@charset "UTF-8";
.color-primary {
  color: #007bff;
}

.color-secondary {
  color: #6c757d;
}

.color-success {
  color: #28a745;
}

.color-danger {
  color: #dc3545;
}

.color-warning {
  color: #ffc107;
}

.color-info {
  color: #17a2b8;
}

.color-light {
  color: #f5f5f8;
}

.color-white {
  color: #FFFFFF;
}

.color-dark {
  color: #333;
}

.color-blank {
  color: transparent;
}

.color-mask {
  color: rgba(0, 0, 0, 0.95);
}

.color-medium {
  color: #666;
}

.color-black {
  color: #000;
}

.border-color-primary {
  border-color: #007bff;
}

.border-color-secondary {
  border-color: #6c757d;
}

.border-color-success {
  border-color: #28a745;
}

.border-color-danger {
  border-color: #dc3545;
}

.border-color-warning {
  border-color: #ffc107;
}

.border-color-info {
  border-color: #17a2b8;
}

.border-color-light {
  border-color: #f5f5f8;
}

.border-color-white {
  border-color: #FFFFFF;
}

.border-color-dark {
  border-color: #333;
}

.border-color-blank {
  border-color: transparent;
}

.border-color-mask {
  border-color: rgba(0, 0, 0, 0.95);
}

.border-color-medium {
  border-color: #666;
}

.border-color-black {
  border-color: #000;
}

.bg-color-primary {
  background-color: #007bff;
}

.bg-color-secondary {
  background-color: #6c757d;
}

.bg-color-success {
  background-color: #28a745;
}

.bg-color-danger {
  background-color: #dc3545;
}

.bg-color-warning {
  background-color: #ffc107;
}

.bg-color-info {
  background-color: #17a2b8;
}

.bg-color-light {
  background-color: #f5f5f8;
}

.bg-color-white {
  background-color: #FFFFFF;
}

.bg-color-dark {
  background-color: #333;
}

.bg-color-blank {
  background-color: transparent;
}

.bg-color-mask {
  background-color: rgba(0, 0, 0, 0.95);
}

.bg-color-medium {
  background-color: #666;
}

.bg-color-black {
  background-color: #000;
}

.border {
  border: 1px solid #E1E1E1;
}

.border-left {
  border-left: 1px solid #E1E1E1;
}

.border-right {
  border-right: 1px solid #E1E1E1;
}

.border-top {
  border-top: 1px solid #E1E1E1;
}

.border-bottom {
  border-bottom: 1px solid #E1E1E1;
}

.border-radius {
  border-radius: 1rem;
}

.border-radius-0 {
  border-radius: 0rem;
}

.border-radius-0\.1 {
  border-radius: 0.1rem;
}

.border-radius-0\.2 {
  border-radius: 0.2rem;
}

.border-radius-0\.3 {
  border-radius: 0.3rem;
}

.border-radius-0\.4 {
  border-radius: 0.4rem;
}

.border-radius-0\.5 {
  border-radius: 0.5rem;
}

.border-radius-0\.6 {
  border-radius: 0.6rem;
}

.border-radius-0\.7 {
  border-radius: 0.7rem;
}

.border-radius-0\.8 {
  border-radius: 0.8rem;
}

.border-radius-0\.9 {
  border-radius: 0.9rem;
}

.border-radius-1 {
  border-radius: 1rem;
}

.border-radius-1\.1 {
  border-radius: 1.1rem;
}

.border-radius-1\.2 {
  border-radius: 1.2rem;
}

.border-radius-1\.3 {
  border-radius: 1.3rem;
}

.border-radius-1\.4 {
  border-radius: 1.4rem;
}

.border-radius-1\.5 {
  border-radius: 1.5rem;
}

.border-radius-1\.6 {
  border-radius: 1.6rem;
}

.border-radius-1\.7 {
  border-radius: 1.7rem;
}

.border-radius-1\.8 {
  border-radius: 1.8rem;
}

.border-radius-1\.9 {
  border-radius: 1.9rem;
}

.border-radius-2 {
  border-radius: 2rem;
}

.border-radius-2\.1 {
  border-radius: 2.1rem;
}

.border-radius-2\.2 {
  border-radius: 2.2rem;
}

.border-radius-2\.3 {
  border-radius: 2.3rem;
}

.border-radius-2\.4 {
  border-radius: 2.4rem;
}

.border-radius-2\.5 {
  border-radius: 2.5rem;
}

.border-radius-2\.6 {
  border-radius: 2.6rem;
}

.border-radius-2\.7 {
  border-radius: 2.7rem;
}

.border-radius-2\.8 {
  border-radius: 2.8rem;
}

.border-radius-2\.9 {
  border-radius: 2.9rem;
}

.border-radius-3 {
  border-radius: 3rem;
}

* {
  padding: 0;
  margin: 0;
  font-family: arial,sans-serif,'Microsoft Yahei';
  list-style: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  border: 0;
}

hr {
  border: 0;
  height: 1rem;
  background: #f5f5f8;
  margin: 1rem 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clearfloat:after {
  content: '';
  display: block;
  clear: both;
}

.table-border td, .table-border th {
  border: solid 1px #E1E1E1;
  padding: 0.5rem;
}

.table-border-bottom td, .table-border-bottom th {
  border-bottom: solid 1px #E1E1E1;
  padding: 0.5rem;
}

.table-border-bottom th {
  border-bottom-width: 2px;
}

.contain-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.contain-box > * {
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  position: absolute;
}

.image-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.image-contain {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-blur {
  -webkit-backdrop-filter: saturate(100%) blur(10px);
          backdrop-filter: saturate(100%) blur(10px);
}

.bg-size-cover {
  background-size: cover;
}

.bg-size-contain {
  background-size: contain;
}

.bg-position-center {
  background-position: center center;
}

.bg-position-left-top {
  background-position: left top;
}

.bg-position-right-top {
  background-position: right top;
}

.bg-position-left-bottom {
  background-position: left bottom;
}

.bg-position-right-bottom {
  background-position: right bottom;
}

.bg-position-center-top {
  background-position: center top;
}

.bg-position-center-bottom {
  background-position: center bottom;
}

.bg-position-left-center {
  background-position: left center;
}

.bg-position-right-center {
  background-position: right center;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.list-style-disc {
  list-style-type: disc;
  padding-left: 1rem;
}

.list-style-circle {
  list-style-type: circle;
  padding-left: 1rem;
}

.list-style-decimal {
  list-style-type: decimal;
  padding-left: 1rem;
}

.list-style-disc li, .list .list-style-circle li, .list .list-style-decimal li {
  display: list-item;
  list-style-type: inherit;
}

.list-border > * {
  border-bottom: 1px solid #E1E1E1;
}

.arrow-top, .arrow-right, .arrow-bottom, .arrow-left {
  position: relative;
  overflow: visible !important;
}

.arrow-top:before {
  content: '';
  display: block;
  border: 0.5rem solid transparent;
  border-top: 0;
  position: absolute;
  left: 0.5rem;
  bottom: 100%;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
  -ms-filter: blur(2px);
  margin-bottom: 2px;
}

.arrow-top:after {
  content: '';
  display: block;
  border: 0.5rem solid transparent;
  border-top: 0;
  position: absolute;
  left: 0.5rem;
  bottom: 100%;
  border-bottom-color: #FFF;
}

.arrow-bottom:before {
  content: '';
  display: block;
  border: 0.5rem solid transparent;
  border-bottom: 0;
  position: absolute;
  left: 0.5rem;
  top: 100%;
  border-top-color: rgba(0, 0, 0, 0.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
  -ms-filter: blur(2px);
  margin-top: 2px;
}

.arrow-bottom:after {
  content: '';
  display: block;
  border: 0.5rem solid transparent;
  border-bottom: 0;
  position: absolute;
  left: 0.5rem;
  top: 100%;
  border-top-color: #FFF;
}

.arrow-left:before {
  content: '';
  display: block;
  border: 0.5rem solid transparent;
  border-left: 0;
  position: absolute;
  top: 0.5rem;
  right: 100%;
  border-right-color: rgba(0, 0, 0, 0.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
  -ms-filter: blur(2px);
  margin-right: 2px;
}

.arrow-left:after {
  content: '';
  display: block;
  border: 0.5rem solid transparent;
  border-left: 0;
  position: absolute;
  top: 0.5rem;
  right: 100%;
  border-right-color: #FFF;
}

.arrow-right:before {
  content: '';
  display: block;
  border: 0.5rem solid transparent;
  border-right: 0;
  position: absolute;
  top: 0.5rem;
  left: 100%;
  border-left-color: rgba(0, 0, 0, 0.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
  -ms-filter: blur(2px);
  margin-left: 2px;
}

.arrow-right:after {
  content: '';
  display: block;
  border: 0.5rem solid transparent;
  border-right: 0;
  position: absolute;
  top: 0.5rem;
  left: 100%;
  border-left-color: #FFF;
}

.arrow-border-top, .arrow-border-bottom, .arrow-border-left, .arrow-border-right {
  position: relative;
  background-color: #FFF;
}

.arrow-border-top:before, .arrow-border-bottom:before, .arrow-border-left:before, .arrow-border-right:before {
  content: '';
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: inherit;
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
  border: inherit;
  position: absolute;
}

.arrow-border-top:before {
  bottom: 100%;
  left: 50%;
  margin-left: -0.4rem;
  margin-bottom: -0.4rem;
  border: inherit;
  border-right: none;
  border-bottom: none;
}

.arrow-border-bottom:before {
  top: 100%;
  left: 50%;
  margin-left: -0.4rem;
  bottom: auto;
  margin-bottom: auto;
  margin-top: -0.4rem;
  border: inherit;
  border-left: none;
  border-top: none;
}

.arrow-border-left:before {
  top: 50%;
  margin-top: -0.4rem;
  right: 100%;
  margin-right: -0.4rem;
  border: inherit;
  border-right: none;
  border-top: none;
}

.arrow-border-right:before {
  top: 50%;
  margin-top: -0.4rem;
  left: 100%;
  margin-left: -0.4rem;
  border: inherit;
  border-left: none;
  border-bottom: none;
}

.foucs-inline-block, .foucs-block, .foucs-inline, .foucs-flex, .foucs-inline-flex {
  display: none;
}

.foucs-backer:focus .focus-inline-block {
  display: inline-block;
}

.foucs-backer:focus .focus-inline {
  display: inline;
}

.foucs-backer:focus .focus-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.foucs-backer:focus .focus-inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.nav-mobile-btn > * {
  width: 1.5rem;
  height: 2px;
  border-radius: 1px;
  background-color: #666;
  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.nav-mobile-btn.active > *:nth-child(1) {
  -webkit-transform: translateY(8px) rotate(45deg);
          transform: translateY(8px) rotate(45deg);
}

.nav-mobile-btn.active > *:nth-child(2) {
  opacity: 0;
}

.nav-mobile-btn.active > *:nth-child(3) {
  -webkit-transform: translateY(-8px) rotate(-45deg);
          transform: translateY(-8px) rotate(-45deg);
}

.hv-shadow {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.hv-shadow:hover {
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
}

.hv-shadow-bottom {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.hv-shadow-bottom:hover {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.hv-shadow-top {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.hv-shadow-top:hover {
  -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.hv-shadow-left {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.hv-shadow-left:hover {
  -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
}

.hv-shadow-right {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.hv-shadow-right:hover {
  -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
}

.hv-shadow-left-top {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.hv-shadow-left-top:hover {
  -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.hv-shadow-right-top {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.hv-shadow-right-top:hover {
  -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.hv-shadow-left-bottom {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.hv-shadow-left-bottom:hover {
  -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.hv-shadow-right-bottom {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.hv-shadow-right-bottom:hover {
  -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.hv-table-light tr td {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.hv-table-light tr:hover td {
  background-color: #f5f5f8;
}

.hv-show {
  display: none;
}

.hv-backer:hover .hv-show {
  display: block;
}

.hv-visible {
  visibility: hidden;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.hv-backer:hover .hv-visible {
  visibility: visible;
}

.hv-visible-fade-zoom {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-name: fade-zoom-out;
          animation-name: fade-zoom-out;
  visibility: hidden;
}

.hv-backer:hover .hv-visible-fade-zoom {
  visibility: visible;
  -webkit-animation-name: fade-zoom-in;
          animation-name: fade-zoom-in;
}

.hv-visible-fade {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-name: fadeout;
          animation-name: fadeout;
  visibility: hidden;
}

.hv-backer:hover .hv-visible-fade {
  visibility: visible;
  -webkit-animation-name: fadein;
          animation-name: fadein;
}

.hv-visible-zoom {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-name: zoomOut;
          animation-name: zoomOut;
  visibility: hidden;
}

.hv-backer:hover .hv-visible-zoom {
  visibility: visible;
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
}

.hv-visible-slide {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-name: slideOutDown;
          animation-name: slideOutDown;
  visibility: hidden;
}

.hv-backer:hover .hv-visible-slide {
  visibility: visible;
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
}

.hv-visible-slide-down {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  visibility: hidden;
}

.hv-backer:hover .hv-visible-slide-down {
  visibility: visible;
  -webkit-animation-name: slideInDown;
          animation-name: slideInDown;
}

.hv-visible-rotate {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-name: rotateOut;
          animation-name: rotateOut;
  visibility: hidden;
}

.hv-backer:hover .hv-visible-rotate {
  visibility: visible;
  -webkit-animation-name: rotateIn;
          animation-name: rotateIn;
}

.hv-ani-pop, .hv-ani-push, .hv-ani-pulse, .hv-ani-float, .hv-ani-bob, .hv-ani-skew, .hv-ani-wobble {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.hv-ani-pop:hover {
  -webkit-animation-name: pop;
          animation-name: pop;
}

.hv-ani-push:hover {
  -webkit-animation-name: push;
          animation-name: push;
}

.hv-ani-pulse:hover {
  -webkit-animation-name: pulse;
          animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.hv-ani-float:hover {
  -webkit-transform: translateY(-0.5rem);
          transform: translateY(-0.5rem);
}

.hv-ani-bob:hover {
  -webkit-animation-name: bob;
          animation-name: bob;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.hv-ani-skew:hover {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
}

.hv-ani-wobble:hover {
  -webkit-animation-name: wobble-top;
          animation-name: wobble-top;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.shadow {
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
}

.shadow-bottom {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.shadow-top {
  -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.shadow-left {
  -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
}

.shadow-right {
  -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
}

.shadow-left-top {
  -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.shadow-right-top {
  -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.shadow-left-bottom {
  -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.shadow-right-bottom {
  -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.s0x0:after, .s4x3:after, .s1x1:after, .s3x4:after, .s16x9:after, .s9x16:after {
  content: '';
  display: block;
}

.s4x3:after {
  padding-top: 75% !important;
}

.s1x1:after {
  padding-top: 100% !important;
}

.s3x4:after {
  padding-top: 133.33% !important;
}

.s16x9:after {
  padding-top: 56.25% !important;
}

.s9x16:after {
  padding-top: 177.77% !important;
}

.s0x0:after {
  padding-top: 100% !important;
}

.s0x0 {
  border-radius: 50% !important;
  overflow: hidden;
}

.for-td-pd-s td {
  padding: 0.5rem;
}

.for-td-pd-1 td {
  padding: 1rem;
}

.for-td-pd-2 td {
  padding: 2rem;
}

.for-td-pd-3 td {
  padding: 3rem;
}

.for-td-pd-4 td {
  padding: 4rem;
}

.for-th-pd-1 th {
  padding: 1rem;
}

.for-th-pd-2 th {
  padding: 2rem;
}

.for-th-pd-3 th {
  padding: 3rem;
}

.for-th-pd-4 th {
  padding: 4rem;
}

.in {
  margin: 0 1rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.bold {
  font-weight: bold;
}

.lighter {
  font-weight: lighter;
  font-family: "Microsoft Yahei" !important;
}

.hide {
  display: none;
}

.hidden {
  visibility: hidden;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

.h-auto {
  height: auto;
}

.w-auto {
  width: auto;
}

.vmiddle {
  vertical-align: middle;
}

.vtop {
  vertical-align: top;
}

.vbottom {
  vertical-align: bottom;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.show, .display {
  display: block;
}

.show-block, .display-block {
  display: block;
}

.show-table, .display-table {
  display: table;
}

.show-inline, .display-inline {
  display: inline;
}

.show-inline-block, .display-inline-block {
  display: inline-block;
}

.show-flex, .display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.weight-1 {
  font-weight: 100;
}

.weight-2 {
  font-weight: 200;
}

.weight-3 {
  font-weight: 300;
}

.weight-4 {
  font-weight: 400;
}

.weight-5 {
  font-weight: 500;
}

.weight-6 {
  font-weight: 600;
}

.weight-7 {
  font-weight: 700;
}

.weight-8 {
  font-weight: 800;
}

.weight-9 {
  font-weight: 900;
}

.opacity-0 {
  opacity: 0;
}

.opacity-0\.1 {
  opacity: 0.1;
}

.opacity-0\.2 {
  opacity: 0.2;
}

.opacity-0\.3 {
  opacity: 0.3;
}

.opacity-0\.4 {
  opacity: 0.4;
}

.opacity-0\.5 {
  opacity: 0.5;
}

.opacity-0\.6 {
  opacity: 0.6;
}

.opacity-0\.7 {
  opacity: 0.7;
}

.opacity-0\.8 {
  opacity: 0.8;
}

.opacity-0\.9 {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

.col-auto {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 1px;
}

.for-col-auto > * {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 1px;
}

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  min-width: 1px;
}

.for-col-1 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  min-width: 1px;
}

.for-col-2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  min-width: 1px;
}

.for-col-3 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
}

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  min-width: 1px;
}

.for-col-4 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  min-width: 1px;
}

.for-col-5 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  min-width: 1px;
}

.for-col-6 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  min-width: 1px;
}

.for-col-7 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  min-width: 1px;
}

.for-col-8 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  min-width: 1px;
}

.for-col-9 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
}

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  min-width: 1px;
}

.for-col-10 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  min-width: 1px;
}

.for-col-11 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  min-width: 1px;
}

.for-col-12 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}

.width-1 {
  width: 1rem;
}

.width-2 {
  width: 2rem;
}

.width-3 {
  width: 3rem;
}

.width-4 {
  width: 4rem;
}

.width-5 {
  width: 5rem;
}

.width-6 {
  width: 6rem;
}

.width-7 {
  width: 7rem;
}

.width-8 {
  width: 8rem;
}

.width-9 {
  width: 9rem;
}

.width-10 {
  width: 10rem;
}

.fs-0\.1 {
  font-size: 0.1rem;
}

.fs-0\.2 {
  font-size: 0.2rem;
}

.fs-0\.3 {
  font-size: 0.3rem;
}

.fs-0\.4 {
  font-size: 0.4rem;
}

.fs-0\.5 {
  font-size: 0.5rem;
}

.fs-0\.6 {
  font-size: 0.6rem;
}

.fs-0\.7 {
  font-size: 0.7rem;
}

.fs-0\.8 {
  font-size: 0.8rem;
}

.fs-0\.9 {
  font-size: 0.9rem;
}

.fs-1 {
  font-size: 1rem;
}

.fs-1\.1 {
  font-size: 1.1rem;
}

.fs-1\.2 {
  font-size: 1.2rem;
}

.fs-1\.3 {
  font-size: 1.3rem;
}

.fs-1\.4 {
  font-size: 1.4rem;
}

.fs-1\.5 {
  font-size: 1.5rem;
}

.fs-1\.6 {
  font-size: 1.6rem;
}

.fs-1\.7 {
  font-size: 1.7rem;
}

.fs-1\.8 {
  font-size: 1.8rem;
}

.fs-1\.9 {
  font-size: 1.9rem;
}

.fs-2 {
  font-size: 2rem;
}

.fs-2\.1 {
  font-size: 2.1rem;
}

.fs-2\.2 {
  font-size: 2.2rem;
}

.fs-2\.3 {
  font-size: 2.3rem;
}

.fs-2\.4 {
  font-size: 2.4rem;
}

.fs-2\.5 {
  font-size: 2.5rem;
}

.fs-2\.6 {
  font-size: 2.6rem;
}

.fs-2\.7 {
  font-size: 2.7rem;
}

.fs-2\.8 {
  font-size: 2.8rem;
}

.fs-2\.9 {
  font-size: 2.9rem;
}

.fs-3 {
  font-size: 3rem;
}

.fs-3\.1 {
  font-size: 3.1rem;
}

.fs-3\.2 {
  font-size: 3.2rem;
}

.fs-3\.3 {
  font-size: 3.3rem;
}

.fs-3\.4 {
  font-size: 3.4rem;
}

.fs-3\.5 {
  font-size: 3.5rem;
}

.fs-3\.6 {
  font-size: 3.6rem;
}

.fs-3\.7 {
  font-size: 3.7rem;
}

.fs-3\.8 {
  font-size: 3.8rem;
}

.fs-3\.9 {
  font-size: 3.9rem;
}

.fs-4 {
  font-size: 4rem;
}

.fs-4\.1 {
  font-size: 4.1rem;
}

.fs-4\.2 {
  font-size: 4.2rem;
}

.fs-4\.3 {
  font-size: 4.3rem;
}

.fs-4\.4 {
  font-size: 4.4rem;
}

.fs-4\.5 {
  font-size: 4.5rem;
}

.fs-4\.6 {
  font-size: 4.6rem;
}

.fs-4\.7 {
  font-size: 4.7rem;
}

.fs-4\.8 {
  font-size: 4.8rem;
}

.fs-4\.9 {
  font-size: 4.9rem;
}

.fs-5 {
  font-size: 5rem;
}

.fs-5\.1 {
  font-size: 5.1rem;
}

.fs-5\.2 {
  font-size: 5.2rem;
}

.fs-5\.3 {
  font-size: 5.3rem;
}

.fs-5\.4 {
  font-size: 5.4rem;
}

.fs-5\.5 {
  font-size: 5.5rem;
}

.fs-5\.6 {
  font-size: 5.6rem;
}

.fs-5\.7 {
  font-size: 5.7rem;
}

.fs-5\.8 {
  font-size: 5.8rem;
}

.fs-5\.9 {
  font-size: 5.9rem;
}

.fs-6 {
  font-size: 6rem;
}

.fs-6\.1 {
  font-size: 6.1rem;
}

.fs-6\.2 {
  font-size: 6.2rem;
}

.fs-6\.3 {
  font-size: 6.3rem;
}

.fs-6\.4 {
  font-size: 6.4rem;
}

.fs-6\.5 {
  font-size: 6.5rem;
}

.fs-6\.6 {
  font-size: 6.6rem;
}

.fs-6\.7 {
  font-size: 6.7rem;
}

.fs-6\.8 {
  font-size: 6.8rem;
}

.fs-6\.9 {
  font-size: 6.9rem;
}

.fs-7 {
  font-size: 7rem;
}

.fs-7\.1 {
  font-size: 7.1rem;
}

.fs-7\.2 {
  font-size: 7.2rem;
}

.fs-7\.3 {
  font-size: 7.3rem;
}

.fs-7\.4 {
  font-size: 7.4rem;
}

.fs-7\.5 {
  font-size: 7.5rem;
}

.fs-7\.6 {
  font-size: 7.6rem;
}

.fs-7\.7 {
  font-size: 7.7rem;
}

.fs-7\.8 {
  font-size: 7.8rem;
}

.fs-7\.9 {
  font-size: 7.9rem;
}

.fs-8 {
  font-size: 8rem;
}

.fs-8\.1 {
  font-size: 8.1rem;
}

.fs-8\.2 {
  font-size: 8.2rem;
}

.fs-8\.3 {
  font-size: 8.3rem;
}

.fs-8\.4 {
  font-size: 8.4rem;
}

.fs-8\.5 {
  font-size: 8.5rem;
}

.fs-8\.6 {
  font-size: 8.6rem;
}

.fs-8\.7 {
  font-size: 8.7rem;
}

.fs-8\.8 {
  font-size: 8.8rem;
}

.fs-8\.9 {
  font-size: 8.9rem;
}

.fs-9 {
  font-size: 9rem;
}

.fs-9\.1 {
  font-size: 9.1rem;
}

.fs-9\.2 {
  font-size: 9.2rem;
}

.fs-9\.3 {
  font-size: 9.3rem;
}

.fs-9\.4 {
  font-size: 9.4rem;
}

.fs-9\.5 {
  font-size: 9.5rem;
}

.fs-9\.6 {
  font-size: 9.6rem;
}

.fs-9\.7 {
  font-size: 9.7rem;
}

.fs-9\.8 {
  font-size: 9.8rem;
}

.fs-9\.9 {
  font-size: 9.9rem;
}

.fs-10 {
  font-size: 10rem;
}

.fs-14 {
  font-size: 0.875rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-large {
  font-size: 3rem;
}

.line-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.line-2 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-3 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-4 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.line-5 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.line-6 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.line-7 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
}

.line-8 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
}

.line-9 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
}

.line-10 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}

.lh-0\.2 {
  line-height: 0.2;
}

.lh-02 {
  line-height: 0.2;
}

.lh-0\.3 {
  line-height: 0.3;
}

.lh-03 {
  line-height: 0.3;
}

.lh-0\.4 {
  line-height: 0.4;
}

.lh-04 {
  line-height: 0.4;
}

.lh-0\.5 {
  line-height: 0.5;
}

.lh-05 {
  line-height: 0.5;
}

.lh-0\.6 {
  line-height: 0.6;
}

.lh-06 {
  line-height: 0.6;
}

.lh-0\.7 {
  line-height: 0.7;
}

.lh-07 {
  line-height: 0.7;
}

.lh-0\.8 {
  line-height: 0.8;
}

.lh-08 {
  line-height: 0.8;
}

.lh-0\.9 {
  line-height: 0.9;
}

.lh-09 {
  line-height: 0.9;
}

.lh-1 {
  line-height: 1;
}

.lh-1 {
  line-height: 1;
}

.lh-1\.1 {
  line-height: 1.1;
}

.lh-11 {
  line-height: 1.1;
}

.lh-1\.2 {
  line-height: 1.2;
}

.lh-12 {
  line-height: 1.2;
}

.lh-1\.3 {
  line-height: 1.3;
}

.lh-13 {
  line-height: 1.3;
}

.lh-1\.4 {
  line-height: 1.4;
}

.lh-14 {
  line-height: 1.4;
}

.lh-1\.5 {
  line-height: 1.5;
}

.lh-15 {
  line-height: 1.5;
}

.lh-1\.6 {
  line-height: 1.6;
}

.lh-16 {
  line-height: 1.6;
}

.lh-1\.7 {
  line-height: 1.7;
}

.lh-17 {
  line-height: 1.7;
}

.lh-1\.8 {
  line-height: 1.8;
}

.lh-18 {
  line-height: 1.8;
}

.lh-1\.9 {
  line-height: 1.9;
}

.lh-19 {
  line-height: 1.9;
}

.lh-2 {
  line-height: 2;
}

.lh-2 {
  line-height: 2;
}

.lh-2\.1 {
  line-height: 2.1;
}

.lh-21 {
  line-height: 2.1;
}

.lh-2\.2 {
  line-height: 2.2;
}

.lh-22 {
  line-height: 2.2;
}

.lh-2\.3 {
  line-height: 2.3;
}

.lh-23 {
  line-height: 2.3;
}

.lh-2\.4 {
  line-height: 2.4;
}

.lh-24 {
  line-height: 2.4;
}

.lh-2\.5 {
  line-height: 2.5;
}

.lh-25 {
  line-height: 2.5;
}

.lh-2\.6 {
  line-height: 2.6;
}

.lh-26 {
  line-height: 2.6;
}

.lh-2\.7 {
  line-height: 2.7;
}

.lh-27 {
  line-height: 2.7;
}

.lh-2\.8 {
  line-height: 2.8;
}

.lh-28 {
  line-height: 2.8;
}

.lh-2\.9 {
  line-height: 2.9;
}

.lh-29 {
  line-height: 2.9;
}

.lh-3 {
  line-height: 3;
}

.lh-3 {
  line-height: 3;
}

.lh-3\.1 {
  line-height: 3.1;
}

.lh-31 {
  line-height: 3.1;
}

.lh-3\.2 {
  line-height: 3.2;
}

.lh-32 {
  line-height: 3.2;
}

.lh-3\.3 {
  line-height: 3.3;
}

.lh-33 {
  line-height: 3.3;
}

.lh-3\.4 {
  line-height: 3.4;
}

.lh-34 {
  line-height: 3.4;
}

.lh-3\.5 {
  line-height: 3.5;
}

.lh-35 {
  line-height: 3.5;
}

.w-auto {
  width: auto;
}

.for-w-auto > * {
  width: auto;
}

.w-1 {
  width: 8.33333%;
}

.for-w-1 > * {
  width: 8.33333%;
}

.w-2 {
  width: 16.66667%;
}

.for-w-2 > * {
  width: 16.66667%;
}

.w-3 {
  width: 25%;
}

.for-w-3 > * {
  width: 25%;
}

.w-4 {
  width: 33.33333%;
}

.for-w-4 > * {
  width: 33.33333%;
}

.w-5 {
  width: 41.66667%;
}

.for-w-5 > * {
  width: 41.66667%;
}

.w-6 {
  width: 50%;
}

.for-w-6 > * {
  width: 50%;
}

.w-7 {
  width: 58.33333%;
}

.for-w-7 > * {
  width: 58.33333%;
}

.w-8 {
  width: 66.66667%;
}

.for-w-8 > * {
  width: 66.66667%;
}

.w-9 {
  width: 75%;
}

.for-w-9 > * {
  width: 75%;
}

.w-10 {
  width: 83.33333%;
}

.for-w-10 > * {
  width: 83.33333%;
}

.w-11 {
  width: 91.66667%;
}

.for-w-11 > * {
  width: 91.66667%;
}

.w-12 {
  width: 100%;
}

.for-w-12 > * {
  width: 100%;
}

.l-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.l-1 > * {
  width: 100%;
}

.l-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.l-2 > * {
  width: 50%;
}

.l-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.l-3 > * {
  width: 33.33333%;
}

.l-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.l-4 > * {
  width: 25%;
}

.l-5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.l-5 > * {
  width: 20%;
}

.l-6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.l-6 > * {
  width: 16.66667%;
}

.l-7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.l-7 > * {
  width: 14.28571%;
}

.l-8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.l-8 > * {
  width: 12.5%;
}

.l-9 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.l-9 > * {
  width: 11.11111%;
}

.l-10 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.l-10 > * {
  width: 10%;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-visible {
  overflow: visible;
}

.no-events {
  pointer-events: none;
}

.no-drag {
  -webkit-user-drag: none;
}

.no-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-bold {
  font-weight: normal;
}

.no-border {
  border: none;
}

.no-float {
  float: none;
}

.no-first-border > *:first-child {
  border: 0;
}

.no-last-border > *:last-child {
  border: 0;
}

.no-bg {
  background-color: transparent !important;
  background-image: none !important;
}

.no-wrap {
  white-space: nowrap;
}

.no-flex-shrink {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* 水平，左起 */
  /* 水平，左起 */
  /* 垂直，上起 */
  /* 垂直，下起 */
  /* flex 水平对齐方式 */
  /*分布对齐，如有空隙，包括始端与末端*/
  /* flex 垂直对齐方式 */
  /*第一行文字的基线对齐。*/
  /*（默认值）：如果项目未设置高度或设为auto，将占满整个容器的高度。*/
  /* flex align-content */
  /* 单项 对齐方式 */
}

.flex-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.flex-nowrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.flex-wrap-reverse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}

.flex-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.flex-i {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.flex-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.flex-direction-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.flex-direction-row-reverse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.flex-direction-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flex-direction-column-reverse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.flex-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.flex-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.flex-x-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.flex-x-around {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.flex-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.flex-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.flex-middle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-baseline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.flex-stretch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.flex-align-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: start;
      align-content: flex-start;
}

.flex-align-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: end;
      align-content: flex-end;
}

.flex-align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: flex-center;
      align-content: flex-center;
}

.flex-align-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: justify;
      align-content: space-between;
}

.flex-align-around {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: distribute;
      align-content: space-around;
}

.flex-align-stretch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: stretch;
      align-content: stretch;
}

.flex > .item-top {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.flex > .item-middle {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.flex > .item-bottom {
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.flex > .item-baseline {
  -ms-flex-item-align: baseline;
      align-self: baseline;
}

.flex > .item-stretch {
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
}

.flex > .item-auto {
  -ms-flex-item-align: auto;
      -ms-grid-row-align: auto;
      align-self: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-indent {
  text-indent: 2em;
}

.text-underline {
  text-decoration: underline;
}

.full {
  width: 100%;
  height: 100%;
}

.full-w {
  width: 100%;
}

.full-h {
  height: 100%;
}

.pd-s {
  padding: 0.5rem;
}

.pd-auto {
  padding: auto;
}

.pd-x-auto {
  padding-left: auto;
  padding-right: auto;
}

.pd-y-auto {
  padding-top: auto;
  padding-top: auto;
}

.pd-left-auto {
  padding-left: auto;
}

.pd-right-auto {
  padding-right: auto;
}

.pd-top-auto {
  padding-top: auto;
}

.pd-bottom-auto {
  padding-bottom: auto;
}

.pd-0 {
  padding: 0rem;
}

.pd-left-0 {
  padding-left: 0rem;
}

.pd-right-0 {
  padding-right: 0rem;
}

.pd-top-0 {
  padding-top: 0rem;
}

.pd-bottom-0 {
  padding-bottom: 0rem;
}

.pd-x-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.pd-y-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.pd-0\.5 {
  padding: 0.5rem;
}

.pd-left-0\.5 {
  padding-left: 0.5rem;
}

.pd-right-0\.5 {
  padding-right: 0.5rem;
}

.pd-top-0\.5 {
  padding-top: 0.5rem;
}

.pd-bottom-0\.5 {
  padding-bottom: 0.5rem;
}

.pd-x-0\.5 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.pd-y-0\.5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pd-1 {
  padding: 1rem;
}

.pd-left-1 {
  padding-left: 1rem;
}

.pd-right-1 {
  padding-right: 1rem;
}

.pd-top-1 {
  padding-top: 1rem;
}

.pd-bottom-1 {
  padding-bottom: 1rem;
}

.pd-x-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pd-y-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pd-1\.5 {
  padding: 1.5rem;
}

.pd-left-1\.5 {
  padding-left: 1.5rem;
}

.pd-right-1\.5 {
  padding-right: 1.5rem;
}

.pd-top-1\.5 {
  padding-top: 1.5rem;
}

.pd-bottom-1\.5 {
  padding-bottom: 1.5rem;
}

.pd-x-1\.5 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pd-y-1\.5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pd-2 {
  padding: 2rem;
}

.pd-left-2 {
  padding-left: 2rem;
}

.pd-right-2 {
  padding-right: 2rem;
}

.pd-top-2 {
  padding-top: 2rem;
}

.pd-bottom-2 {
  padding-bottom: 2rem;
}

.pd-x-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pd-y-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pd-2\.5 {
  padding: 2.5rem;
}

.pd-left-2\.5 {
  padding-left: 2.5rem;
}

.pd-right-2\.5 {
  padding-right: 2.5rem;
}

.pd-top-2\.5 {
  padding-top: 2.5rem;
}

.pd-bottom-2\.5 {
  padding-bottom: 2.5rem;
}

.pd-x-2\.5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pd-y-2\.5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pd-3 {
  padding: 3rem;
}

.pd-left-3 {
  padding-left: 3rem;
}

.pd-right-3 {
  padding-right: 3rem;
}

.pd-top-3 {
  padding-top: 3rem;
}

.pd-bottom-3 {
  padding-bottom: 3rem;
}

.pd-x-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.pd-y-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pd-3\.5 {
  padding: 3.5rem;
}

.pd-left-3\.5 {
  padding-left: 3.5rem;
}

.pd-right-3\.5 {
  padding-right: 3.5rem;
}

.pd-top-3\.5 {
  padding-top: 3.5rem;
}

.pd-bottom-3\.5 {
  padding-bottom: 3.5rem;
}

.pd-x-3\.5 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.pd-y-3\.5 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.pd-4 {
  padding: 4rem;
}

.pd-left-4 {
  padding-left: 4rem;
}

.pd-right-4 {
  padding-right: 4rem;
}

.pd-top-4 {
  padding-top: 4rem;
}

.pd-bottom-4 {
  padding-bottom: 4rem;
}

.pd-x-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.pd-y-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pd-4\.5 {
  padding: 4.5rem;
}

.pd-left-4\.5 {
  padding-left: 4.5rem;
}

.pd-right-4\.5 {
  padding-right: 4.5rem;
}

.pd-top-4\.5 {
  padding-top: 4.5rem;
}

.pd-bottom-4\.5 {
  padding-bottom: 4.5rem;
}

.pd-x-4\.5 {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.pd-y-4\.5 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.pd-5 {
  padding: 5rem;
}

.pd-left-5 {
  padding-left: 5rem;
}

.pd-right-5 {
  padding-right: 5rem;
}

.pd-top-5 {
  padding-top: 5rem;
}

.pd-bottom-5 {
  padding-bottom: 5rem;
}

.pd-x-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.pd-y-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pd-5\.5 {
  padding: 5.5rem;
}

.pd-left-5\.5 {
  padding-left: 5.5rem;
}

.pd-right-5\.5 {
  padding-right: 5.5rem;
}

.pd-top-5\.5 {
  padding-top: 5.5rem;
}

.pd-bottom-5\.5 {
  padding-bottom: 5.5rem;
}

.pd-x-5\.5 {
  padding-left: 5.5rem;
  padding-right: 5.5rem;
}

.pd-y-5\.5 {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}

.pd-6 {
  padding: 6rem;
}

.pd-left-6 {
  padding-left: 6rem;
}

.pd-right-6 {
  padding-right: 6rem;
}

.pd-top-6 {
  padding-top: 6rem;
}

.pd-bottom-6 {
  padding-bottom: 6rem;
}

.pd-x-6 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.pd-y-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.pd-6\.5 {
  padding: 6.5rem;
}

.pd-left-6\.5 {
  padding-left: 6.5rem;
}

.pd-right-6\.5 {
  padding-right: 6.5rem;
}

.pd-top-6\.5 {
  padding-top: 6.5rem;
}

.pd-bottom-6\.5 {
  padding-bottom: 6.5rem;
}

.pd-x-6\.5 {
  padding-left: 6.5rem;
  padding-right: 6.5rem;
}

.pd-y-6\.5 {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}

.pd-7 {
  padding: 7rem;
}

.pd-left-7 {
  padding-left: 7rem;
}

.pd-right-7 {
  padding-right: 7rem;
}

.pd-top-7 {
  padding-top: 7rem;
}

.pd-bottom-7 {
  padding-bottom: 7rem;
}

.pd-x-7 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.pd-y-7 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.pd-7\.5 {
  padding: 7.5rem;
}

.pd-left-7\.5 {
  padding-left: 7.5rem;
}

.pd-right-7\.5 {
  padding-right: 7.5rem;
}

.pd-top-7\.5 {
  padding-top: 7.5rem;
}

.pd-bottom-7\.5 {
  padding-bottom: 7.5rem;
}

.pd-x-7\.5 {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
}

.pd-y-7\.5 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.pd-8 {
  padding: 8rem;
}

.pd-left-8 {
  padding-left: 8rem;
}

.pd-right-8 {
  padding-right: 8rem;
}

.pd-top-8 {
  padding-top: 8rem;
}

.pd-bottom-8 {
  padding-bottom: 8rem;
}

.pd-x-8 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.pd-y-8 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.pd-8\.5 {
  padding: 8.5rem;
}

.pd-left-8\.5 {
  padding-left: 8.5rem;
}

.pd-right-8\.5 {
  padding-right: 8.5rem;
}

.pd-top-8\.5 {
  padding-top: 8.5rem;
}

.pd-bottom-8\.5 {
  padding-bottom: 8.5rem;
}

.pd-x-8\.5 {
  padding-left: 8.5rem;
  padding-right: 8.5rem;
}

.pd-y-8\.5 {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
}

.pd-9 {
  padding: 9rem;
}

.pd-left-9 {
  padding-left: 9rem;
}

.pd-right-9 {
  padding-right: 9rem;
}

.pd-top-9 {
  padding-top: 9rem;
}

.pd-bottom-9 {
  padding-bottom: 9rem;
}

.pd-x-9 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.pd-y-9 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.pd-9\.5 {
  padding: 9.5rem;
}

.pd-left-9\.5 {
  padding-left: 9.5rem;
}

.pd-right-9\.5 {
  padding-right: 9.5rem;
}

.pd-top-9\.5 {
  padding-top: 9.5rem;
}

.pd-bottom-9\.5 {
  padding-bottom: 9.5rem;
}

.pd-x-9\.5 {
  padding-left: 9.5rem;
  padding-right: 9.5rem;
}

.pd-y-9\.5 {
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
}

.pd-10 {
  padding: 10rem;
}

.pd-left-10 {
  padding-left: 10rem;
}

.pd-right-10 {
  padding-right: 10rem;
}

.pd-top-10 {
  padding-top: 10rem;
}

.pd-bottom-10 {
  padding-bottom: 10rem;
}

.pd-x-10 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.pd-y-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.mg-s {
  margin: 0.5rem;
}

.mg-auto {
  margin: auto;
}

.mg-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.mg-y-auto {
  margin-top: auto;
  margin-top: auto;
}

.mg-left-auto {
  margin-left: auto;
}

.mg-right-auto {
  margin-right: auto;
}

.mg-top-auto {
  margin-top: auto;
}

.mg-bottom-auto {
  margin-bottom: auto;
}

.mg-0 {
  margin: 0rem;
}

.mg-left-0 {
  margin-left: 0rem;
}

.mg-right-0 {
  margin-right: 0rem;
}

.mg-top-0 {
  margin-top: 0rem;
}

.mg-bottom-0 {
  margin-bottom: 0rem;
}

.mg-x-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.mg-y-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.mg-0\.5 {
  margin: 0.5rem;
}

.mg-left-0\.5 {
  margin-left: 0.5rem;
}

.mg-right-0\.5 {
  margin-right: 0.5rem;
}

.mg-top-0\.5 {
  margin-top: 0.5rem;
}

.mg-bottom-0\.5 {
  margin-bottom: 0.5rem;
}

.mg-x-0\.5 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mg-y-0\.5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mg-1 {
  margin: 1rem;
}

.mg-left-1 {
  margin-left: 1rem;
}

.mg-right-1 {
  margin-right: 1rem;
}

.mg-top-1 {
  margin-top: 1rem;
}

.mg-bottom-1 {
  margin-bottom: 1rem;
}

.mg-x-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mg-y-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mg-1\.5 {
  margin: 1.5rem;
}

.mg-left-1\.5 {
  margin-left: 1.5rem;
}

.mg-right-1\.5 {
  margin-right: 1.5rem;
}

.mg-top-1\.5 {
  margin-top: 1.5rem;
}

.mg-bottom-1\.5 {
  margin-bottom: 1.5rem;
}

.mg-x-1\.5 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mg-y-1\.5 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mg-2 {
  margin: 2rem;
}

.mg-left-2 {
  margin-left: 2rem;
}

.mg-right-2 {
  margin-right: 2rem;
}

.mg-top-2 {
  margin-top: 2rem;
}

.mg-bottom-2 {
  margin-bottom: 2rem;
}

.mg-x-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mg-y-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mg-2\.5 {
  margin: 2.5rem;
}

.mg-left-2\.5 {
  margin-left: 2.5rem;
}

.mg-right-2\.5 {
  margin-right: 2.5rem;
}

.mg-top-2\.5 {
  margin-top: 2.5rem;
}

.mg-bottom-2\.5 {
  margin-bottom: 2.5rem;
}

.mg-x-2\.5 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mg-y-2\.5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mg-3 {
  margin: 3rem;
}

.mg-left-3 {
  margin-left: 3rem;
}

.mg-right-3 {
  margin-right: 3rem;
}

.mg-top-3 {
  margin-top: 3rem;
}

.mg-bottom-3 {
  margin-bottom: 3rem;
}

.mg-x-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mg-y-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mg-3\.5 {
  margin: 3.5rem;
}

.mg-left-3\.5 {
  margin-left: 3.5rem;
}

.mg-right-3\.5 {
  margin-right: 3.5rem;
}

.mg-top-3\.5 {
  margin-top: 3.5rem;
}

.mg-bottom-3\.5 {
  margin-bottom: 3.5rem;
}

.mg-x-3\.5 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.mg-y-3\.5 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.mg-4 {
  margin: 4rem;
}

.mg-left-4 {
  margin-left: 4rem;
}

.mg-right-4 {
  margin-right: 4rem;
}

.mg-top-4 {
  margin-top: 4rem;
}

.mg-bottom-4 {
  margin-bottom: 4rem;
}

.mg-x-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mg-y-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mg-4\.5 {
  margin: 4.5rem;
}

.mg-left-4\.5 {
  margin-left: 4.5rem;
}

.mg-right-4\.5 {
  margin-right: 4.5rem;
}

.mg-top-4\.5 {
  margin-top: 4.5rem;
}

.mg-bottom-4\.5 {
  margin-bottom: 4.5rem;
}

.mg-x-4\.5 {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}

.mg-y-4\.5 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.mg-5 {
  margin: 5rem;
}

.mg-left-5 {
  margin-left: 5rem;
}

.mg-right-5 {
  margin-right: 5rem;
}

.mg-top-5 {
  margin-top: 5rem;
}

.mg-bottom-5 {
  margin-bottom: 5rem;
}

.mg-x-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mg-y-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mg-5\.5 {
  margin: 5.5rem;
}

.mg-left-5\.5 {
  margin-left: 5.5rem;
}

.mg-right-5\.5 {
  margin-right: 5.5rem;
}

.mg-top-5\.5 {
  margin-top: 5.5rem;
}

.mg-bottom-5\.5 {
  margin-bottom: 5.5rem;
}

.mg-x-5\.5 {
  margin-left: 5.5rem;
  margin-right: 5.5rem;
}

.mg-y-5\.5 {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}

.mg-6 {
  margin: 6rem;
}

.mg-left-6 {
  margin-left: 6rem;
}

.mg-right-6 {
  margin-right: 6rem;
}

.mg-top-6 {
  margin-top: 6rem;
}

.mg-bottom-6 {
  margin-bottom: 6rem;
}

.mg-x-6 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mg-y-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.mg-6\.5 {
  margin: 6.5rem;
}

.mg-left-6\.5 {
  margin-left: 6.5rem;
}

.mg-right-6\.5 {
  margin-right: 6.5rem;
}

.mg-top-6\.5 {
  margin-top: 6.5rem;
}

.mg-bottom-6\.5 {
  margin-bottom: 6.5rem;
}

.mg-x-6\.5 {
  margin-left: 6.5rem;
  margin-right: 6.5rem;
}

.mg-y-6\.5 {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
}

.mg-7 {
  margin: 7rem;
}

.mg-left-7 {
  margin-left: 7rem;
}

.mg-right-7 {
  margin-right: 7rem;
}

.mg-top-7 {
  margin-top: 7rem;
}

.mg-bottom-7 {
  margin-bottom: 7rem;
}

.mg-x-7 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.mg-y-7 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.mg-7\.5 {
  margin: 7.5rem;
}

.mg-left-7\.5 {
  margin-left: 7.5rem;
}

.mg-right-7\.5 {
  margin-right: 7.5rem;
}

.mg-top-7\.5 {
  margin-top: 7.5rem;
}

.mg-bottom-7\.5 {
  margin-bottom: 7.5rem;
}

.mg-x-7\.5 {
  margin-left: 7.5rem;
  margin-right: 7.5rem;
}

.mg-y-7\.5 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.mg-8 {
  margin: 8rem;
}

.mg-left-8 {
  margin-left: 8rem;
}

.mg-right-8 {
  margin-right: 8rem;
}

.mg-top-8 {
  margin-top: 8rem;
}

.mg-bottom-8 {
  margin-bottom: 8rem;
}

.mg-x-8 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mg-y-8 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.mg-8\.5 {
  margin: 8.5rem;
}

.mg-left-8\.5 {
  margin-left: 8.5rem;
}

.mg-right-8\.5 {
  margin-right: 8.5rem;
}

.mg-top-8\.5 {
  margin-top: 8.5rem;
}

.mg-bottom-8\.5 {
  margin-bottom: 8.5rem;
}

.mg-x-8\.5 {
  margin-left: 8.5rem;
  margin-right: 8.5rem;
}

.mg-y-8\.5 {
  margin-top: 8.5rem;
  margin-bottom: 8.5rem;
}

.mg-9 {
  margin: 9rem;
}

.mg-left-9 {
  margin-left: 9rem;
}

.mg-right-9 {
  margin-right: 9rem;
}

.mg-top-9 {
  margin-top: 9rem;
}

.mg-bottom-9 {
  margin-bottom: 9rem;
}

.mg-x-9 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.mg-y-9 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.mg-9\.5 {
  margin: 9.5rem;
}

.mg-left-9\.5 {
  margin-left: 9.5rem;
}

.mg-right-9\.5 {
  margin-right: 9.5rem;
}

.mg-top-9\.5 {
  margin-top: 9.5rem;
}

.mg-bottom-9\.5 {
  margin-bottom: 9.5rem;
}

.mg-x-9\.5 {
  margin-left: 9.5rem;
  margin-right: 9.5rem;
}

.mg-y-9\.5 {
  margin-top: 9.5rem;
  margin-bottom: 9.5rem;
}

.mg-10 {
  margin: 10rem;
}

.mg-left-10 {
  margin-left: 10rem;
}

.mg-right-10 {
  margin-right: 10rem;
}

.mg-top-10 {
  margin-top: 10rem;
}

.mg-bottom-10 {
  margin-bottom: 10rem;
}

.mg-x-10 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.mg-y-10 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.for-no-flex-shrink > * {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.for-full > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-align-items: stretch;
}

.for-full > * > .box {
  width: 100%;
}

.for-radius > * {
  border-radius: 0.25rem;
}

.for-cursor-pointer > * {
  cursor: pointer;
}

.for-spacing-s {
  margin-left: -0.5rem;
  margin-bottom: -0.5rem;
}

.for-spacing-s > * {
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}

.for-spacing-0 {
  margin-left: -0rem;
  margin-bottom: -0rem;
}

.for-spacing-0 > * {
  padding-left: 0rem;
  padding-bottom: 0rem;
}

.for-spacing-0\.5 {
  margin-left: -0.5rem;
  margin-bottom: -0.5rem;
}

.for-spacing-0\.5 > * {
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}

.for-spacing-1 {
  margin-left: -1rem;
  margin-bottom: -1rem;
}

.for-spacing-1 > * {
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.for-spacing-1\.5 {
  margin-left: -1.5rem;
  margin-bottom: -1.5rem;
}

.for-spacing-1\.5 > * {
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
}

.for-spacing-2 {
  margin-left: -2rem;
  margin-bottom: -2rem;
}

.for-spacing-2 > * {
  padding-left: 2rem;
  padding-bottom: 2rem;
}

.for-spacing-2\.5 {
  margin-left: -2.5rem;
  margin-bottom: -2.5rem;
}

.for-spacing-2\.5 > * {
  padding-left: 2.5rem;
  padding-bottom: 2.5rem;
}

.for-spacing-3 {
  margin-left: -3rem;
  margin-bottom: -3rem;
}

.for-spacing-3 > * {
  padding-left: 3rem;
  padding-bottom: 3rem;
}

.for-spacing-3\.5 {
  margin-left: -3.5rem;
  margin-bottom: -3.5rem;
}

.for-spacing-3\.5 > * {
  padding-left: 3.5rem;
  padding-bottom: 3.5rem;
}

.for-spacing-4 {
  margin-left: -4rem;
  margin-bottom: -4rem;
}

.for-spacing-4 > * {
  padding-left: 4rem;
  padding-bottom: 4rem;
}

.for-spacing-4\.5 {
  margin-left: -4.5rem;
  margin-bottom: -4.5rem;
}

.for-spacing-4\.5 > * {
  padding-left: 4.5rem;
  padding-bottom: 4.5rem;
}

.for-spacing-5 {
  margin-left: -5rem;
  margin-bottom: -5rem;
}

.for-spacing-5 > * {
  padding-left: 5rem;
  padding-bottom: 5rem;
}

.for-spacing-5\.5 {
  margin-left: -5.5rem;
  margin-bottom: -5.5rem;
}

.for-spacing-5\.5 > * {
  padding-left: 5.5rem;
  padding-bottom: 5.5rem;
}

.for-spacing-6 {
  margin-left: -6rem;
  margin-bottom: -6rem;
}

.for-spacing-6 > * {
  padding-left: 6rem;
  padding-bottom: 6rem;
}

.for-spacing-6\.5 {
  margin-left: -6.5rem;
  margin-bottom: -6.5rem;
}

.for-spacing-6\.5 > * {
  padding-left: 6.5rem;
  padding-bottom: 6.5rem;
}

.for-spacing-7 {
  margin-left: -7rem;
  margin-bottom: -7rem;
}

.for-spacing-7 > * {
  padding-left: 7rem;
  padding-bottom: 7rem;
}

.for-spacing-7\.5 {
  margin-left: -7.5rem;
  margin-bottom: -7.5rem;
}

.for-spacing-7\.5 > * {
  padding-left: 7.5rem;
  padding-bottom: 7.5rem;
}

.for-spacing-8 {
  margin-left: -8rem;
  margin-bottom: -8rem;
}

.for-spacing-8 > * {
  padding-left: 8rem;
  padding-bottom: 8rem;
}

.for-spacing-8\.5 {
  margin-left: -8.5rem;
  margin-bottom: -8.5rem;
}

.for-spacing-8\.5 > * {
  padding-left: 8.5rem;
  padding-bottom: 8.5rem;
}

.for-spacing-9 {
  margin-left: -9rem;
  margin-bottom: -9rem;
}

.for-spacing-9 > * {
  padding-left: 9rem;
  padding-bottom: 9rem;
}

.for-spacing-9\.5 {
  margin-left: -9.5rem;
  margin-bottom: -9.5rem;
}

.for-spacing-9\.5 > * {
  padding-left: 9.5rem;
  padding-bottom: 9.5rem;
}

.for-spacing-10 {
  margin-left: -10rem;
  margin-bottom: -10rem;
}

.for-spacing-10 > * {
  padding-left: 10rem;
  padding-bottom: 10rem;
}

.for-box-radius-0 > * > .box {
  border-radius: 0rem;
}

.for-box-radius-0\.1 > * > .box {
  border-radius: 0.1rem;
}

.for-box-radius-0\.2 > * > .box {
  border-radius: 0.2rem;
}

.for-box-radius-0\.3 > * > .box {
  border-radius: 0.3rem;
}

.for-box-radius-0\.4 > * > .box {
  border-radius: 0.4rem;
}

.for-box-radius-0\.5 > * > .box {
  border-radius: 0.5rem;
}

.for-box-radius-0\.6 > * > .box {
  border-radius: 0.6rem;
}

.for-box-radius-0\.7 > * > .box {
  border-radius: 0.7rem;
}

.for-box-radius-0\.8 > * > .box {
  border-radius: 0.8rem;
}

.for-box-radius-0\.9 > * > .box {
  border-radius: 0.9rem;
}

.for-box-radius-1 > * > .box {
  border-radius: 1rem;
}

.for-box-radius-1\.1 > * > .box {
  border-radius: 1.1rem;
}

.for-box-radius-1\.2 > * > .box {
  border-radius: 1.2rem;
}

.for-box-radius-1\.3 > * > .box {
  border-radius: 1.3rem;
}

.for-box-radius-1\.4 > * > .box {
  border-radius: 1.4rem;
}

.for-box-radius-1\.5 > * > .box {
  border-radius: 1.5rem;
}

.for-box-radius-1\.6 > * > .box {
  border-radius: 1.6rem;
}

.for-box-radius-1\.7 > * > .box {
  border-radius: 1.7rem;
}

.for-box-radius-1\.8 > * > .box {
  border-radius: 1.8rem;
}

.for-box-radius-1\.9 > * > .box {
  border-radius: 1.9rem;
}

.for-box-radius-2 > * > .box {
  border-radius: 2rem;
}

.for-box-radius-2\.1 > * > .box {
  border-radius: 2.1rem;
}

.for-box-radius-2\.2 > * > .box {
  border-radius: 2.2rem;
}

.for-box-radius-2\.3 > * > .box {
  border-radius: 2.3rem;
}

.for-box-radius-2\.4 > * > .box {
  border-radius: 2.4rem;
}

.for-box-radius-2\.5 > * > .box {
  border-radius: 2.5rem;
}

.for-box-radius-2\.6 > * > .box {
  border-radius: 2.6rem;
}

.for-box-radius-2\.7 > * > .box {
  border-radius: 2.7rem;
}

.for-box-radius-2\.8 > * > .box {
  border-radius: 2.8rem;
}

.for-box-radius-2\.9 > * > .box {
  border-radius: 2.9rem;
}

.for-box-radius-3 > * > .box {
  border-radius: 3rem;
}

.for-pd-0 > * {
  padding: 0rem;
}

.for-pd-left-0 > * {
  padding-left: 0rem;
}

.for-pd-right-0 > * {
  padding-right: 0rem;
}

.for-pd-top-0 > * {
  padding-top: 0rem;
}

.for-pd-bottom-0 > * {
  padding-bottom: 0rem;
}

.for-pd-x-0 > * {
  padding-left: 0rem;
  padding-right: 0rem;
}

.for-pd-y-0 > * {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.for-pd-0\.5 > * {
  padding: 0.5rem;
}

.for-pd-left-0\.5 > * {
  padding-left: 0.5rem;
}

.for-pd-right-0\.5 > * {
  padding-right: 0.5rem;
}

.for-pd-top-0\.5 > * {
  padding-top: 0.5rem;
}

.for-pd-bottom-0\.5 > * {
  padding-bottom: 0.5rem;
}

.for-pd-x-0\.5 > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.for-pd-y-0\.5 > * {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.for-pd-1 > * {
  padding: 1rem;
}

.for-pd-left-1 > * {
  padding-left: 1rem;
}

.for-pd-right-1 > * {
  padding-right: 1rem;
}

.for-pd-top-1 > * {
  padding-top: 1rem;
}

.for-pd-bottom-1 > * {
  padding-bottom: 1rem;
}

.for-pd-x-1 > * {
  padding-left: 1rem;
  padding-right: 1rem;
}

.for-pd-y-1 > * {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.for-pd-1\.5 > * {
  padding: 1.5rem;
}

.for-pd-left-1\.5 > * {
  padding-left: 1.5rem;
}

.for-pd-right-1\.5 > * {
  padding-right: 1.5rem;
}

.for-pd-top-1\.5 > * {
  padding-top: 1.5rem;
}

.for-pd-bottom-1\.5 > * {
  padding-bottom: 1.5rem;
}

.for-pd-x-1\.5 > * {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.for-pd-y-1\.5 > * {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.for-pd-2 > * {
  padding: 2rem;
}

.for-pd-left-2 > * {
  padding-left: 2rem;
}

.for-pd-right-2 > * {
  padding-right: 2rem;
}

.for-pd-top-2 > * {
  padding-top: 2rem;
}

.for-pd-bottom-2 > * {
  padding-bottom: 2rem;
}

.for-pd-x-2 > * {
  padding-left: 2rem;
  padding-right: 2rem;
}

.for-pd-y-2 > * {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.for-pd-2\.5 > * {
  padding: 2.5rem;
}

.for-pd-left-2\.5 > * {
  padding-left: 2.5rem;
}

.for-pd-right-2\.5 > * {
  padding-right: 2.5rem;
}

.for-pd-top-2\.5 > * {
  padding-top: 2.5rem;
}

.for-pd-bottom-2\.5 > * {
  padding-bottom: 2.5rem;
}

.for-pd-x-2\.5 > * {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.for-pd-y-2\.5 > * {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.for-pd-3 > * {
  padding: 3rem;
}

.for-pd-left-3 > * {
  padding-left: 3rem;
}

.for-pd-right-3 > * {
  padding-right: 3rem;
}

.for-pd-top-3 > * {
  padding-top: 3rem;
}

.for-pd-bottom-3 > * {
  padding-bottom: 3rem;
}

.for-pd-x-3 > * {
  padding-left: 3rem;
  padding-right: 3rem;
}

.for-pd-y-3 > * {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.for-pd-3\.5 > * {
  padding: 3.5rem;
}

.for-pd-left-3\.5 > * {
  padding-left: 3.5rem;
}

.for-pd-right-3\.5 > * {
  padding-right: 3.5rem;
}

.for-pd-top-3\.5 > * {
  padding-top: 3.5rem;
}

.for-pd-bottom-3\.5 > * {
  padding-bottom: 3.5rem;
}

.for-pd-x-3\.5 > * {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.for-pd-y-3\.5 > * {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.for-pd-4 > * {
  padding: 4rem;
}

.for-pd-left-4 > * {
  padding-left: 4rem;
}

.for-pd-right-4 > * {
  padding-right: 4rem;
}

.for-pd-top-4 > * {
  padding-top: 4rem;
}

.for-pd-bottom-4 > * {
  padding-bottom: 4rem;
}

.for-pd-x-4 > * {
  padding-left: 4rem;
  padding-right: 4rem;
}

.for-pd-y-4 > * {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.for-pd-4\.5 > * {
  padding: 4.5rem;
}

.for-pd-left-4\.5 > * {
  padding-left: 4.5rem;
}

.for-pd-right-4\.5 > * {
  padding-right: 4.5rem;
}

.for-pd-top-4\.5 > * {
  padding-top: 4.5rem;
}

.for-pd-bottom-4\.5 > * {
  padding-bottom: 4.5rem;
}

.for-pd-x-4\.5 > * {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.for-pd-y-4\.5 > * {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.for-pd-5 > * {
  padding: 5rem;
}

.for-pd-left-5 > * {
  padding-left: 5rem;
}

.for-pd-right-5 > * {
  padding-right: 5rem;
}

.for-pd-top-5 > * {
  padding-top: 5rem;
}

.for-pd-bottom-5 > * {
  padding-bottom: 5rem;
}

.for-pd-x-5 > * {
  padding-left: 5rem;
  padding-right: 5rem;
}

.for-pd-y-5 > * {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.for-pd-5\.5 > * {
  padding: 5.5rem;
}

.for-pd-left-5\.5 > * {
  padding-left: 5.5rem;
}

.for-pd-right-5\.5 > * {
  padding-right: 5.5rem;
}

.for-pd-top-5\.5 > * {
  padding-top: 5.5rem;
}

.for-pd-bottom-5\.5 > * {
  padding-bottom: 5.5rem;
}

.for-pd-x-5\.5 > * {
  padding-left: 5.5rem;
  padding-right: 5.5rem;
}

.for-pd-y-5\.5 > * {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}

.for-pd-6 > * {
  padding: 6rem;
}

.for-pd-left-6 > * {
  padding-left: 6rem;
}

.for-pd-right-6 > * {
  padding-right: 6rem;
}

.for-pd-top-6 > * {
  padding-top: 6rem;
}

.for-pd-bottom-6 > * {
  padding-bottom: 6rem;
}

.for-pd-x-6 > * {
  padding-left: 6rem;
  padding-right: 6rem;
}

.for-pd-y-6 > * {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.for-pd-6\.5 > * {
  padding: 6.5rem;
}

.for-pd-left-6\.5 > * {
  padding-left: 6.5rem;
}

.for-pd-right-6\.5 > * {
  padding-right: 6.5rem;
}

.for-pd-top-6\.5 > * {
  padding-top: 6.5rem;
}

.for-pd-bottom-6\.5 > * {
  padding-bottom: 6.5rem;
}

.for-pd-x-6\.5 > * {
  padding-left: 6.5rem;
  padding-right: 6.5rem;
}

.for-pd-y-6\.5 > * {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}

.for-pd-7 > * {
  padding: 7rem;
}

.for-pd-left-7 > * {
  padding-left: 7rem;
}

.for-pd-right-7 > * {
  padding-right: 7rem;
}

.for-pd-top-7 > * {
  padding-top: 7rem;
}

.for-pd-bottom-7 > * {
  padding-bottom: 7rem;
}

.for-pd-x-7 > * {
  padding-left: 7rem;
  padding-right: 7rem;
}

.for-pd-y-7 > * {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.for-pd-7\.5 > * {
  padding: 7.5rem;
}

.for-pd-left-7\.5 > * {
  padding-left: 7.5rem;
}

.for-pd-right-7\.5 > * {
  padding-right: 7.5rem;
}

.for-pd-top-7\.5 > * {
  padding-top: 7.5rem;
}

.for-pd-bottom-7\.5 > * {
  padding-bottom: 7.5rem;
}

.for-pd-x-7\.5 > * {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
}

.for-pd-y-7\.5 > * {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.for-pd-8 > * {
  padding: 8rem;
}

.for-pd-left-8 > * {
  padding-left: 8rem;
}

.for-pd-right-8 > * {
  padding-right: 8rem;
}

.for-pd-top-8 > * {
  padding-top: 8rem;
}

.for-pd-bottom-8 > * {
  padding-bottom: 8rem;
}

.for-pd-x-8 > * {
  padding-left: 8rem;
  padding-right: 8rem;
}

.for-pd-y-8 > * {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.for-pd-8\.5 > * {
  padding: 8.5rem;
}

.for-pd-left-8\.5 > * {
  padding-left: 8.5rem;
}

.for-pd-right-8\.5 > * {
  padding-right: 8.5rem;
}

.for-pd-top-8\.5 > * {
  padding-top: 8.5rem;
}

.for-pd-bottom-8\.5 > * {
  padding-bottom: 8.5rem;
}

.for-pd-x-8\.5 > * {
  padding-left: 8.5rem;
  padding-right: 8.5rem;
}

.for-pd-y-8\.5 > * {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
}

.for-pd-9 > * {
  padding: 9rem;
}

.for-pd-left-9 > * {
  padding-left: 9rem;
}

.for-pd-right-9 > * {
  padding-right: 9rem;
}

.for-pd-top-9 > * {
  padding-top: 9rem;
}

.for-pd-bottom-9 > * {
  padding-bottom: 9rem;
}

.for-pd-x-9 > * {
  padding-left: 9rem;
  padding-right: 9rem;
}

.for-pd-y-9 > * {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.for-pd-9\.5 > * {
  padding: 9.5rem;
}

.for-pd-left-9\.5 > * {
  padding-left: 9.5rem;
}

.for-pd-right-9\.5 > * {
  padding-right: 9.5rem;
}

.for-pd-top-9\.5 > * {
  padding-top: 9.5rem;
}

.for-pd-bottom-9\.5 > * {
  padding-bottom: 9.5rem;
}

.for-pd-x-9\.5 > * {
  padding-left: 9.5rem;
  padding-right: 9.5rem;
}

.for-pd-y-9\.5 > * {
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
}

.for-pd-10 > * {
  padding: 10rem;
}

.for-pd-left-10 > * {
  padding-left: 10rem;
}

.for-pd-right-10 > * {
  padding-right: 10rem;
}

.for-pd-top-10 > * {
  padding-top: 10rem;
}

.for-pd-bottom-10 > * {
  padding-bottom: 10rem;
}

.for-pd-x-10 > * {
  padding-left: 10rem;
  padding-right: 10rem;
}

.for-pd-y-10 > * {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.for-mg-0 > * {
  margin: 0rem;
}

.for-mg-left-0 > * {
  margin-left: 0rem;
}

.for-mg-right-0 > * {
  margin-right: 0rem;
}

.for-mg-top-0 > * {
  margin-top: 0rem;
}

.for-mg-bottom-0 > * {
  margin-bottom: 0rem;
}

.for-mg-x-0 > * {
  margin-left: 0rem;
  margin-right: 0rem;
}

.for-mg-y-0 > * {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.for-mg-0\.5 > * {
  margin: 0.5rem;
}

.for-mg-left-0\.5 > * {
  margin-left: 0.5rem;
}

.for-mg-right-0\.5 > * {
  margin-right: 0.5rem;
}

.for-mg-top-0\.5 > * {
  margin-top: 0.5rem;
}

.for-mg-bottom-0\.5 > * {
  margin-bottom: 0.5rem;
}

.for-mg-x-0\.5 > * {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.for-mg-y-0\.5 > * {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.for-mg-1 > * {
  margin: 1rem;
}

.for-mg-left-1 > * {
  margin-left: 1rem;
}

.for-mg-right-1 > * {
  margin-right: 1rem;
}

.for-mg-top-1 > * {
  margin-top: 1rem;
}

.for-mg-bottom-1 > * {
  margin-bottom: 1rem;
}

.for-mg-x-1 > * {
  margin-left: 1rem;
  margin-right: 1rem;
}

.for-mg-y-1 > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.for-mg-1\.5 > * {
  margin: 1.5rem;
}

.for-mg-left-1\.5 > * {
  margin-left: 1.5rem;
}

.for-mg-right-1\.5 > * {
  margin-right: 1.5rem;
}

.for-mg-top-1\.5 > * {
  margin-top: 1.5rem;
}

.for-mg-bottom-1\.5 > * {
  margin-bottom: 1.5rem;
}

.for-mg-x-1\.5 > * {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.for-mg-y-1\.5 > * {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.for-mg-2 > * {
  margin: 2rem;
}

.for-mg-left-2 > * {
  margin-left: 2rem;
}

.for-mg-right-2 > * {
  margin-right: 2rem;
}

.for-mg-top-2 > * {
  margin-top: 2rem;
}

.for-mg-bottom-2 > * {
  margin-bottom: 2rem;
}

.for-mg-x-2 > * {
  margin-left: 2rem;
  margin-right: 2rem;
}

.for-mg-y-2 > * {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.for-mg-2\.5 > * {
  margin: 2.5rem;
}

.for-mg-left-2\.5 > * {
  margin-left: 2.5rem;
}

.for-mg-right-2\.5 > * {
  margin-right: 2.5rem;
}

.for-mg-top-2\.5 > * {
  margin-top: 2.5rem;
}

.for-mg-bottom-2\.5 > * {
  margin-bottom: 2.5rem;
}

.for-mg-x-2\.5 > * {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.for-mg-y-2\.5 > * {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.for-mg-3 > * {
  margin: 3rem;
}

.for-mg-left-3 > * {
  margin-left: 3rem;
}

.for-mg-right-3 > * {
  margin-right: 3rem;
}

.for-mg-top-3 > * {
  margin-top: 3rem;
}

.for-mg-bottom-3 > * {
  margin-bottom: 3rem;
}

.for-mg-x-3 > * {
  margin-left: 3rem;
  margin-right: 3rem;
}

.for-mg-y-3 > * {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.for-mg-3\.5 > * {
  margin: 3.5rem;
}

.for-mg-left-3\.5 > * {
  margin-left: 3.5rem;
}

.for-mg-right-3\.5 > * {
  margin-right: 3.5rem;
}

.for-mg-top-3\.5 > * {
  margin-top: 3.5rem;
}

.for-mg-bottom-3\.5 > * {
  margin-bottom: 3.5rem;
}

.for-mg-x-3\.5 > * {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.for-mg-y-3\.5 > * {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.for-mg-4 > * {
  margin: 4rem;
}

.for-mg-left-4 > * {
  margin-left: 4rem;
}

.for-mg-right-4 > * {
  margin-right: 4rem;
}

.for-mg-top-4 > * {
  margin-top: 4rem;
}

.for-mg-bottom-4 > * {
  margin-bottom: 4rem;
}

.for-mg-x-4 > * {
  margin-left: 4rem;
  margin-right: 4rem;
}

.for-mg-y-4 > * {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.for-mg-4\.5 > * {
  margin: 4.5rem;
}

.for-mg-left-4\.5 > * {
  margin-left: 4.5rem;
}

.for-mg-right-4\.5 > * {
  margin-right: 4.5rem;
}

.for-mg-top-4\.5 > * {
  margin-top: 4.5rem;
}

.for-mg-bottom-4\.5 > * {
  margin-bottom: 4.5rem;
}

.for-mg-x-4\.5 > * {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}

.for-mg-y-4\.5 > * {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.for-mg-5 > * {
  margin: 5rem;
}

.for-mg-left-5 > * {
  margin-left: 5rem;
}

.for-mg-right-5 > * {
  margin-right: 5rem;
}

.for-mg-top-5 > * {
  margin-top: 5rem;
}

.for-mg-bottom-5 > * {
  margin-bottom: 5rem;
}

.for-mg-x-5 > * {
  margin-left: 5rem;
  margin-right: 5rem;
}

.for-mg-y-5 > * {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.for-mg-5\.5 > * {
  margin: 5.5rem;
}

.for-mg-left-5\.5 > * {
  margin-left: 5.5rem;
}

.for-mg-right-5\.5 > * {
  margin-right: 5.5rem;
}

.for-mg-top-5\.5 > * {
  margin-top: 5.5rem;
}

.for-mg-bottom-5\.5 > * {
  margin-bottom: 5.5rem;
}

.for-mg-x-5\.5 > * {
  margin-left: 5.5rem;
  margin-right: 5.5rem;
}

.for-mg-y-5\.5 > * {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}

.for-mg-6 > * {
  margin: 6rem;
}

.for-mg-left-6 > * {
  margin-left: 6rem;
}

.for-mg-right-6 > * {
  margin-right: 6rem;
}

.for-mg-top-6 > * {
  margin-top: 6rem;
}

.for-mg-bottom-6 > * {
  margin-bottom: 6rem;
}

.for-mg-x-6 > * {
  margin-left: 6rem;
  margin-right: 6rem;
}

.for-mg-y-6 > * {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.for-mg-6\.5 > * {
  margin: 6.5rem;
}

.for-mg-left-6\.5 > * {
  margin-left: 6.5rem;
}

.for-mg-right-6\.5 > * {
  margin-right: 6.5rem;
}

.for-mg-top-6\.5 > * {
  margin-top: 6.5rem;
}

.for-mg-bottom-6\.5 > * {
  margin-bottom: 6.5rem;
}

.for-mg-x-6\.5 > * {
  margin-left: 6.5rem;
  margin-right: 6.5rem;
}

.for-mg-y-6\.5 > * {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
}

.for-mg-7 > * {
  margin: 7rem;
}

.for-mg-left-7 > * {
  margin-left: 7rem;
}

.for-mg-right-7 > * {
  margin-right: 7rem;
}

.for-mg-top-7 > * {
  margin-top: 7rem;
}

.for-mg-bottom-7 > * {
  margin-bottom: 7rem;
}

.for-mg-x-7 > * {
  margin-left: 7rem;
  margin-right: 7rem;
}

.for-mg-y-7 > * {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.for-mg-7\.5 > * {
  margin: 7.5rem;
}

.for-mg-left-7\.5 > * {
  margin-left: 7.5rem;
}

.for-mg-right-7\.5 > * {
  margin-right: 7.5rem;
}

.for-mg-top-7\.5 > * {
  margin-top: 7.5rem;
}

.for-mg-bottom-7\.5 > * {
  margin-bottom: 7.5rem;
}

.for-mg-x-7\.5 > * {
  margin-left: 7.5rem;
  margin-right: 7.5rem;
}

.for-mg-y-7\.5 > * {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.for-mg-8 > * {
  margin: 8rem;
}

.for-mg-left-8 > * {
  margin-left: 8rem;
}

.for-mg-right-8 > * {
  margin-right: 8rem;
}

.for-mg-top-8 > * {
  margin-top: 8rem;
}

.for-mg-bottom-8 > * {
  margin-bottom: 8rem;
}

.for-mg-x-8 > * {
  margin-left: 8rem;
  margin-right: 8rem;
}

.for-mg-y-8 > * {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.for-mg-8\.5 > * {
  margin: 8.5rem;
}

.for-mg-left-8\.5 > * {
  margin-left: 8.5rem;
}

.for-mg-right-8\.5 > * {
  margin-right: 8.5rem;
}

.for-mg-top-8\.5 > * {
  margin-top: 8.5rem;
}

.for-mg-bottom-8\.5 > * {
  margin-bottom: 8.5rem;
}

.for-mg-x-8\.5 > * {
  margin-left: 8.5rem;
  margin-right: 8.5rem;
}

.for-mg-y-8\.5 > * {
  margin-top: 8.5rem;
  margin-bottom: 8.5rem;
}

.for-mg-9 > * {
  margin: 9rem;
}

.for-mg-left-9 > * {
  margin-left: 9rem;
}

.for-mg-right-9 > * {
  margin-right: 9rem;
}

.for-mg-top-9 > * {
  margin-top: 9rem;
}

.for-mg-bottom-9 > * {
  margin-bottom: 9rem;
}

.for-mg-x-9 > * {
  margin-left: 9rem;
  margin-right: 9rem;
}

.for-mg-y-9 > * {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.for-mg-9\.5 > * {
  margin: 9.5rem;
}

.for-mg-left-9\.5 > * {
  margin-left: 9.5rem;
}

.for-mg-right-9\.5 > * {
  margin-right: 9.5rem;
}

.for-mg-top-9\.5 > * {
  margin-top: 9.5rem;
}

.for-mg-bottom-9\.5 > * {
  margin-bottom: 9.5rem;
}

.for-mg-x-9\.5 > * {
  margin-left: 9.5rem;
  margin-right: 9.5rem;
}

.for-mg-y-9\.5 > * {
  margin-top: 9.5rem;
  margin-bottom: 9.5rem;
}

.for-mg-10 > * {
  margin: 10rem;
}

.for-mg-left-10 > * {
  margin-left: 10rem;
}

.for-mg-right-10 > * {
  margin-right: 10rem;
}

.for-mg-top-10 > * {
  margin-top: 10rem;
}

.for-mg-bottom-10 > * {
  margin-bottom: 10rem;
}

.for-mg-x-10 > * {
  margin-left: 10rem;
  margin-right: 10rem;
}

.for-mg-y-10 > * {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.for-box-light > * > .box {
  background-color: #f5f5f8;
}

.for-box-border > * > .box {
  border: 1px solid #E1E1E1;
}

.for-box-shadow > * > .box {
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
}

.for-box-shadow-bottom > * > .box {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-box-shadow-top > * > .box {
  -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-box-shadow-left > * > .box {
  -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
}

.for-box-shadow-right > * > .box {
  -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
}

.for-box-shadow-left-top > * > .box {
  -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-box-shadow-right-top > * > .box {
  -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-box-shadow-left-bottom > * > .box {
  -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-box-shadow-right-bottom > * > .box {
  -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-box-radius > * > .box {
  border-radius: 1rem;
}

.for-box-pd-0 > * > .box {
  padding: 0rem;
}

.for-box-pd-left-0 > * > .box {
  padding-left: 0rem;
}

.for-box-pd-right-0 > * > .box {
  padding-right: 0rem;
}

.for-box-pd-top-0 > * > .box {
  padding-top: 0rem;
}

.for-box-pd-bottom-0 > * > .box {
  padding-bottom: 0rem;
}

.for-box-pd-x-0 > * > .box {
  padding-left: 0rem;
  padding-right: 0rem;
}

.for-box-pd-y-0 > * > .box {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.for-box-pd-0\.5 > * > .box {
  padding: 0.5rem;
}

.for-box-pd-left-0\.5 > * > .box {
  padding-left: 0.5rem;
}

.for-box-pd-right-0\.5 > * > .box {
  padding-right: 0.5rem;
}

.for-box-pd-top-0\.5 > * > .box {
  padding-top: 0.5rem;
}

.for-box-pd-bottom-0\.5 > * > .box {
  padding-bottom: 0.5rem;
}

.for-box-pd-x-0\.5 > * > .box {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.for-box-pd-y-0\.5 > * > .box {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.for-box-pd-1 > * > .box {
  padding: 1rem;
}

.for-box-pd-left-1 > * > .box {
  padding-left: 1rem;
}

.for-box-pd-right-1 > * > .box {
  padding-right: 1rem;
}

.for-box-pd-top-1 > * > .box {
  padding-top: 1rem;
}

.for-box-pd-bottom-1 > * > .box {
  padding-bottom: 1rem;
}

.for-box-pd-x-1 > * > .box {
  padding-left: 1rem;
  padding-right: 1rem;
}

.for-box-pd-y-1 > * > .box {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.for-box-pd-1\.5 > * > .box {
  padding: 1.5rem;
}

.for-box-pd-left-1\.5 > * > .box {
  padding-left: 1.5rem;
}

.for-box-pd-right-1\.5 > * > .box {
  padding-right: 1.5rem;
}

.for-box-pd-top-1\.5 > * > .box {
  padding-top: 1.5rem;
}

.for-box-pd-bottom-1\.5 > * > .box {
  padding-bottom: 1.5rem;
}

.for-box-pd-x-1\.5 > * > .box {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.for-box-pd-y-1\.5 > * > .box {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.for-box-pd-2 > * > .box {
  padding: 2rem;
}

.for-box-pd-left-2 > * > .box {
  padding-left: 2rem;
}

.for-box-pd-right-2 > * > .box {
  padding-right: 2rem;
}

.for-box-pd-top-2 > * > .box {
  padding-top: 2rem;
}

.for-box-pd-bottom-2 > * > .box {
  padding-bottom: 2rem;
}

.for-box-pd-x-2 > * > .box {
  padding-left: 2rem;
  padding-right: 2rem;
}

.for-box-pd-y-2 > * > .box {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.for-box-pd-2\.5 > * > .box {
  padding: 2.5rem;
}

.for-box-pd-left-2\.5 > * > .box {
  padding-left: 2.5rem;
}

.for-box-pd-right-2\.5 > * > .box {
  padding-right: 2.5rem;
}

.for-box-pd-top-2\.5 > * > .box {
  padding-top: 2.5rem;
}

.for-box-pd-bottom-2\.5 > * > .box {
  padding-bottom: 2.5rem;
}

.for-box-pd-x-2\.5 > * > .box {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.for-box-pd-y-2\.5 > * > .box {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.for-box-pd-3 > * > .box {
  padding: 3rem;
}

.for-box-pd-left-3 > * > .box {
  padding-left: 3rem;
}

.for-box-pd-right-3 > * > .box {
  padding-right: 3rem;
}

.for-box-pd-top-3 > * > .box {
  padding-top: 3rem;
}

.for-box-pd-bottom-3 > * > .box {
  padding-bottom: 3rem;
}

.for-box-pd-x-3 > * > .box {
  padding-left: 3rem;
  padding-right: 3rem;
}

.for-box-pd-y-3 > * > .box {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.for-box-pd-3\.5 > * > .box {
  padding: 3.5rem;
}

.for-box-pd-left-3\.5 > * > .box {
  padding-left: 3.5rem;
}

.for-box-pd-right-3\.5 > * > .box {
  padding-right: 3.5rem;
}

.for-box-pd-top-3\.5 > * > .box {
  padding-top: 3.5rem;
}

.for-box-pd-bottom-3\.5 > * > .box {
  padding-bottom: 3.5rem;
}

.for-box-pd-x-3\.5 > * > .box {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.for-box-pd-y-3\.5 > * > .box {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.for-box-pd-4 > * > .box {
  padding: 4rem;
}

.for-box-pd-left-4 > * > .box {
  padding-left: 4rem;
}

.for-box-pd-right-4 > * > .box {
  padding-right: 4rem;
}

.for-box-pd-top-4 > * > .box {
  padding-top: 4rem;
}

.for-box-pd-bottom-4 > * > .box {
  padding-bottom: 4rem;
}

.for-box-pd-x-4 > * > .box {
  padding-left: 4rem;
  padding-right: 4rem;
}

.for-box-pd-y-4 > * > .box {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.for-box-pd-4\.5 > * > .box {
  padding: 4.5rem;
}

.for-box-pd-left-4\.5 > * > .box {
  padding-left: 4.5rem;
}

.for-box-pd-right-4\.5 > * > .box {
  padding-right: 4.5rem;
}

.for-box-pd-top-4\.5 > * > .box {
  padding-top: 4.5rem;
}

.for-box-pd-bottom-4\.5 > * > .box {
  padding-bottom: 4.5rem;
}

.for-box-pd-x-4\.5 > * > .box {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.for-box-pd-y-4\.5 > * > .box {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.for-box-pd-5 > * > .box {
  padding: 5rem;
}

.for-box-pd-left-5 > * > .box {
  padding-left: 5rem;
}

.for-box-pd-right-5 > * > .box {
  padding-right: 5rem;
}

.for-box-pd-top-5 > * > .box {
  padding-top: 5rem;
}

.for-box-pd-bottom-5 > * > .box {
  padding-bottom: 5rem;
}

.for-box-pd-x-5 > * > .box {
  padding-left: 5rem;
  padding-right: 5rem;
}

.for-box-pd-y-5 > * > .box {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.for-box-pd-5\.5 > * > .box {
  padding: 5.5rem;
}

.for-box-pd-left-5\.5 > * > .box {
  padding-left: 5.5rem;
}

.for-box-pd-right-5\.5 > * > .box {
  padding-right: 5.5rem;
}

.for-box-pd-top-5\.5 > * > .box {
  padding-top: 5.5rem;
}

.for-box-pd-bottom-5\.5 > * > .box {
  padding-bottom: 5.5rem;
}

.for-box-pd-x-5\.5 > * > .box {
  padding-left: 5.5rem;
  padding-right: 5.5rem;
}

.for-box-pd-y-5\.5 > * > .box {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}

.for-box-pd-6 > * > .box {
  padding: 6rem;
}

.for-box-pd-left-6 > * > .box {
  padding-left: 6rem;
}

.for-box-pd-right-6 > * > .box {
  padding-right: 6rem;
}

.for-box-pd-top-6 > * > .box {
  padding-top: 6rem;
}

.for-box-pd-bottom-6 > * > .box {
  padding-bottom: 6rem;
}

.for-box-pd-x-6 > * > .box {
  padding-left: 6rem;
  padding-right: 6rem;
}

.for-box-pd-y-6 > * > .box {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.for-box-pd-6\.5 > * > .box {
  padding: 6.5rem;
}

.for-box-pd-left-6\.5 > * > .box {
  padding-left: 6.5rem;
}

.for-box-pd-right-6\.5 > * > .box {
  padding-right: 6.5rem;
}

.for-box-pd-top-6\.5 > * > .box {
  padding-top: 6.5rem;
}

.for-box-pd-bottom-6\.5 > * > .box {
  padding-bottom: 6.5rem;
}

.for-box-pd-x-6\.5 > * > .box {
  padding-left: 6.5rem;
  padding-right: 6.5rem;
}

.for-box-pd-y-6\.5 > * > .box {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}

.for-box-pd-7 > * > .box {
  padding: 7rem;
}

.for-box-pd-left-7 > * > .box {
  padding-left: 7rem;
}

.for-box-pd-right-7 > * > .box {
  padding-right: 7rem;
}

.for-box-pd-top-7 > * > .box {
  padding-top: 7rem;
}

.for-box-pd-bottom-7 > * > .box {
  padding-bottom: 7rem;
}

.for-box-pd-x-7 > * > .box {
  padding-left: 7rem;
  padding-right: 7rem;
}

.for-box-pd-y-7 > * > .box {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.for-box-pd-7\.5 > * > .box {
  padding: 7.5rem;
}

.for-box-pd-left-7\.5 > * > .box {
  padding-left: 7.5rem;
}

.for-box-pd-right-7\.5 > * > .box {
  padding-right: 7.5rem;
}

.for-box-pd-top-7\.5 > * > .box {
  padding-top: 7.5rem;
}

.for-box-pd-bottom-7\.5 > * > .box {
  padding-bottom: 7.5rem;
}

.for-box-pd-x-7\.5 > * > .box {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
}

.for-box-pd-y-7\.5 > * > .box {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.for-box-pd-8 > * > .box {
  padding: 8rem;
}

.for-box-pd-left-8 > * > .box {
  padding-left: 8rem;
}

.for-box-pd-right-8 > * > .box {
  padding-right: 8rem;
}

.for-box-pd-top-8 > * > .box {
  padding-top: 8rem;
}

.for-box-pd-bottom-8 > * > .box {
  padding-bottom: 8rem;
}

.for-box-pd-x-8 > * > .box {
  padding-left: 8rem;
  padding-right: 8rem;
}

.for-box-pd-y-8 > * > .box {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.for-box-pd-8\.5 > * > .box {
  padding: 8.5rem;
}

.for-box-pd-left-8\.5 > * > .box {
  padding-left: 8.5rem;
}

.for-box-pd-right-8\.5 > * > .box {
  padding-right: 8.5rem;
}

.for-box-pd-top-8\.5 > * > .box {
  padding-top: 8.5rem;
}

.for-box-pd-bottom-8\.5 > * > .box {
  padding-bottom: 8.5rem;
}

.for-box-pd-x-8\.5 > * > .box {
  padding-left: 8.5rem;
  padding-right: 8.5rem;
}

.for-box-pd-y-8\.5 > * > .box {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
}

.for-box-pd-9 > * > .box {
  padding: 9rem;
}

.for-box-pd-left-9 > * > .box {
  padding-left: 9rem;
}

.for-box-pd-right-9 > * > .box {
  padding-right: 9rem;
}

.for-box-pd-top-9 > * > .box {
  padding-top: 9rem;
}

.for-box-pd-bottom-9 > * > .box {
  padding-bottom: 9rem;
}

.for-box-pd-x-9 > * > .box {
  padding-left: 9rem;
  padding-right: 9rem;
}

.for-box-pd-y-9 > * > .box {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.for-box-pd-9\.5 > * > .box {
  padding: 9.5rem;
}

.for-box-pd-left-9\.5 > * > .box {
  padding-left: 9.5rem;
}

.for-box-pd-right-9\.5 > * > .box {
  padding-right: 9.5rem;
}

.for-box-pd-top-9\.5 > * > .box {
  padding-top: 9.5rem;
}

.for-box-pd-bottom-9\.5 > * > .box {
  padding-bottom: 9.5rem;
}

.for-box-pd-x-9\.5 > * > .box {
  padding-left: 9.5rem;
  padding-right: 9.5rem;
}

.for-box-pd-y-9\.5 > * > .box {
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
}

.for-box-pd-10 > * > .box {
  padding: 10rem;
}

.for-box-pd-left-10 > * > .box {
  padding-left: 10rem;
}

.for-box-pd-right-10 > * > .box {
  padding-right: 10rem;
}

.for-box-pd-top-10 > * > .box {
  padding-top: 10rem;
}

.for-box-pd-bottom-10 > * > .box {
  padding-bottom: 10rem;
}

.for-box-pd-x-10 > * > .box {
  padding-left: 10rem;
  padding-right: 10rem;
}

.for-box-pd-y-10 > * > .box {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.for-bgpic-radius > * > .bgpic {
  border-radius: 0.25rem;
  overflow: hidden;
}

.for-bgpic-border > * > .bgpic {
  border: 1px solid #E1E1E1;
}

.for-border > * {
  border: 1px solid #E1E1E1;
}

.for-hv-shadow > * {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-shadow > *:hover {
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-shadow-bottom > * {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-shadow-bottom > *:hover {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-shadow-top > * {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-shadow-top > *:hover {
  -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-shadow-left > * {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-shadow-left > *:hover {
  -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-shadow-right > * {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-shadow-right > *:hover {
  -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-shadow-left-top > * {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-shadow-left-top > *:hover {
  -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-shadow-right-top > * {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-shadow-right-top > *:hover {
  -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-shadow-left-bottom > * {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-shadow-left-bottom > *:hover {
  -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-shadow-right-bottom > * {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-shadow-right-bottom > *:hover {
  -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-box-shadow > * > .box {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-box-shadow > * > .box:hover {
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-box-shadow-bottom > * > .box {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-box-shadow-bottom > * > .box:hover {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-box-shadow-top > * > .box {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-box-shadow-top > * > .box:hover {
  -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-box-shadow-left > * > .box {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-box-shadow-left > * > .box:hover {
  -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-box-shadow-right > * > .box {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-box-shadow-right > * > .box:hover {
  -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-box-shadow-left-top > * > .box {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-box-shadow-left-top > * > .box:hover {
  -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-box-shadow-right-top > * > .box {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-box-shadow-right-top > * > .box:hover {
  -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-box-shadow-left-bottom > * > .box {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-box-shadow-left-bottom > * > .box:hover {
  -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-box-shadow-right-bottom > * > .box {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.for-hv-box-shadow-right-bottom > * > .box:hover {
  -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
          box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

.for-hv-ani-pop > *, .for-hv-ani-push > *, .for-hv-ani-pulse > *, .for-hv-ani-float > *, .for-hv-ani-bob > *, .for-hv-ani-skew > *, .for-hv-ani-wobble > * {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.for-hv-ani-pop > *:hover {
  -webkit-animation-name: pop;
          animation-name: pop;
}

.for-hv-ani-push > *:hover {
  -webkit-animation-name: push;
          animation-name: push;
}

.for-hv-ani-pulse > *:hover {
  -webkit-animation-name: pulse;
          animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.for-hv-ani-float > *:hover {
  -webkit-transform: translateY(-0.5rem);
          transform: translateY(-0.5rem);
}

.for-hv-ani-bob > *:hover {
  -webkit-animation-name: bob;
          animation-name: bob;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.for-hv-ani-skew > *:hover {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
}

.for-hv-ani-wobble > *:hover {
  -webkit-animation-name: wobble-top;
          animation-name: wobble-top;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.for-hv-box-ani-pop > * > .box, .for-hv-box-ani-push > * > .box, .for-hv-box-ani-pulse > * > .box, .for-hv-box-ani-float > * > .box, .for-hv-box-ani-bob > * > .box, .for-hv-box-ani-skew > * > .box, .for-hv-box-ani-wobble > * > .box {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.for-hv-box-ani-pop > * > .box:hover {
  -webkit-animation-name: pop;
          animation-name: pop;
}

.for-hv-box-ani-push > * > .box:hover {
  -webkit-animation-name: push;
          animation-name: push;
}

.for-hv-box-ani-pulse > * > .box:hover {
  -webkit-animation-name: pulse;
          animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.for-hv-box-ani-float > * > .box:hover {
  -webkit-transform: translateY(-0.5rem);
          transform: translateY(-0.5rem);
}

.for-hv-box-ani-bob > * > .box:hover {
  -webkit-animation-name: bob;
          animation-name: bob;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.for-hv-box-ani-skew > * > .box:hover {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
}

.for-hv-box-ani-wobble > * > .box:hover {
  -webkit-animation-name: wobble-top;
          animation-name: wobble-top;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

@-webkit-keyframes loading-zoom-a {
  50% {
    -webkit-transform: scale(4);
            transform: scale(4);
  }
}

@keyframes loading-zoom-a {
  50% {
    -webkit-transform: scale(4);
            transform: scale(4);
  }
}

@-webkit-keyframes loading-zoom-b {
  50% {
    -webkit-transform: scale(2.5);
            transform: scale(2.5);
  }
}

@keyframes loading-zoom-b {
  50% {
    -webkit-transform: scale(2.5);
            transform: scale(2.5);
  }
}

@-webkit-keyframes loading-rotating {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loading-rotating {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes wobble-top {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}

@keyframes wobble-top {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}

@-webkit-keyframes bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@-webkit-keyframes push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes fade-zoom-in {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(3, 3, 1);
            transform: scale3d(3, 3, 1);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-zoom-in {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(3, 3, 1);
            transform: scale3d(3, 3, 1);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-zoom-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(3, 3, 1);
            transform: scale3d(3, 3, 1);
  }
}

@keyframes fade-zoom-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(3, 3, 1);
            transform: scale3d(3, 3, 1);
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

.loading-zoom {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: rgba(0, 123, 255, 0.34);
  display: inline-block;
  position: relative;
  -webkit-transform: scale(0.25);
          transform: scale(0.25);
  vertical-align: middle;
  pointer-events: none;
}

.loading-zoom::before {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: inherit;
  -webkit-animation: loading-zoom-a 1.5s infinite;
          animation: loading-zoom-a 1.5s infinite;
}

.loading-zoom::after {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: inherit;
  -webkit-animation: loading-zoom-b 1.5s infinite;
          animation: loading-zoom-b 1.5s infinite;
}

.loading-rotating {
  border: 0.1rem solid #007bff;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  border-bottom-color: transparent;
  -webkit-animation: loading-rotating 1s infinite linear;
          animation: loading-rotating 1s infinite linear;
  vertical-align: middle;
  pointer-events: none;
}

@media (max-width: 1024px) {
  .f-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .f-absolute {
    position: absolute;
  }
  .f-fixed {
    position: fixed;
  }
  .f-relative {
    position: relative;
  }
  .f-bold {
    font-weight: bold;
  }
  .f-lighter {
    font-weight: lighter;
    font-family: "Microsoft Yahei" !important;
  }
  .f-hide {
    display: none;
  }
  .f-hidden {
    visibility: hidden;
  }
  .f-left {
    left: 0;
  }
  .f-right {
    right: 0;
  }
  .f-top {
    top: 0;
  }
  .f-bottom {
    bottom: 0;
  }
  .f-h-auto {
    height: auto;
  }
  .f-w-auto {
    width: auto;
  }
  .f-vmiddle {
    vertical-align: middle;
  }
  .f-vtop {
    vertical-align: top;
  }
  .f-vbottom {
    vertical-align: bottom;
  }
  .f-float-left {
    float: left;
  }
  .f-float-right {
    float: right;
  }
  .f-float-none {
    float: none;
  }
  .f-show, .f-display {
    display: block;
  }
  .f-show-block, .f-display-block {
    display: block;
  }
  .f-show-table, .f-display-table {
    display: table;
  }
  .f-show-inline, .f-display-inline {
    display: inline;
  }
  .f-show-inline-block, .f-display-inline-block {
    display: inline-block;
  }
  .f-show-flex, .f-display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .f-weight-1 {
    font-weight: 100;
  }
  .f-weight-2 {
    font-weight: 200;
  }
  .f-weight-3 {
    font-weight: 300;
  }
  .f-weight-4 {
    font-weight: 400;
  }
  .f-weight-5 {
    font-weight: 500;
  }
  .f-weight-6 {
    font-weight: 600;
  }
  .f-weight-7 {
    font-weight: 700;
  }
  .f-weight-8 {
    font-weight: 800;
  }
  .f-weight-9 {
    font-weight: 900;
  }
  .f-opacity-0 {
    opacity: 0;
  }
  .f-opacity-0\.1 {
    opacity: 0.1;
  }
  .f-opacity-0\.2 {
    opacity: 0.2;
  }
  .f-opacity-0\.3 {
    opacity: 0.3;
  }
  .f-opacity-0\.4 {
    opacity: 0.4;
  }
  .f-opacity-0\.5 {
    opacity: 0.5;
  }
  .f-opacity-0\.6 {
    opacity: 0.6;
  }
  .f-opacity-0\.7 {
    opacity: 0.7;
  }
  .f-opacity-0\.8 {
    opacity: 0.8;
  }
  .f-opacity-0\.9 {
    opacity: 0.9;
  }
  .f-opacity-1 {
    opacity: 1;
  }
  .f-col-auto {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 1px;
  }
  .f-for-col-auto > * {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 1px;
  }
  .f-col-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    min-width: 1px;
  }
  .f-for-col-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
  }
  .f-col-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    min-width: 1px;
  }
  .f-for-col-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
  }
  .f-col-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    min-width: 1px;
  }
  .f-for-col-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
  }
  .f-col-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    min-width: 1px;
  }
  .f-for-col-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
  }
  .f-col-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    min-width: 1px;
  }
  .f-for-col-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
  }
  .f-col-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    min-width: 1px;
  }
  .f-for-col-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
  .f-col-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    min-width: 1px;
  }
  .f-for-col-7 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
  }
  .f-col-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    min-width: 1px;
  }
  .f-for-col-8 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
  }
  .f-col-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    min-width: 1px;
  }
  .f-for-col-9 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
  }
  .f-col-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    min-width: 1px;
  }
  .f-for-col-10 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
  }
  .f-col-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    min-width: 1px;
  }
  .f-for-col-11 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
  }
  .f-col-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    min-width: 1px;
  }
  .f-for-col-12 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
  .f-width-1 {
    width: 1rem;
  }
  .f-width-2 {
    width: 2rem;
  }
  .f-width-3 {
    width: 3rem;
  }
  .f-width-4 {
    width: 4rem;
  }
  .f-width-5 {
    width: 5rem;
  }
  .f-width-6 {
    width: 6rem;
  }
  .f-width-7 {
    width: 7rem;
  }
  .f-width-8 {
    width: 8rem;
  }
  .f-width-9 {
    width: 9rem;
  }
  .f-width-10 {
    width: 10rem;
  }
  .f-fs-0\.1 {
    font-size: 0.1rem;
  }
  .f-fs-0\.2 {
    font-size: 0.2rem;
  }
  .f-fs-0\.3 {
    font-size: 0.3rem;
  }
  .f-fs-0\.4 {
    font-size: 0.4rem;
  }
  .f-fs-0\.5 {
    font-size: 0.5rem;
  }
  .f-fs-0\.6 {
    font-size: 0.6rem;
  }
  .f-fs-0\.7 {
    font-size: 0.7rem;
  }
  .f-fs-0\.8 {
    font-size: 0.8rem;
  }
  .f-fs-0\.9 {
    font-size: 0.9rem;
  }
  .f-fs-1 {
    font-size: 1rem;
  }
  .f-fs-1\.1 {
    font-size: 1.1rem;
  }
  .f-fs-1\.2 {
    font-size: 1.2rem;
  }
  .f-fs-1\.3 {
    font-size: 1.3rem;
  }
  .f-fs-1\.4 {
    font-size: 1.4rem;
  }
  .f-fs-1\.5 {
    font-size: 1.5rem;
  }
  .f-fs-1\.6 {
    font-size: 1.6rem;
  }
  .f-fs-1\.7 {
    font-size: 1.7rem;
  }
  .f-fs-1\.8 {
    font-size: 1.8rem;
  }
  .f-fs-1\.9 {
    font-size: 1.9rem;
  }
  .f-fs-2 {
    font-size: 2rem;
  }
  .f-fs-2\.1 {
    font-size: 2.1rem;
  }
  .f-fs-2\.2 {
    font-size: 2.2rem;
  }
  .f-fs-2\.3 {
    font-size: 2.3rem;
  }
  .f-fs-2\.4 {
    font-size: 2.4rem;
  }
  .f-fs-2\.5 {
    font-size: 2.5rem;
  }
  .f-fs-2\.6 {
    font-size: 2.6rem;
  }
  .f-fs-2\.7 {
    font-size: 2.7rem;
  }
  .f-fs-2\.8 {
    font-size: 2.8rem;
  }
  .f-fs-2\.9 {
    font-size: 2.9rem;
  }
  .f-fs-3 {
    font-size: 3rem;
  }
  .f-fs-3\.1 {
    font-size: 3.1rem;
  }
  .f-fs-3\.2 {
    font-size: 3.2rem;
  }
  .f-fs-3\.3 {
    font-size: 3.3rem;
  }
  .f-fs-3\.4 {
    font-size: 3.4rem;
  }
  .f-fs-3\.5 {
    font-size: 3.5rem;
  }
  .f-fs-3\.6 {
    font-size: 3.6rem;
  }
  .f-fs-3\.7 {
    font-size: 3.7rem;
  }
  .f-fs-3\.8 {
    font-size: 3.8rem;
  }
  .f-fs-3\.9 {
    font-size: 3.9rem;
  }
  .f-fs-4 {
    font-size: 4rem;
  }
  .f-fs-4\.1 {
    font-size: 4.1rem;
  }
  .f-fs-4\.2 {
    font-size: 4.2rem;
  }
  .f-fs-4\.3 {
    font-size: 4.3rem;
  }
  .f-fs-4\.4 {
    font-size: 4.4rem;
  }
  .f-fs-4\.5 {
    font-size: 4.5rem;
  }
  .f-fs-4\.6 {
    font-size: 4.6rem;
  }
  .f-fs-4\.7 {
    font-size: 4.7rem;
  }
  .f-fs-4\.8 {
    font-size: 4.8rem;
  }
  .f-fs-4\.9 {
    font-size: 4.9rem;
  }
  .f-fs-5 {
    font-size: 5rem;
  }
  .f-fs-5\.1 {
    font-size: 5.1rem;
  }
  .f-fs-5\.2 {
    font-size: 5.2rem;
  }
  .f-fs-5\.3 {
    font-size: 5.3rem;
  }
  .f-fs-5\.4 {
    font-size: 5.4rem;
  }
  .f-fs-5\.5 {
    font-size: 5.5rem;
  }
  .f-fs-5\.6 {
    font-size: 5.6rem;
  }
  .f-fs-5\.7 {
    font-size: 5.7rem;
  }
  .f-fs-5\.8 {
    font-size: 5.8rem;
  }
  .f-fs-5\.9 {
    font-size: 5.9rem;
  }
  .f-fs-6 {
    font-size: 6rem;
  }
  .f-fs-6\.1 {
    font-size: 6.1rem;
  }
  .f-fs-6\.2 {
    font-size: 6.2rem;
  }
  .f-fs-6\.3 {
    font-size: 6.3rem;
  }
  .f-fs-6\.4 {
    font-size: 6.4rem;
  }
  .f-fs-6\.5 {
    font-size: 6.5rem;
  }
  .f-fs-6\.6 {
    font-size: 6.6rem;
  }
  .f-fs-6\.7 {
    font-size: 6.7rem;
  }
  .f-fs-6\.8 {
    font-size: 6.8rem;
  }
  .f-fs-6\.9 {
    font-size: 6.9rem;
  }
  .f-fs-7 {
    font-size: 7rem;
  }
  .f-fs-7\.1 {
    font-size: 7.1rem;
  }
  .f-fs-7\.2 {
    font-size: 7.2rem;
  }
  .f-fs-7\.3 {
    font-size: 7.3rem;
  }
  .f-fs-7\.4 {
    font-size: 7.4rem;
  }
  .f-fs-7\.5 {
    font-size: 7.5rem;
  }
  .f-fs-7\.6 {
    font-size: 7.6rem;
  }
  .f-fs-7\.7 {
    font-size: 7.7rem;
  }
  .f-fs-7\.8 {
    font-size: 7.8rem;
  }
  .f-fs-7\.9 {
    font-size: 7.9rem;
  }
  .f-fs-8 {
    font-size: 8rem;
  }
  .f-fs-8\.1 {
    font-size: 8.1rem;
  }
  .f-fs-8\.2 {
    font-size: 8.2rem;
  }
  .f-fs-8\.3 {
    font-size: 8.3rem;
  }
  .f-fs-8\.4 {
    font-size: 8.4rem;
  }
  .f-fs-8\.5 {
    font-size: 8.5rem;
  }
  .f-fs-8\.6 {
    font-size: 8.6rem;
  }
  .f-fs-8\.7 {
    font-size: 8.7rem;
  }
  .f-fs-8\.8 {
    font-size: 8.8rem;
  }
  .f-fs-8\.9 {
    font-size: 8.9rem;
  }
  .f-fs-9 {
    font-size: 9rem;
  }
  .f-fs-9\.1 {
    font-size: 9.1rem;
  }
  .f-fs-9\.2 {
    font-size: 9.2rem;
  }
  .f-fs-9\.3 {
    font-size: 9.3rem;
  }
  .f-fs-9\.4 {
    font-size: 9.4rem;
  }
  .f-fs-9\.5 {
    font-size: 9.5rem;
  }
  .f-fs-9\.6 {
    font-size: 9.6rem;
  }
  .f-fs-9\.7 {
    font-size: 9.7rem;
  }
  .f-fs-9\.8 {
    font-size: 9.8rem;
  }
  .f-fs-9\.9 {
    font-size: 9.9rem;
  }
  .f-fs-10 {
    font-size: 10rem;
  }
  .f-fs-14 {
    font-size: 0.875rem;
  }
  .f-fs-18 {
    font-size: 1.125rem;
  }
  .f-fs-large {
    font-size: 3rem;
  }
  .f-line-1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .f-line-2 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .f-line-3 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  .f-line-4 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
  .f-line-5 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }
  .f-line-6 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
  .f-line-7 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
  }
  .f-line-8 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
  }
  .f-line-9 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;
  }
  .f-line-10 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
  }
  .f-lh-0\.2 {
    line-height: 0.2;
  }
  .f-lh-02 {
    line-height: 0.2;
  }
  .f-lh-0\.3 {
    line-height: 0.3;
  }
  .f-lh-03 {
    line-height: 0.3;
  }
  .f-lh-0\.4 {
    line-height: 0.4;
  }
  .f-lh-04 {
    line-height: 0.4;
  }
  .f-lh-0\.5 {
    line-height: 0.5;
  }
  .f-lh-05 {
    line-height: 0.5;
  }
  .f-lh-0\.6 {
    line-height: 0.6;
  }
  .f-lh-06 {
    line-height: 0.6;
  }
  .f-lh-0\.7 {
    line-height: 0.7;
  }
  .f-lh-07 {
    line-height: 0.7;
  }
  .f-lh-0\.8 {
    line-height: 0.8;
  }
  .f-lh-08 {
    line-height: 0.8;
  }
  .f-lh-0\.9 {
    line-height: 0.9;
  }
  .f-lh-09 {
    line-height: 0.9;
  }
  .f-lh-1 {
    line-height: 1;
  }
  .f-lh-1 {
    line-height: 1;
  }
  .f-lh-1\.1 {
    line-height: 1.1;
  }
  .f-lh-11 {
    line-height: 1.1;
  }
  .f-lh-1\.2 {
    line-height: 1.2;
  }
  .f-lh-12 {
    line-height: 1.2;
  }
  .f-lh-1\.3 {
    line-height: 1.3;
  }
  .f-lh-13 {
    line-height: 1.3;
  }
  .f-lh-1\.4 {
    line-height: 1.4;
  }
  .f-lh-14 {
    line-height: 1.4;
  }
  .f-lh-1\.5 {
    line-height: 1.5;
  }
  .f-lh-15 {
    line-height: 1.5;
  }
  .f-lh-1\.6 {
    line-height: 1.6;
  }
  .f-lh-16 {
    line-height: 1.6;
  }
  .f-lh-1\.7 {
    line-height: 1.7;
  }
  .f-lh-17 {
    line-height: 1.7;
  }
  .f-lh-1\.8 {
    line-height: 1.8;
  }
  .f-lh-18 {
    line-height: 1.8;
  }
  .f-lh-1\.9 {
    line-height: 1.9;
  }
  .f-lh-19 {
    line-height: 1.9;
  }
  .f-lh-2 {
    line-height: 2;
  }
  .f-lh-2 {
    line-height: 2;
  }
  .f-lh-2\.1 {
    line-height: 2.1;
  }
  .f-lh-21 {
    line-height: 2.1;
  }
  .f-lh-2\.2 {
    line-height: 2.2;
  }
  .f-lh-22 {
    line-height: 2.2;
  }
  .f-lh-2\.3 {
    line-height: 2.3;
  }
  .f-lh-23 {
    line-height: 2.3;
  }
  .f-lh-2\.4 {
    line-height: 2.4;
  }
  .f-lh-24 {
    line-height: 2.4;
  }
  .f-lh-2\.5 {
    line-height: 2.5;
  }
  .f-lh-25 {
    line-height: 2.5;
  }
  .f-lh-2\.6 {
    line-height: 2.6;
  }
  .f-lh-26 {
    line-height: 2.6;
  }
  .f-lh-2\.7 {
    line-height: 2.7;
  }
  .f-lh-27 {
    line-height: 2.7;
  }
  .f-lh-2\.8 {
    line-height: 2.8;
  }
  .f-lh-28 {
    line-height: 2.8;
  }
  .f-lh-2\.9 {
    line-height: 2.9;
  }
  .f-lh-29 {
    line-height: 2.9;
  }
  .f-lh-3 {
    line-height: 3;
  }
  .f-lh-3 {
    line-height: 3;
  }
  .f-lh-3\.1 {
    line-height: 3.1;
  }
  .f-lh-31 {
    line-height: 3.1;
  }
  .f-lh-3\.2 {
    line-height: 3.2;
  }
  .f-lh-32 {
    line-height: 3.2;
  }
  .f-lh-3\.3 {
    line-height: 3.3;
  }
  .f-lh-33 {
    line-height: 3.3;
  }
  .f-lh-3\.4 {
    line-height: 3.4;
  }
  .f-lh-34 {
    line-height: 3.4;
  }
  .f-lh-3\.5 {
    line-height: 3.5;
  }
  .f-lh-35 {
    line-height: 3.5;
  }
  .f-w-auto {
    width: auto;
  }
  .f-for-w-auto > * {
    width: auto;
  }
  .f-w-1 {
    width: 8.33333%;
  }
  .f-for-w-1 > * {
    width: 8.33333%;
  }
  .f-w-2 {
    width: 16.66667%;
  }
  .f-for-w-2 > * {
    width: 16.66667%;
  }
  .f-w-3 {
    width: 25%;
  }
  .f-for-w-3 > * {
    width: 25%;
  }
  .f-w-4 {
    width: 33.33333%;
  }
  .f-for-w-4 > * {
    width: 33.33333%;
  }
  .f-w-5 {
    width: 41.66667%;
  }
  .f-for-w-5 > * {
    width: 41.66667%;
  }
  .f-w-6 {
    width: 50%;
  }
  .f-for-w-6 > * {
    width: 50%;
  }
  .f-w-7 {
    width: 58.33333%;
  }
  .f-for-w-7 > * {
    width: 58.33333%;
  }
  .f-w-8 {
    width: 66.66667%;
  }
  .f-for-w-8 > * {
    width: 66.66667%;
  }
  .f-w-9 {
    width: 75%;
  }
  .f-for-w-9 > * {
    width: 75%;
  }
  .f-w-10 {
    width: 83.33333%;
  }
  .f-for-w-10 > * {
    width: 83.33333%;
  }
  .f-w-11 {
    width: 91.66667%;
  }
  .f-for-w-11 > * {
    width: 91.66667%;
  }
  .f-w-12 {
    width: 100%;
  }
  .f-for-w-12 > * {
    width: 100%;
  }
  .f-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .f-1 > * {
    width: 100%;
  }
  .f-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .f-2 > * {
    width: 50%;
  }
  .f-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .f-3 > * {
    width: 33.33333%;
  }
  .f-4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .f-4 > * {
    width: 25%;
  }
  .f-5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .f-5 > * {
    width: 20%;
  }
  .f-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .f-6 > * {
    width: 16.66667%;
  }
  .f-7 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .f-7 > * {
    width: 14.28571%;
  }
  .f-8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .f-8 > * {
    width: 12.5%;
  }
  .f-9 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .f-9 > * {
    width: 11.11111%;
  }
  .f-10 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .f-10 > * {
    width: 10%;
  }
  .f-overflow-hidden {
    overflow: hidden;
  }
  .f-overflow-x-hidden {
    overflow-x: hidden;
  }
  .f-overflow-y-hidden {
    overflow-y: hidden;
  }
  .f-overflow-x-auto {
    overflow-x: auto;
  }
  .f-overflow-y-auto {
    overflow-y: auto;
  }
  .f-overflow-visible {
    overflow: visible;
  }
  .f-no-events {
    pointer-events: none;
  }
  .f-no-drag {
    -webkit-user-drag: none;
  }
  .f-no-select {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .f-no-bold {
    font-weight: normal;
  }
  .f-no-border {
    border: none;
  }
  .f-no-float {
    float: none;
  }
  .f-no-first-border > *:first-child {
    border: 0;
  }
  .f-no-last-border > *:last-child {
    border: 0;
  }
  .f-no-bg {
    background-color: transparent !important;
    background-image: none !important;
  }
  .f-no-wrap {
    white-space: nowrap;
  }
  .f-no-flex-shrink {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  .f-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* 水平，左起 */
    /* 水平，左起 */
    /* 垂直，上起 */
    /* 垂直，下起 */
    /* flex 水平对齐方式 */
    /*分布对齐，如有空隙，包括始端与末端*/
    /* flex 垂直对齐方式 */
    /*第一行文字的基线对齐。*/
    /*（默认值）：如果项目未设置高度或设为auto，将占满整个容器的高度。*/
    /* flex align-content */
    /* 单项 对齐方式 */
  }
  .f-flex-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .f-flex-nowrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .f-flex-wrap-reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }
  .f-flex-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .f-flex-i {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .f-flex-1 {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .f-flex-direction-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .f-flex-direction-row-reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .f-flex-direction-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .f-flex-direction-column-reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .f-flex-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .f-flex-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .f-flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .f-flex-x-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .f-flex-x-around {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .f-flex-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .f-flex-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .f-flex-middle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .f-flex-baseline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
  .f-flex-stretch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .f-flex-align-start {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }
  .f-flex-align-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: end;
        align-content: flex-end;
  }
  .f-flex-align-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: flex-center;
        align-content: flex-center;
  }
  .f-flex-align-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: justify;
        align-content: space-between;
  }
  .f-flex-align-around {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: distribute;
        align-content: space-around;
  }
  .f-flex-align-stretch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
  }
  .f-flex > .item-top {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
  .f-flex > .item-middle {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .f-flex > .item-bottom {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }
  .f-flex > .item-baseline {
    -ms-flex-item-align: baseline;
        align-self: baseline;
  }
  .f-flex > .item-stretch {
    -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
  }
  .f-flex > .item-auto {
    -ms-flex-item-align: auto;
        -ms-grid-row-align: auto;
        align-self: auto;
  }
  .f-text-center {
    text-align: center;
  }
  .f-text-left {
    text-align: left;
  }
  .f-text-right {
    text-align: right;
  }
  .f-text-indent {
    text-indent: 2em;
  }
  .f-text-underline {
    text-decoration: underline;
  }
  .f-full {
    width: 100%;
    height: 100%;
  }
  .f-full-w {
    width: 100%;
  }
  .f-full-h {
    height: 100%;
  }
  .f-pd-s {
    padding: 0.5rem;
  }
  .f-pd-auto {
    padding: auto;
  }
  .f-pd-x-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .f-pd-y-auto {
    padding-top: auto;
    padding-top: auto;
  }
  .f-pd-left-auto {
    padding-left: auto;
  }
  .f-pd-right-auto {
    padding-right: auto;
  }
  .f-pd-top-auto {
    padding-top: auto;
  }
  .f-pd-bottom-auto {
    padding-bottom: auto;
  }
  .f-pd-0 {
    padding: 0rem;
  }
  .f-pd-left-0 {
    padding-left: 0rem;
  }
  .f-pd-right-0 {
    padding-right: 0rem;
  }
  .f-pd-top-0 {
    padding-top: 0rem;
  }
  .f-pd-bottom-0 {
    padding-bottom: 0rem;
  }
  .f-pd-x-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .f-pd-y-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .f-pd-0\.5 {
    padding: 0.5rem;
  }
  .f-pd-left-0\.5 {
    padding-left: 0.5rem;
  }
  .f-pd-right-0\.5 {
    padding-right: 0.5rem;
  }
  .f-pd-top-0\.5 {
    padding-top: 0.5rem;
  }
  .f-pd-bottom-0\.5 {
    padding-bottom: 0.5rem;
  }
  .f-pd-x-0\.5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .f-pd-y-0\.5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .f-pd-1 {
    padding: 1rem;
  }
  .f-pd-left-1 {
    padding-left: 1rem;
  }
  .f-pd-right-1 {
    padding-right: 1rem;
  }
  .f-pd-top-1 {
    padding-top: 1rem;
  }
  .f-pd-bottom-1 {
    padding-bottom: 1rem;
  }
  .f-pd-x-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .f-pd-y-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .f-pd-1\.5 {
    padding: 1.5rem;
  }
  .f-pd-left-1\.5 {
    padding-left: 1.5rem;
  }
  .f-pd-right-1\.5 {
    padding-right: 1.5rem;
  }
  .f-pd-top-1\.5 {
    padding-top: 1.5rem;
  }
  .f-pd-bottom-1\.5 {
    padding-bottom: 1.5rem;
  }
  .f-pd-x-1\.5 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .f-pd-y-1\.5 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .f-pd-2 {
    padding: 2rem;
  }
  .f-pd-left-2 {
    padding-left: 2rem;
  }
  .f-pd-right-2 {
    padding-right: 2rem;
  }
  .f-pd-top-2 {
    padding-top: 2rem;
  }
  .f-pd-bottom-2 {
    padding-bottom: 2rem;
  }
  .f-pd-x-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .f-pd-y-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .f-pd-2\.5 {
    padding: 2.5rem;
  }
  .f-pd-left-2\.5 {
    padding-left: 2.5rem;
  }
  .f-pd-right-2\.5 {
    padding-right: 2.5rem;
  }
  .f-pd-top-2\.5 {
    padding-top: 2.5rem;
  }
  .f-pd-bottom-2\.5 {
    padding-bottom: 2.5rem;
  }
  .f-pd-x-2\.5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .f-pd-y-2\.5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .f-pd-3 {
    padding: 3rem;
  }
  .f-pd-left-3 {
    padding-left: 3rem;
  }
  .f-pd-right-3 {
    padding-right: 3rem;
  }
  .f-pd-top-3 {
    padding-top: 3rem;
  }
  .f-pd-bottom-3 {
    padding-bottom: 3rem;
  }
  .f-pd-x-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .f-pd-y-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .f-pd-3\.5 {
    padding: 3.5rem;
  }
  .f-pd-left-3\.5 {
    padding-left: 3.5rem;
  }
  .f-pd-right-3\.5 {
    padding-right: 3.5rem;
  }
  .f-pd-top-3\.5 {
    padding-top: 3.5rem;
  }
  .f-pd-bottom-3\.5 {
    padding-bottom: 3.5rem;
  }
  .f-pd-x-3\.5 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .f-pd-y-3\.5 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .f-pd-4 {
    padding: 4rem;
  }
  .f-pd-left-4 {
    padding-left: 4rem;
  }
  .f-pd-right-4 {
    padding-right: 4rem;
  }
  .f-pd-top-4 {
    padding-top: 4rem;
  }
  .f-pd-bottom-4 {
    padding-bottom: 4rem;
  }
  .f-pd-x-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .f-pd-y-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .f-pd-4\.5 {
    padding: 4.5rem;
  }
  .f-pd-left-4\.5 {
    padding-left: 4.5rem;
  }
  .f-pd-right-4\.5 {
    padding-right: 4.5rem;
  }
  .f-pd-top-4\.5 {
    padding-top: 4.5rem;
  }
  .f-pd-bottom-4\.5 {
    padding-bottom: 4.5rem;
  }
  .f-pd-x-4\.5 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .f-pd-y-4\.5 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .f-pd-5 {
    padding: 5rem;
  }
  .f-pd-left-5 {
    padding-left: 5rem;
  }
  .f-pd-right-5 {
    padding-right: 5rem;
  }
  .f-pd-top-5 {
    padding-top: 5rem;
  }
  .f-pd-bottom-5 {
    padding-bottom: 5rem;
  }
  .f-pd-x-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .f-pd-y-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .f-pd-5\.5 {
    padding: 5.5rem;
  }
  .f-pd-left-5\.5 {
    padding-left: 5.5rem;
  }
  .f-pd-right-5\.5 {
    padding-right: 5.5rem;
  }
  .f-pd-top-5\.5 {
    padding-top: 5.5rem;
  }
  .f-pd-bottom-5\.5 {
    padding-bottom: 5.5rem;
  }
  .f-pd-x-5\.5 {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
  .f-pd-y-5\.5 {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .f-pd-6 {
    padding: 6rem;
  }
  .f-pd-left-6 {
    padding-left: 6rem;
  }
  .f-pd-right-6 {
    padding-right: 6rem;
  }
  .f-pd-top-6 {
    padding-top: 6rem;
  }
  .f-pd-bottom-6 {
    padding-bottom: 6rem;
  }
  .f-pd-x-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .f-pd-y-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .f-pd-6\.5 {
    padding: 6.5rem;
  }
  .f-pd-left-6\.5 {
    padding-left: 6.5rem;
  }
  .f-pd-right-6\.5 {
    padding-right: 6.5rem;
  }
  .f-pd-top-6\.5 {
    padding-top: 6.5rem;
  }
  .f-pd-bottom-6\.5 {
    padding-bottom: 6.5rem;
  }
  .f-pd-x-6\.5 {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  .f-pd-y-6\.5 {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .f-pd-7 {
    padding: 7rem;
  }
  .f-pd-left-7 {
    padding-left: 7rem;
  }
  .f-pd-right-7 {
    padding-right: 7rem;
  }
  .f-pd-top-7 {
    padding-top: 7rem;
  }
  .f-pd-bottom-7 {
    padding-bottom: 7rem;
  }
  .f-pd-x-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .f-pd-y-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .f-pd-7\.5 {
    padding: 7.5rem;
  }
  .f-pd-left-7\.5 {
    padding-left: 7.5rem;
  }
  .f-pd-right-7\.5 {
    padding-right: 7.5rem;
  }
  .f-pd-top-7\.5 {
    padding-top: 7.5rem;
  }
  .f-pd-bottom-7\.5 {
    padding-bottom: 7.5rem;
  }
  .f-pd-x-7\.5 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .f-pd-y-7\.5 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .f-pd-8 {
    padding: 8rem;
  }
  .f-pd-left-8 {
    padding-left: 8rem;
  }
  .f-pd-right-8 {
    padding-right: 8rem;
  }
  .f-pd-top-8 {
    padding-top: 8rem;
  }
  .f-pd-bottom-8 {
    padding-bottom: 8rem;
  }
  .f-pd-x-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .f-pd-y-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .f-pd-8\.5 {
    padding: 8.5rem;
  }
  .f-pd-left-8\.5 {
    padding-left: 8.5rem;
  }
  .f-pd-right-8\.5 {
    padding-right: 8.5rem;
  }
  .f-pd-top-8\.5 {
    padding-top: 8.5rem;
  }
  .f-pd-bottom-8\.5 {
    padding-bottom: 8.5rem;
  }
  .f-pd-x-8\.5 {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .f-pd-y-8\.5 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .f-pd-9 {
    padding: 9rem;
  }
  .f-pd-left-9 {
    padding-left: 9rem;
  }
  .f-pd-right-9 {
    padding-right: 9rem;
  }
  .f-pd-top-9 {
    padding-top: 9rem;
  }
  .f-pd-bottom-9 {
    padding-bottom: 9rem;
  }
  .f-pd-x-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .f-pd-y-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .f-pd-9\.5 {
    padding: 9.5rem;
  }
  .f-pd-left-9\.5 {
    padding-left: 9.5rem;
  }
  .f-pd-right-9\.5 {
    padding-right: 9.5rem;
  }
  .f-pd-top-9\.5 {
    padding-top: 9.5rem;
  }
  .f-pd-bottom-9\.5 {
    padding-bottom: 9.5rem;
  }
  .f-pd-x-9\.5 {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .f-pd-y-9\.5 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .f-pd-10 {
    padding: 10rem;
  }
  .f-pd-left-10 {
    padding-left: 10rem;
  }
  .f-pd-right-10 {
    padding-right: 10rem;
  }
  .f-pd-top-10 {
    padding-top: 10rem;
  }
  .f-pd-bottom-10 {
    padding-bottom: 10rem;
  }
  .f-pd-x-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .f-pd-y-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .f-mg-s {
    margin: 0.5rem;
  }
  .f-mg-auto {
    margin: auto;
  }
  .f-mg-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .f-mg-y-auto {
    margin-top: auto;
    margin-top: auto;
  }
  .f-mg-left-auto {
    margin-left: auto;
  }
  .f-mg-right-auto {
    margin-right: auto;
  }
  .f-mg-top-auto {
    margin-top: auto;
  }
  .f-mg-bottom-auto {
    margin-bottom: auto;
  }
  .f-mg-0 {
    margin: 0rem;
  }
  .f-mg-left-0 {
    margin-left: 0rem;
  }
  .f-mg-right-0 {
    margin-right: 0rem;
  }
  .f-mg-top-0 {
    margin-top: 0rem;
  }
  .f-mg-bottom-0 {
    margin-bottom: 0rem;
  }
  .f-mg-x-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .f-mg-y-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .f-mg-0\.5 {
    margin: 0.5rem;
  }
  .f-mg-left-0\.5 {
    margin-left: 0.5rem;
  }
  .f-mg-right-0\.5 {
    margin-right: 0.5rem;
  }
  .f-mg-top-0\.5 {
    margin-top: 0.5rem;
  }
  .f-mg-bottom-0\.5 {
    margin-bottom: 0.5rem;
  }
  .f-mg-x-0\.5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .f-mg-y-0\.5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .f-mg-1 {
    margin: 1rem;
  }
  .f-mg-left-1 {
    margin-left: 1rem;
  }
  .f-mg-right-1 {
    margin-right: 1rem;
  }
  .f-mg-top-1 {
    margin-top: 1rem;
  }
  .f-mg-bottom-1 {
    margin-bottom: 1rem;
  }
  .f-mg-x-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .f-mg-y-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .f-mg-1\.5 {
    margin: 1.5rem;
  }
  .f-mg-left-1\.5 {
    margin-left: 1.5rem;
  }
  .f-mg-right-1\.5 {
    margin-right: 1.5rem;
  }
  .f-mg-top-1\.5 {
    margin-top: 1.5rem;
  }
  .f-mg-bottom-1\.5 {
    margin-bottom: 1.5rem;
  }
  .f-mg-x-1\.5 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .f-mg-y-1\.5 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .f-mg-2 {
    margin: 2rem;
  }
  .f-mg-left-2 {
    margin-left: 2rem;
  }
  .f-mg-right-2 {
    margin-right: 2rem;
  }
  .f-mg-top-2 {
    margin-top: 2rem;
  }
  .f-mg-bottom-2 {
    margin-bottom: 2rem;
  }
  .f-mg-x-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .f-mg-y-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .f-mg-2\.5 {
    margin: 2.5rem;
  }
  .f-mg-left-2\.5 {
    margin-left: 2.5rem;
  }
  .f-mg-right-2\.5 {
    margin-right: 2.5rem;
  }
  .f-mg-top-2\.5 {
    margin-top: 2.5rem;
  }
  .f-mg-bottom-2\.5 {
    margin-bottom: 2.5rem;
  }
  .f-mg-x-2\.5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .f-mg-y-2\.5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .f-mg-3 {
    margin: 3rem;
  }
  .f-mg-left-3 {
    margin-left: 3rem;
  }
  .f-mg-right-3 {
    margin-right: 3rem;
  }
  .f-mg-top-3 {
    margin-top: 3rem;
  }
  .f-mg-bottom-3 {
    margin-bottom: 3rem;
  }
  .f-mg-x-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .f-mg-y-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .f-mg-3\.5 {
    margin: 3.5rem;
  }
  .f-mg-left-3\.5 {
    margin-left: 3.5rem;
  }
  .f-mg-right-3\.5 {
    margin-right: 3.5rem;
  }
  .f-mg-top-3\.5 {
    margin-top: 3.5rem;
  }
  .f-mg-bottom-3\.5 {
    margin-bottom: 3.5rem;
  }
  .f-mg-x-3\.5 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .f-mg-y-3\.5 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .f-mg-4 {
    margin: 4rem;
  }
  .f-mg-left-4 {
    margin-left: 4rem;
  }
  .f-mg-right-4 {
    margin-right: 4rem;
  }
  .f-mg-top-4 {
    margin-top: 4rem;
  }
  .f-mg-bottom-4 {
    margin-bottom: 4rem;
  }
  .f-mg-x-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .f-mg-y-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .f-mg-4\.5 {
    margin: 4.5rem;
  }
  .f-mg-left-4\.5 {
    margin-left: 4.5rem;
  }
  .f-mg-right-4\.5 {
    margin-right: 4.5rem;
  }
  .f-mg-top-4\.5 {
    margin-top: 4.5rem;
  }
  .f-mg-bottom-4\.5 {
    margin-bottom: 4.5rem;
  }
  .f-mg-x-4\.5 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .f-mg-y-4\.5 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .f-mg-5 {
    margin: 5rem;
  }
  .f-mg-left-5 {
    margin-left: 5rem;
  }
  .f-mg-right-5 {
    margin-right: 5rem;
  }
  .f-mg-top-5 {
    margin-top: 5rem;
  }
  .f-mg-bottom-5 {
    margin-bottom: 5rem;
  }
  .f-mg-x-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .f-mg-y-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .f-mg-5\.5 {
    margin: 5.5rem;
  }
  .f-mg-left-5\.5 {
    margin-left: 5.5rem;
  }
  .f-mg-right-5\.5 {
    margin-right: 5.5rem;
  }
  .f-mg-top-5\.5 {
    margin-top: 5.5rem;
  }
  .f-mg-bottom-5\.5 {
    margin-bottom: 5.5rem;
  }
  .f-mg-x-5\.5 {
    margin-left: 5.5rem;
    margin-right: 5.5rem;
  }
  .f-mg-y-5\.5 {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
  }
  .f-mg-6 {
    margin: 6rem;
  }
  .f-mg-left-6 {
    margin-left: 6rem;
  }
  .f-mg-right-6 {
    margin-right: 6rem;
  }
  .f-mg-top-6 {
    margin-top: 6rem;
  }
  .f-mg-bottom-6 {
    margin-bottom: 6rem;
  }
  .f-mg-x-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .f-mg-y-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .f-mg-6\.5 {
    margin: 6.5rem;
  }
  .f-mg-left-6\.5 {
    margin-left: 6.5rem;
  }
  .f-mg-right-6\.5 {
    margin-right: 6.5rem;
  }
  .f-mg-top-6\.5 {
    margin-top: 6.5rem;
  }
  .f-mg-bottom-6\.5 {
    margin-bottom: 6.5rem;
  }
  .f-mg-x-6\.5 {
    margin-left: 6.5rem;
    margin-right: 6.5rem;
  }
  .f-mg-y-6\.5 {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem;
  }
  .f-mg-7 {
    margin: 7rem;
  }
  .f-mg-left-7 {
    margin-left: 7rem;
  }
  .f-mg-right-7 {
    margin-right: 7rem;
  }
  .f-mg-top-7 {
    margin-top: 7rem;
  }
  .f-mg-bottom-7 {
    margin-bottom: 7rem;
  }
  .f-mg-x-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .f-mg-y-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .f-mg-7\.5 {
    margin: 7.5rem;
  }
  .f-mg-left-7\.5 {
    margin-left: 7.5rem;
  }
  .f-mg-right-7\.5 {
    margin-right: 7.5rem;
  }
  .f-mg-top-7\.5 {
    margin-top: 7.5rem;
  }
  .f-mg-bottom-7\.5 {
    margin-bottom: 7.5rem;
  }
  .f-mg-x-7\.5 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .f-mg-y-7\.5 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .f-mg-8 {
    margin: 8rem;
  }
  .f-mg-left-8 {
    margin-left: 8rem;
  }
  .f-mg-right-8 {
    margin-right: 8rem;
  }
  .f-mg-top-8 {
    margin-top: 8rem;
  }
  .f-mg-bottom-8 {
    margin-bottom: 8rem;
  }
  .f-mg-x-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .f-mg-y-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .f-mg-8\.5 {
    margin: 8.5rem;
  }
  .f-mg-left-8\.5 {
    margin-left: 8.5rem;
  }
  .f-mg-right-8\.5 {
    margin-right: 8.5rem;
  }
  .f-mg-top-8\.5 {
    margin-top: 8.5rem;
  }
  .f-mg-bottom-8\.5 {
    margin-bottom: 8.5rem;
  }
  .f-mg-x-8\.5 {
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .f-mg-y-8\.5 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }
  .f-mg-9 {
    margin: 9rem;
  }
  .f-mg-left-9 {
    margin-left: 9rem;
  }
  .f-mg-right-9 {
    margin-right: 9rem;
  }
  .f-mg-top-9 {
    margin-top: 9rem;
  }
  .f-mg-bottom-9 {
    margin-bottom: 9rem;
  }
  .f-mg-x-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .f-mg-y-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .f-mg-9\.5 {
    margin: 9.5rem;
  }
  .f-mg-left-9\.5 {
    margin-left: 9.5rem;
  }
  .f-mg-right-9\.5 {
    margin-right: 9.5rem;
  }
  .f-mg-top-9\.5 {
    margin-top: 9.5rem;
  }
  .f-mg-bottom-9\.5 {
    margin-bottom: 9.5rem;
  }
  .f-mg-x-9\.5 {
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .f-mg-y-9\.5 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem;
  }
  .f-mg-10 {
    margin: 10rem;
  }
  .f-mg-left-10 {
    margin-left: 10rem;
  }
  .f-mg-right-10 {
    margin-right: 10rem;
  }
  .f-mg-top-10 {
    margin-top: 10rem;
  }
  .f-mg-bottom-10 {
    margin-bottom: 10rem;
  }
  .f-mg-x-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .f-mg-y-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .f-for-no-flex-shrink > * {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  .f-for-full > * {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-align-items: stretch;
  }
  .f-for-full > * > .box {
    width: 100%;
  }
  .f-for-radius > * {
    border-radius: 0.25rem;
  }
  .f-for-cursor-pointer > * {
    cursor: pointer;
  }
  .f-for-spacing-s {
    margin-left: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .f-for-spacing-s > * {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .f-for-spacing-0 {
    margin-left: -0rem;
    margin-bottom: -0rem;
  }
  .f-for-spacing-0 > * {
    padding-left: 0rem;
    padding-bottom: 0rem;
  }
  .f-for-spacing-0\.5 {
    margin-left: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .f-for-spacing-0\.5 > * {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .f-for-spacing-1 {
    margin-left: -1rem;
    margin-bottom: -1rem;
  }
  .f-for-spacing-1 > * {
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
  .f-for-spacing-1\.5 {
    margin-left: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .f-for-spacing-1\.5 > * {
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .f-for-spacing-2 {
    margin-left: -2rem;
    margin-bottom: -2rem;
  }
  .f-for-spacing-2 > * {
    padding-left: 2rem;
    padding-bottom: 2rem;
  }
  .f-for-spacing-2\.5 {
    margin-left: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .f-for-spacing-2\.5 > * {
    padding-left: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .f-for-spacing-3 {
    margin-left: -3rem;
    margin-bottom: -3rem;
  }
  .f-for-spacing-3 > * {
    padding-left: 3rem;
    padding-bottom: 3rem;
  }
  .f-for-spacing-3\.5 {
    margin-left: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .f-for-spacing-3\.5 > * {
    padding-left: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .f-for-spacing-4 {
    margin-left: -4rem;
    margin-bottom: -4rem;
  }
  .f-for-spacing-4 > * {
    padding-left: 4rem;
    padding-bottom: 4rem;
  }
  .f-for-spacing-4\.5 {
    margin-left: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .f-for-spacing-4\.5 > * {
    padding-left: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .f-for-spacing-5 {
    margin-left: -5rem;
    margin-bottom: -5rem;
  }
  .f-for-spacing-5 > * {
    padding-left: 5rem;
    padding-bottom: 5rem;
  }
  .f-for-spacing-5\.5 {
    margin-left: -5.5rem;
    margin-bottom: -5.5rem;
  }
  .f-for-spacing-5\.5 > * {
    padding-left: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .f-for-spacing-6 {
    margin-left: -6rem;
    margin-bottom: -6rem;
  }
  .f-for-spacing-6 > * {
    padding-left: 6rem;
    padding-bottom: 6rem;
  }
  .f-for-spacing-6\.5 {
    margin-left: -6.5rem;
    margin-bottom: -6.5rem;
  }
  .f-for-spacing-6\.5 > * {
    padding-left: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .f-for-spacing-7 {
    margin-left: -7rem;
    margin-bottom: -7rem;
  }
  .f-for-spacing-7 > * {
    padding-left: 7rem;
    padding-bottom: 7rem;
  }
  .f-for-spacing-7\.5 {
    margin-left: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .f-for-spacing-7\.5 > * {
    padding-left: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .f-for-spacing-8 {
    margin-left: -8rem;
    margin-bottom: -8rem;
  }
  .f-for-spacing-8 > * {
    padding-left: 8rem;
    padding-bottom: 8rem;
  }
  .f-for-spacing-8\.5 {
    margin-left: -8.5rem;
    margin-bottom: -8.5rem;
  }
  .f-for-spacing-8\.5 > * {
    padding-left: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .f-for-spacing-9 {
    margin-left: -9rem;
    margin-bottom: -9rem;
  }
  .f-for-spacing-9 > * {
    padding-left: 9rem;
    padding-bottom: 9rem;
  }
  .f-for-spacing-9\.5 {
    margin-left: -9.5rem;
    margin-bottom: -9.5rem;
  }
  .f-for-spacing-9\.5 > * {
    padding-left: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .f-for-spacing-10 {
    margin-left: -10rem;
    margin-bottom: -10rem;
  }
  .f-for-spacing-10 > * {
    padding-left: 10rem;
    padding-bottom: 10rem;
  }
  .f-for-box-radius-0 > * > .box {
    border-radius: 0rem;
  }
  .f-for-box-radius-0\.1 > * > .box {
    border-radius: 0.1rem;
  }
  .f-for-box-radius-0\.2 > * > .box {
    border-radius: 0.2rem;
  }
  .f-for-box-radius-0\.3 > * > .box {
    border-radius: 0.3rem;
  }
  .f-for-box-radius-0\.4 > * > .box {
    border-radius: 0.4rem;
  }
  .f-for-box-radius-0\.5 > * > .box {
    border-radius: 0.5rem;
  }
  .f-for-box-radius-0\.6 > * > .box {
    border-radius: 0.6rem;
  }
  .f-for-box-radius-0\.7 > * > .box {
    border-radius: 0.7rem;
  }
  .f-for-box-radius-0\.8 > * > .box {
    border-radius: 0.8rem;
  }
  .f-for-box-radius-0\.9 > * > .box {
    border-radius: 0.9rem;
  }
  .f-for-box-radius-1 > * > .box {
    border-radius: 1rem;
  }
  .f-for-box-radius-1\.1 > * > .box {
    border-radius: 1.1rem;
  }
  .f-for-box-radius-1\.2 > * > .box {
    border-radius: 1.2rem;
  }
  .f-for-box-radius-1\.3 > * > .box {
    border-radius: 1.3rem;
  }
  .f-for-box-radius-1\.4 > * > .box {
    border-radius: 1.4rem;
  }
  .f-for-box-radius-1\.5 > * > .box {
    border-radius: 1.5rem;
  }
  .f-for-box-radius-1\.6 > * > .box {
    border-radius: 1.6rem;
  }
  .f-for-box-radius-1\.7 > * > .box {
    border-radius: 1.7rem;
  }
  .f-for-box-radius-1\.8 > * > .box {
    border-radius: 1.8rem;
  }
  .f-for-box-radius-1\.9 > * > .box {
    border-radius: 1.9rem;
  }
  .f-for-box-radius-2 > * > .box {
    border-radius: 2rem;
  }
  .f-for-box-radius-2\.1 > * > .box {
    border-radius: 2.1rem;
  }
  .f-for-box-radius-2\.2 > * > .box {
    border-radius: 2.2rem;
  }
  .f-for-box-radius-2\.3 > * > .box {
    border-radius: 2.3rem;
  }
  .f-for-box-radius-2\.4 > * > .box {
    border-radius: 2.4rem;
  }
  .f-for-box-radius-2\.5 > * > .box {
    border-radius: 2.5rem;
  }
  .f-for-box-radius-2\.6 > * > .box {
    border-radius: 2.6rem;
  }
  .f-for-box-radius-2\.7 > * > .box {
    border-radius: 2.7rem;
  }
  .f-for-box-radius-2\.8 > * > .box {
    border-radius: 2.8rem;
  }
  .f-for-box-radius-2\.9 > * > .box {
    border-radius: 2.9rem;
  }
  .f-for-box-radius-3 > * > .box {
    border-radius: 3rem;
  }
  .f-for-pd-0 > * {
    padding: 0rem;
  }
  .f-for-pd-left-0 > * {
    padding-left: 0rem;
  }
  .f-for-pd-right-0 > * {
    padding-right: 0rem;
  }
  .f-for-pd-top-0 > * {
    padding-top: 0rem;
  }
  .f-for-pd-bottom-0 > * {
    padding-bottom: 0rem;
  }
  .f-for-pd-x-0 > * {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .f-for-pd-y-0 > * {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .f-for-pd-0\.5 > * {
    padding: 0.5rem;
  }
  .f-for-pd-left-0\.5 > * {
    padding-left: 0.5rem;
  }
  .f-for-pd-right-0\.5 > * {
    padding-right: 0.5rem;
  }
  .f-for-pd-top-0\.5 > * {
    padding-top: 0.5rem;
  }
  .f-for-pd-bottom-0\.5 > * {
    padding-bottom: 0.5rem;
  }
  .f-for-pd-x-0\.5 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .f-for-pd-y-0\.5 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .f-for-pd-1 > * {
    padding: 1rem;
  }
  .f-for-pd-left-1 > * {
    padding-left: 1rem;
  }
  .f-for-pd-right-1 > * {
    padding-right: 1rem;
  }
  .f-for-pd-top-1 > * {
    padding-top: 1rem;
  }
  .f-for-pd-bottom-1 > * {
    padding-bottom: 1rem;
  }
  .f-for-pd-x-1 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .f-for-pd-y-1 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .f-for-pd-1\.5 > * {
    padding: 1.5rem;
  }
  .f-for-pd-left-1\.5 > * {
    padding-left: 1.5rem;
  }
  .f-for-pd-right-1\.5 > * {
    padding-right: 1.5rem;
  }
  .f-for-pd-top-1\.5 > * {
    padding-top: 1.5rem;
  }
  .f-for-pd-bottom-1\.5 > * {
    padding-bottom: 1.5rem;
  }
  .f-for-pd-x-1\.5 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .f-for-pd-y-1\.5 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .f-for-pd-2 > * {
    padding: 2rem;
  }
  .f-for-pd-left-2 > * {
    padding-left: 2rem;
  }
  .f-for-pd-right-2 > * {
    padding-right: 2rem;
  }
  .f-for-pd-top-2 > * {
    padding-top: 2rem;
  }
  .f-for-pd-bottom-2 > * {
    padding-bottom: 2rem;
  }
  .f-for-pd-x-2 > * {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .f-for-pd-y-2 > * {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .f-for-pd-2\.5 > * {
    padding: 2.5rem;
  }
  .f-for-pd-left-2\.5 > * {
    padding-left: 2.5rem;
  }
  .f-for-pd-right-2\.5 > * {
    padding-right: 2.5rem;
  }
  .f-for-pd-top-2\.5 > * {
    padding-top: 2.5rem;
  }
  .f-for-pd-bottom-2\.5 > * {
    padding-bottom: 2.5rem;
  }
  .f-for-pd-x-2\.5 > * {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .f-for-pd-y-2\.5 > * {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .f-for-pd-3 > * {
    padding: 3rem;
  }
  .f-for-pd-left-3 > * {
    padding-left: 3rem;
  }
  .f-for-pd-right-3 > * {
    padding-right: 3rem;
  }
  .f-for-pd-top-3 > * {
    padding-top: 3rem;
  }
  .f-for-pd-bottom-3 > * {
    padding-bottom: 3rem;
  }
  .f-for-pd-x-3 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .f-for-pd-y-3 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .f-for-pd-3\.5 > * {
    padding: 3.5rem;
  }
  .f-for-pd-left-3\.5 > * {
    padding-left: 3.5rem;
  }
  .f-for-pd-right-3\.5 > * {
    padding-right: 3.5rem;
  }
  .f-for-pd-top-3\.5 > * {
    padding-top: 3.5rem;
  }
  .f-for-pd-bottom-3\.5 > * {
    padding-bottom: 3.5rem;
  }
  .f-for-pd-x-3\.5 > * {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .f-for-pd-y-3\.5 > * {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .f-for-pd-4 > * {
    padding: 4rem;
  }
  .f-for-pd-left-4 > * {
    padding-left: 4rem;
  }
  .f-for-pd-right-4 > * {
    padding-right: 4rem;
  }
  .f-for-pd-top-4 > * {
    padding-top: 4rem;
  }
  .f-for-pd-bottom-4 > * {
    padding-bottom: 4rem;
  }
  .f-for-pd-x-4 > * {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .f-for-pd-y-4 > * {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .f-for-pd-4\.5 > * {
    padding: 4.5rem;
  }
  .f-for-pd-left-4\.5 > * {
    padding-left: 4.5rem;
  }
  .f-for-pd-right-4\.5 > * {
    padding-right: 4.5rem;
  }
  .f-for-pd-top-4\.5 > * {
    padding-top: 4.5rem;
  }
  .f-for-pd-bottom-4\.5 > * {
    padding-bottom: 4.5rem;
  }
  .f-for-pd-x-4\.5 > * {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .f-for-pd-y-4\.5 > * {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .f-for-pd-5 > * {
    padding: 5rem;
  }
  .f-for-pd-left-5 > * {
    padding-left: 5rem;
  }
  .f-for-pd-right-5 > * {
    padding-right: 5rem;
  }
  .f-for-pd-top-5 > * {
    padding-top: 5rem;
  }
  .f-for-pd-bottom-5 > * {
    padding-bottom: 5rem;
  }
  .f-for-pd-x-5 > * {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .f-for-pd-y-5 > * {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .f-for-pd-5\.5 > * {
    padding: 5.5rem;
  }
  .f-for-pd-left-5\.5 > * {
    padding-left: 5.5rem;
  }
  .f-for-pd-right-5\.5 > * {
    padding-right: 5.5rem;
  }
  .f-for-pd-top-5\.5 > * {
    padding-top: 5.5rem;
  }
  .f-for-pd-bottom-5\.5 > * {
    padding-bottom: 5.5rem;
  }
  .f-for-pd-x-5\.5 > * {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
  .f-for-pd-y-5\.5 > * {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .f-for-pd-6 > * {
    padding: 6rem;
  }
  .f-for-pd-left-6 > * {
    padding-left: 6rem;
  }
  .f-for-pd-right-6 > * {
    padding-right: 6rem;
  }
  .f-for-pd-top-6 > * {
    padding-top: 6rem;
  }
  .f-for-pd-bottom-6 > * {
    padding-bottom: 6rem;
  }
  .f-for-pd-x-6 > * {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .f-for-pd-y-6 > * {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .f-for-pd-6\.5 > * {
    padding: 6.5rem;
  }
  .f-for-pd-left-6\.5 > * {
    padding-left: 6.5rem;
  }
  .f-for-pd-right-6\.5 > * {
    padding-right: 6.5rem;
  }
  .f-for-pd-top-6\.5 > * {
    padding-top: 6.5rem;
  }
  .f-for-pd-bottom-6\.5 > * {
    padding-bottom: 6.5rem;
  }
  .f-for-pd-x-6\.5 > * {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  .f-for-pd-y-6\.5 > * {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .f-for-pd-7 > * {
    padding: 7rem;
  }
  .f-for-pd-left-7 > * {
    padding-left: 7rem;
  }
  .f-for-pd-right-7 > * {
    padding-right: 7rem;
  }
  .f-for-pd-top-7 > * {
    padding-top: 7rem;
  }
  .f-for-pd-bottom-7 > * {
    padding-bottom: 7rem;
  }
  .f-for-pd-x-7 > * {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .f-for-pd-y-7 > * {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .f-for-pd-7\.5 > * {
    padding: 7.5rem;
  }
  .f-for-pd-left-7\.5 > * {
    padding-left: 7.5rem;
  }
  .f-for-pd-right-7\.5 > * {
    padding-right: 7.5rem;
  }
  .f-for-pd-top-7\.5 > * {
    padding-top: 7.5rem;
  }
  .f-for-pd-bottom-7\.5 > * {
    padding-bottom: 7.5rem;
  }
  .f-for-pd-x-7\.5 > * {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .f-for-pd-y-7\.5 > * {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .f-for-pd-8 > * {
    padding: 8rem;
  }
  .f-for-pd-left-8 > * {
    padding-left: 8rem;
  }
  .f-for-pd-right-8 > * {
    padding-right: 8rem;
  }
  .f-for-pd-top-8 > * {
    padding-top: 8rem;
  }
  .f-for-pd-bottom-8 > * {
    padding-bottom: 8rem;
  }
  .f-for-pd-x-8 > * {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .f-for-pd-y-8 > * {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .f-for-pd-8\.5 > * {
    padding: 8.5rem;
  }
  .f-for-pd-left-8\.5 > * {
    padding-left: 8.5rem;
  }
  .f-for-pd-right-8\.5 > * {
    padding-right: 8.5rem;
  }
  .f-for-pd-top-8\.5 > * {
    padding-top: 8.5rem;
  }
  .f-for-pd-bottom-8\.5 > * {
    padding-bottom: 8.5rem;
  }
  .f-for-pd-x-8\.5 > * {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .f-for-pd-y-8\.5 > * {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .f-for-pd-9 > * {
    padding: 9rem;
  }
  .f-for-pd-left-9 > * {
    padding-left: 9rem;
  }
  .f-for-pd-right-9 > * {
    padding-right: 9rem;
  }
  .f-for-pd-top-9 > * {
    padding-top: 9rem;
  }
  .f-for-pd-bottom-9 > * {
    padding-bottom: 9rem;
  }
  .f-for-pd-x-9 > * {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .f-for-pd-y-9 > * {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .f-for-pd-9\.5 > * {
    padding: 9.5rem;
  }
  .f-for-pd-left-9\.5 > * {
    padding-left: 9.5rem;
  }
  .f-for-pd-right-9\.5 > * {
    padding-right: 9.5rem;
  }
  .f-for-pd-top-9\.5 > * {
    padding-top: 9.5rem;
  }
  .f-for-pd-bottom-9\.5 > * {
    padding-bottom: 9.5rem;
  }
  .f-for-pd-x-9\.5 > * {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .f-for-pd-y-9\.5 > * {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .f-for-pd-10 > * {
    padding: 10rem;
  }
  .f-for-pd-left-10 > * {
    padding-left: 10rem;
  }
  .f-for-pd-right-10 > * {
    padding-right: 10rem;
  }
  .f-for-pd-top-10 > * {
    padding-top: 10rem;
  }
  .f-for-pd-bottom-10 > * {
    padding-bottom: 10rem;
  }
  .f-for-pd-x-10 > * {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .f-for-pd-y-10 > * {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .f-for-mg-0 > * {
    margin: 0rem;
  }
  .f-for-mg-left-0 > * {
    margin-left: 0rem;
  }
  .f-for-mg-right-0 > * {
    margin-right: 0rem;
  }
  .f-for-mg-top-0 > * {
    margin-top: 0rem;
  }
  .f-for-mg-bottom-0 > * {
    margin-bottom: 0rem;
  }
  .f-for-mg-x-0 > * {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .f-for-mg-y-0 > * {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .f-for-mg-0\.5 > * {
    margin: 0.5rem;
  }
  .f-for-mg-left-0\.5 > * {
    margin-left: 0.5rem;
  }
  .f-for-mg-right-0\.5 > * {
    margin-right: 0.5rem;
  }
  .f-for-mg-top-0\.5 > * {
    margin-top: 0.5rem;
  }
  .f-for-mg-bottom-0\.5 > * {
    margin-bottom: 0.5rem;
  }
  .f-for-mg-x-0\.5 > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .f-for-mg-y-0\.5 > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .f-for-mg-1 > * {
    margin: 1rem;
  }
  .f-for-mg-left-1 > * {
    margin-left: 1rem;
  }
  .f-for-mg-right-1 > * {
    margin-right: 1rem;
  }
  .f-for-mg-top-1 > * {
    margin-top: 1rem;
  }
  .f-for-mg-bottom-1 > * {
    margin-bottom: 1rem;
  }
  .f-for-mg-x-1 > * {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .f-for-mg-y-1 > * {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .f-for-mg-1\.5 > * {
    margin: 1.5rem;
  }
  .f-for-mg-left-1\.5 > * {
    margin-left: 1.5rem;
  }
  .f-for-mg-right-1\.5 > * {
    margin-right: 1.5rem;
  }
  .f-for-mg-top-1\.5 > * {
    margin-top: 1.5rem;
  }
  .f-for-mg-bottom-1\.5 > * {
    margin-bottom: 1.5rem;
  }
  .f-for-mg-x-1\.5 > * {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .f-for-mg-y-1\.5 > * {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .f-for-mg-2 > * {
    margin: 2rem;
  }
  .f-for-mg-left-2 > * {
    margin-left: 2rem;
  }
  .f-for-mg-right-2 > * {
    margin-right: 2rem;
  }
  .f-for-mg-top-2 > * {
    margin-top: 2rem;
  }
  .f-for-mg-bottom-2 > * {
    margin-bottom: 2rem;
  }
  .f-for-mg-x-2 > * {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .f-for-mg-y-2 > * {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .f-for-mg-2\.5 > * {
    margin: 2.5rem;
  }
  .f-for-mg-left-2\.5 > * {
    margin-left: 2.5rem;
  }
  .f-for-mg-right-2\.5 > * {
    margin-right: 2.5rem;
  }
  .f-for-mg-top-2\.5 > * {
    margin-top: 2.5rem;
  }
  .f-for-mg-bottom-2\.5 > * {
    margin-bottom: 2.5rem;
  }
  .f-for-mg-x-2\.5 > * {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .f-for-mg-y-2\.5 > * {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .f-for-mg-3 > * {
    margin: 3rem;
  }
  .f-for-mg-left-3 > * {
    margin-left: 3rem;
  }
  .f-for-mg-right-3 > * {
    margin-right: 3rem;
  }
  .f-for-mg-top-3 > * {
    margin-top: 3rem;
  }
  .f-for-mg-bottom-3 > * {
    margin-bottom: 3rem;
  }
  .f-for-mg-x-3 > * {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .f-for-mg-y-3 > * {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .f-for-mg-3\.5 > * {
    margin: 3.5rem;
  }
  .f-for-mg-left-3\.5 > * {
    margin-left: 3.5rem;
  }
  .f-for-mg-right-3\.5 > * {
    margin-right: 3.5rem;
  }
  .f-for-mg-top-3\.5 > * {
    margin-top: 3.5rem;
  }
  .f-for-mg-bottom-3\.5 > * {
    margin-bottom: 3.5rem;
  }
  .f-for-mg-x-3\.5 > * {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .f-for-mg-y-3\.5 > * {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .f-for-mg-4 > * {
    margin: 4rem;
  }
  .f-for-mg-left-4 > * {
    margin-left: 4rem;
  }
  .f-for-mg-right-4 > * {
    margin-right: 4rem;
  }
  .f-for-mg-top-4 > * {
    margin-top: 4rem;
  }
  .f-for-mg-bottom-4 > * {
    margin-bottom: 4rem;
  }
  .f-for-mg-x-4 > * {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .f-for-mg-y-4 > * {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .f-for-mg-4\.5 > * {
    margin: 4.5rem;
  }
  .f-for-mg-left-4\.5 > * {
    margin-left: 4.5rem;
  }
  .f-for-mg-right-4\.5 > * {
    margin-right: 4.5rem;
  }
  .f-for-mg-top-4\.5 > * {
    margin-top: 4.5rem;
  }
  .f-for-mg-bottom-4\.5 > * {
    margin-bottom: 4.5rem;
  }
  .f-for-mg-x-4\.5 > * {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .f-for-mg-y-4\.5 > * {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .f-for-mg-5 > * {
    margin: 5rem;
  }
  .f-for-mg-left-5 > * {
    margin-left: 5rem;
  }
  .f-for-mg-right-5 > * {
    margin-right: 5rem;
  }
  .f-for-mg-top-5 > * {
    margin-top: 5rem;
  }
  .f-for-mg-bottom-5 > * {
    margin-bottom: 5rem;
  }
  .f-for-mg-x-5 > * {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .f-for-mg-y-5 > * {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .f-for-mg-5\.5 > * {
    margin: 5.5rem;
  }
  .f-for-mg-left-5\.5 > * {
    margin-left: 5.5rem;
  }
  .f-for-mg-right-5\.5 > * {
    margin-right: 5.5rem;
  }
  .f-for-mg-top-5\.5 > * {
    margin-top: 5.5rem;
  }
  .f-for-mg-bottom-5\.5 > * {
    margin-bottom: 5.5rem;
  }
  .f-for-mg-x-5\.5 > * {
    margin-left: 5.5rem;
    margin-right: 5.5rem;
  }
  .f-for-mg-y-5\.5 > * {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
  }
  .f-for-mg-6 > * {
    margin: 6rem;
  }
  .f-for-mg-left-6 > * {
    margin-left: 6rem;
  }
  .f-for-mg-right-6 > * {
    margin-right: 6rem;
  }
  .f-for-mg-top-6 > * {
    margin-top: 6rem;
  }
  .f-for-mg-bottom-6 > * {
    margin-bottom: 6rem;
  }
  .f-for-mg-x-6 > * {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .f-for-mg-y-6 > * {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .f-for-mg-6\.5 > * {
    margin: 6.5rem;
  }
  .f-for-mg-left-6\.5 > * {
    margin-left: 6.5rem;
  }
  .f-for-mg-right-6\.5 > * {
    margin-right: 6.5rem;
  }
  .f-for-mg-top-6\.5 > * {
    margin-top: 6.5rem;
  }
  .f-for-mg-bottom-6\.5 > * {
    margin-bottom: 6.5rem;
  }
  .f-for-mg-x-6\.5 > * {
    margin-left: 6.5rem;
    margin-right: 6.5rem;
  }
  .f-for-mg-y-6\.5 > * {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem;
  }
  .f-for-mg-7 > * {
    margin: 7rem;
  }
  .f-for-mg-left-7 > * {
    margin-left: 7rem;
  }
  .f-for-mg-right-7 > * {
    margin-right: 7rem;
  }
  .f-for-mg-top-7 > * {
    margin-top: 7rem;
  }
  .f-for-mg-bottom-7 > * {
    margin-bottom: 7rem;
  }
  .f-for-mg-x-7 > * {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .f-for-mg-y-7 > * {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .f-for-mg-7\.5 > * {
    margin: 7.5rem;
  }
  .f-for-mg-left-7\.5 > * {
    margin-left: 7.5rem;
  }
  .f-for-mg-right-7\.5 > * {
    margin-right: 7.5rem;
  }
  .f-for-mg-top-7\.5 > * {
    margin-top: 7.5rem;
  }
  .f-for-mg-bottom-7\.5 > * {
    margin-bottom: 7.5rem;
  }
  .f-for-mg-x-7\.5 > * {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .f-for-mg-y-7\.5 > * {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .f-for-mg-8 > * {
    margin: 8rem;
  }
  .f-for-mg-left-8 > * {
    margin-left: 8rem;
  }
  .f-for-mg-right-8 > * {
    margin-right: 8rem;
  }
  .f-for-mg-top-8 > * {
    margin-top: 8rem;
  }
  .f-for-mg-bottom-8 > * {
    margin-bottom: 8rem;
  }
  .f-for-mg-x-8 > * {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .f-for-mg-y-8 > * {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .f-for-mg-8\.5 > * {
    margin: 8.5rem;
  }
  .f-for-mg-left-8\.5 > * {
    margin-left: 8.5rem;
  }
  .f-for-mg-right-8\.5 > * {
    margin-right: 8.5rem;
  }
  .f-for-mg-top-8\.5 > * {
    margin-top: 8.5rem;
  }
  .f-for-mg-bottom-8\.5 > * {
    margin-bottom: 8.5rem;
  }
  .f-for-mg-x-8\.5 > * {
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .f-for-mg-y-8\.5 > * {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }
  .f-for-mg-9 > * {
    margin: 9rem;
  }
  .f-for-mg-left-9 > * {
    margin-left: 9rem;
  }
  .f-for-mg-right-9 > * {
    margin-right: 9rem;
  }
  .f-for-mg-top-9 > * {
    margin-top: 9rem;
  }
  .f-for-mg-bottom-9 > * {
    margin-bottom: 9rem;
  }
  .f-for-mg-x-9 > * {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .f-for-mg-y-9 > * {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .f-for-mg-9\.5 > * {
    margin: 9.5rem;
  }
  .f-for-mg-left-9\.5 > * {
    margin-left: 9.5rem;
  }
  .f-for-mg-right-9\.5 > * {
    margin-right: 9.5rem;
  }
  .f-for-mg-top-9\.5 > * {
    margin-top: 9.5rem;
  }
  .f-for-mg-bottom-9\.5 > * {
    margin-bottom: 9.5rem;
  }
  .f-for-mg-x-9\.5 > * {
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .f-for-mg-y-9\.5 > * {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem;
  }
  .f-for-mg-10 > * {
    margin: 10rem;
  }
  .f-for-mg-left-10 > * {
    margin-left: 10rem;
  }
  .f-for-mg-right-10 > * {
    margin-right: 10rem;
  }
  .f-for-mg-top-10 > * {
    margin-top: 10rem;
  }
  .f-for-mg-bottom-10 > * {
    margin-bottom: 10rem;
  }
  .f-for-mg-x-10 > * {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .f-for-mg-y-10 > * {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .f-for-box-light > * > .box {
    background-color: #f5f5f8;
  }
  .f-for-box-border > * > .box {
    border: 1px solid #E1E1E1;
  }
  .f-for-box-shadow {
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-box-shadow-bottom {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-box-shadow-top {
    -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-box-shadow-left {
    -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-box-shadow-right {
    -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-box-shadow-left-top {
    -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-box-shadow-right-top {
    -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-box-shadow-left-bottom {
    -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-box-shadow-right-bottom {
    -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-box-radius > * > .box {
    border-radius: 1rem;
  }
  .f-for-box-pd-0 > * > .box {
    padding: 0rem;
  }
  .f-for-box-pd-left-0 > * > .box {
    padding-left: 0rem;
  }
  .f-for-box-pd-right-0 > * > .box {
    padding-right: 0rem;
  }
  .f-for-box-pd-top-0 > * > .box {
    padding-top: 0rem;
  }
  .f-for-box-pd-bottom-0 > * > .box {
    padding-bottom: 0rem;
  }
  .f-for-box-pd-x-0 > * > .box {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .f-for-box-pd-y-0 > * > .box {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .f-for-box-pd-0\.5 > * > .box {
    padding: 0.5rem;
  }
  .f-for-box-pd-left-0\.5 > * > .box {
    padding-left: 0.5rem;
  }
  .f-for-box-pd-right-0\.5 > * > .box {
    padding-right: 0.5rem;
  }
  .f-for-box-pd-top-0\.5 > * > .box {
    padding-top: 0.5rem;
  }
  .f-for-box-pd-bottom-0\.5 > * > .box {
    padding-bottom: 0.5rem;
  }
  .f-for-box-pd-x-0\.5 > * > .box {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .f-for-box-pd-y-0\.5 > * > .box {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .f-for-box-pd-1 > * > .box {
    padding: 1rem;
  }
  .f-for-box-pd-left-1 > * > .box {
    padding-left: 1rem;
  }
  .f-for-box-pd-right-1 > * > .box {
    padding-right: 1rem;
  }
  .f-for-box-pd-top-1 > * > .box {
    padding-top: 1rem;
  }
  .f-for-box-pd-bottom-1 > * > .box {
    padding-bottom: 1rem;
  }
  .f-for-box-pd-x-1 > * > .box {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .f-for-box-pd-y-1 > * > .box {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .f-for-box-pd-1\.5 > * > .box {
    padding: 1.5rem;
  }
  .f-for-box-pd-left-1\.5 > * > .box {
    padding-left: 1.5rem;
  }
  .f-for-box-pd-right-1\.5 > * > .box {
    padding-right: 1.5rem;
  }
  .f-for-box-pd-top-1\.5 > * > .box {
    padding-top: 1.5rem;
  }
  .f-for-box-pd-bottom-1\.5 > * > .box {
    padding-bottom: 1.5rem;
  }
  .f-for-box-pd-x-1\.5 > * > .box {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .f-for-box-pd-y-1\.5 > * > .box {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .f-for-box-pd-2 > * > .box {
    padding: 2rem;
  }
  .f-for-box-pd-left-2 > * > .box {
    padding-left: 2rem;
  }
  .f-for-box-pd-right-2 > * > .box {
    padding-right: 2rem;
  }
  .f-for-box-pd-top-2 > * > .box {
    padding-top: 2rem;
  }
  .f-for-box-pd-bottom-2 > * > .box {
    padding-bottom: 2rem;
  }
  .f-for-box-pd-x-2 > * > .box {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .f-for-box-pd-y-2 > * > .box {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .f-for-box-pd-2\.5 > * > .box {
    padding: 2.5rem;
  }
  .f-for-box-pd-left-2\.5 > * > .box {
    padding-left: 2.5rem;
  }
  .f-for-box-pd-right-2\.5 > * > .box {
    padding-right: 2.5rem;
  }
  .f-for-box-pd-top-2\.5 > * > .box {
    padding-top: 2.5rem;
  }
  .f-for-box-pd-bottom-2\.5 > * > .box {
    padding-bottom: 2.5rem;
  }
  .f-for-box-pd-x-2\.5 > * > .box {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .f-for-box-pd-y-2\.5 > * > .box {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .f-for-box-pd-3 > * > .box {
    padding: 3rem;
  }
  .f-for-box-pd-left-3 > * > .box {
    padding-left: 3rem;
  }
  .f-for-box-pd-right-3 > * > .box {
    padding-right: 3rem;
  }
  .f-for-box-pd-top-3 > * > .box {
    padding-top: 3rem;
  }
  .f-for-box-pd-bottom-3 > * > .box {
    padding-bottom: 3rem;
  }
  .f-for-box-pd-x-3 > * > .box {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .f-for-box-pd-y-3 > * > .box {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .f-for-box-pd-3\.5 > * > .box {
    padding: 3.5rem;
  }
  .f-for-box-pd-left-3\.5 > * > .box {
    padding-left: 3.5rem;
  }
  .f-for-box-pd-right-3\.5 > * > .box {
    padding-right: 3.5rem;
  }
  .f-for-box-pd-top-3\.5 > * > .box {
    padding-top: 3.5rem;
  }
  .f-for-box-pd-bottom-3\.5 > * > .box {
    padding-bottom: 3.5rem;
  }
  .f-for-box-pd-x-3\.5 > * > .box {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .f-for-box-pd-y-3\.5 > * > .box {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .f-for-box-pd-4 > * > .box {
    padding: 4rem;
  }
  .f-for-box-pd-left-4 > * > .box {
    padding-left: 4rem;
  }
  .f-for-box-pd-right-4 > * > .box {
    padding-right: 4rem;
  }
  .f-for-box-pd-top-4 > * > .box {
    padding-top: 4rem;
  }
  .f-for-box-pd-bottom-4 > * > .box {
    padding-bottom: 4rem;
  }
  .f-for-box-pd-x-4 > * > .box {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .f-for-box-pd-y-4 > * > .box {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .f-for-box-pd-4\.5 > * > .box {
    padding: 4.5rem;
  }
  .f-for-box-pd-left-4\.5 > * > .box {
    padding-left: 4.5rem;
  }
  .f-for-box-pd-right-4\.5 > * > .box {
    padding-right: 4.5rem;
  }
  .f-for-box-pd-top-4\.5 > * > .box {
    padding-top: 4.5rem;
  }
  .f-for-box-pd-bottom-4\.5 > * > .box {
    padding-bottom: 4.5rem;
  }
  .f-for-box-pd-x-4\.5 > * > .box {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .f-for-box-pd-y-4\.5 > * > .box {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .f-for-box-pd-5 > * > .box {
    padding: 5rem;
  }
  .f-for-box-pd-left-5 > * > .box {
    padding-left: 5rem;
  }
  .f-for-box-pd-right-5 > * > .box {
    padding-right: 5rem;
  }
  .f-for-box-pd-top-5 > * > .box {
    padding-top: 5rem;
  }
  .f-for-box-pd-bottom-5 > * > .box {
    padding-bottom: 5rem;
  }
  .f-for-box-pd-x-5 > * > .box {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .f-for-box-pd-y-5 > * > .box {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .f-for-box-pd-5\.5 > * > .box {
    padding: 5.5rem;
  }
  .f-for-box-pd-left-5\.5 > * > .box {
    padding-left: 5.5rem;
  }
  .f-for-box-pd-right-5\.5 > * > .box {
    padding-right: 5.5rem;
  }
  .f-for-box-pd-top-5\.5 > * > .box {
    padding-top: 5.5rem;
  }
  .f-for-box-pd-bottom-5\.5 > * > .box {
    padding-bottom: 5.5rem;
  }
  .f-for-box-pd-x-5\.5 > * > .box {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
  .f-for-box-pd-y-5\.5 > * > .box {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .f-for-box-pd-6 > * > .box {
    padding: 6rem;
  }
  .f-for-box-pd-left-6 > * > .box {
    padding-left: 6rem;
  }
  .f-for-box-pd-right-6 > * > .box {
    padding-right: 6rem;
  }
  .f-for-box-pd-top-6 > * > .box {
    padding-top: 6rem;
  }
  .f-for-box-pd-bottom-6 > * > .box {
    padding-bottom: 6rem;
  }
  .f-for-box-pd-x-6 > * > .box {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .f-for-box-pd-y-6 > * > .box {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .f-for-box-pd-6\.5 > * > .box {
    padding: 6.5rem;
  }
  .f-for-box-pd-left-6\.5 > * > .box {
    padding-left: 6.5rem;
  }
  .f-for-box-pd-right-6\.5 > * > .box {
    padding-right: 6.5rem;
  }
  .f-for-box-pd-top-6\.5 > * > .box {
    padding-top: 6.5rem;
  }
  .f-for-box-pd-bottom-6\.5 > * > .box {
    padding-bottom: 6.5rem;
  }
  .f-for-box-pd-x-6\.5 > * > .box {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  .f-for-box-pd-y-6\.5 > * > .box {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .f-for-box-pd-7 > * > .box {
    padding: 7rem;
  }
  .f-for-box-pd-left-7 > * > .box {
    padding-left: 7rem;
  }
  .f-for-box-pd-right-7 > * > .box {
    padding-right: 7rem;
  }
  .f-for-box-pd-top-7 > * > .box {
    padding-top: 7rem;
  }
  .f-for-box-pd-bottom-7 > * > .box {
    padding-bottom: 7rem;
  }
  .f-for-box-pd-x-7 > * > .box {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .f-for-box-pd-y-7 > * > .box {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .f-for-box-pd-7\.5 > * > .box {
    padding: 7.5rem;
  }
  .f-for-box-pd-left-7\.5 > * > .box {
    padding-left: 7.5rem;
  }
  .f-for-box-pd-right-7\.5 > * > .box {
    padding-right: 7.5rem;
  }
  .f-for-box-pd-top-7\.5 > * > .box {
    padding-top: 7.5rem;
  }
  .f-for-box-pd-bottom-7\.5 > * > .box {
    padding-bottom: 7.5rem;
  }
  .f-for-box-pd-x-7\.5 > * > .box {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .f-for-box-pd-y-7\.5 > * > .box {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .f-for-box-pd-8 > * > .box {
    padding: 8rem;
  }
  .f-for-box-pd-left-8 > * > .box {
    padding-left: 8rem;
  }
  .f-for-box-pd-right-8 > * > .box {
    padding-right: 8rem;
  }
  .f-for-box-pd-top-8 > * > .box {
    padding-top: 8rem;
  }
  .f-for-box-pd-bottom-8 > * > .box {
    padding-bottom: 8rem;
  }
  .f-for-box-pd-x-8 > * > .box {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .f-for-box-pd-y-8 > * > .box {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .f-for-box-pd-8\.5 > * > .box {
    padding: 8.5rem;
  }
  .f-for-box-pd-left-8\.5 > * > .box {
    padding-left: 8.5rem;
  }
  .f-for-box-pd-right-8\.5 > * > .box {
    padding-right: 8.5rem;
  }
  .f-for-box-pd-top-8\.5 > * > .box {
    padding-top: 8.5rem;
  }
  .f-for-box-pd-bottom-8\.5 > * > .box {
    padding-bottom: 8.5rem;
  }
  .f-for-box-pd-x-8\.5 > * > .box {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .f-for-box-pd-y-8\.5 > * > .box {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .f-for-box-pd-9 > * > .box {
    padding: 9rem;
  }
  .f-for-box-pd-left-9 > * > .box {
    padding-left: 9rem;
  }
  .f-for-box-pd-right-9 > * > .box {
    padding-right: 9rem;
  }
  .f-for-box-pd-top-9 > * > .box {
    padding-top: 9rem;
  }
  .f-for-box-pd-bottom-9 > * > .box {
    padding-bottom: 9rem;
  }
  .f-for-box-pd-x-9 > * > .box {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .f-for-box-pd-y-9 > * > .box {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .f-for-box-pd-9\.5 > * > .box {
    padding: 9.5rem;
  }
  .f-for-box-pd-left-9\.5 > * > .box {
    padding-left: 9.5rem;
  }
  .f-for-box-pd-right-9\.5 > * > .box {
    padding-right: 9.5rem;
  }
  .f-for-box-pd-top-9\.5 > * > .box {
    padding-top: 9.5rem;
  }
  .f-for-box-pd-bottom-9\.5 > * > .box {
    padding-bottom: 9.5rem;
  }
  .f-for-box-pd-x-9\.5 > * > .box {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .f-for-box-pd-y-9\.5 > * > .box {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .f-for-box-pd-10 > * > .box {
    padding: 10rem;
  }
  .f-for-box-pd-left-10 > * > .box {
    padding-left: 10rem;
  }
  .f-for-box-pd-right-10 > * > .box {
    padding-right: 10rem;
  }
  .f-for-box-pd-top-10 > * > .box {
    padding-top: 10rem;
  }
  .f-for-box-pd-bottom-10 > * > .box {
    padding-bottom: 10rem;
  }
  .f-for-box-pd-x-10 > * > .box {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .f-for-box-pd-y-10 > * > .box {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .f-for-bgpic-radius > * > .bgpic {
    border-radius: 0.25rem;
    overflow: hidden;
  }
  .f-for-bgpic-border > * > .bgpic {
    border: 1px solid #E1E1E1;
  }
  .f-for-border > * {
    border: 1px solid #E1E1E1;
  }
  .f-for-hv-shadow {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-shadow:hover {
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-shadow-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-shadow-bottom:hover {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-shadow-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-shadow-top:hover {
    -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-shadow-left {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-shadow-left:hover {
    -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-shadow-right {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-shadow-right:hover {
    -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-shadow-left-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-shadow-left-top:hover {
    -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-shadow-right-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-shadow-right-top:hover {
    -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-shadow-left-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-shadow-left-bottom:hover {
    -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-shadow-right-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-shadow-right-bottom:hover {
    -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-box-shadow {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-box-shadow:hover {
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-box-shadow-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-box-shadow-bottom:hover {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-box-shadow-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-box-shadow-top:hover {
    -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-box-shadow-left {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-box-shadow-left:hover {
    -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-box-shadow-right {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-box-shadow-right:hover {
    -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-box-shadow-left-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-box-shadow-left-top:hover {
    -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-box-shadow-right-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-box-shadow-right-top:hover {
    -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-box-shadow-left-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-box-shadow-left-bottom:hover {
    -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-box-shadow-right-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .f-for-hv-box-shadow-right-bottom:hover {
    -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .f-for-hv-ani-pop, .f-for-hv-ani-push, .f-for-hv-ani-pulse, .f-for-hv-ani-float, .f-for-hv-ani-bob, .f-for-hv-ani-skew, .f-for-hv-ani-wobble {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .f-for-hv-ani-pop:hover {
    -webkit-animation-name: pop;
            animation-name: pop;
  }
  .f-for-hv-ani-push:hover {
    -webkit-animation-name: push;
            animation-name: push;
  }
  .f-for-hv-ani-pulse:hover {
    -webkit-animation-name: pulse;
            animation-name: pulse;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  .f-for-hv-ani-float:hover {
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
  }
  .f-for-hv-ani-bob:hover {
    -webkit-animation-name: bob;
            animation-name: bob;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  .f-for-hv-ani-skew:hover {
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
  }
  .f-for-hv-ani-wobble:hover {
    -webkit-animation-name: wobble-top;
            animation-name: wobble-top;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  .f-for-hv-box-ani-pop, .f-for-hv-box-ani-push, .f-for-hv-box-ani-pulse, .f-for-hv-box-ani-float, .f-for-hv-box-ani-bob, .f-for-hv-box-ani-skew, .f-for-hv-box-ani-wobble {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .f-for-hv-box-ani-pop:hover {
    -webkit-animation-name: pop;
            animation-name: pop;
  }
  .f-for-hv-box-ani-push:hover {
    -webkit-animation-name: push;
            animation-name: push;
  }
  .f-for-hv-box-ani-pulse:hover {
    -webkit-animation-name: pulse;
            animation-name: pulse;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  .f-for-hv-box-ani-float:hover {
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
  }
  .f-for-hv-box-ani-bob:hover {
    -webkit-animation-name: bob;
            animation-name: bob;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  .f-for-hv-box-ani-skew:hover {
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
  }
  .f-for-hv-box-ani-wobble:hover {
    -webkit-animation-name: wobble-top;
            animation-name: wobble-top;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
}

@media (max-width: 880px) {
  .m-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .m-absolute {
    position: absolute;
  }
  .m-fixed {
    position: fixed;
  }
  .m-relative {
    position: relative;
  }
  .m-bold {
    font-weight: bold;
  }
  .m-lighter {
    font-weight: lighter;
    font-family: "Microsoft Yahei" !important;
  }
  .m-hide {
    display: none;
  }
  .m-hidden {
    visibility: hidden;
  }
  .m-left {
    left: 0;
  }
  .m-right {
    right: 0;
  }
  .m-top {
    top: 0;
  }
  .m-bottom {
    bottom: 0;
  }
  .m-h-auto {
    height: auto;
  }
  .m-w-auto {
    width: auto;
  }
  .m-vmiddle {
    vertical-align: middle;
  }
  .m-vtop {
    vertical-align: top;
  }
  .m-vbottom {
    vertical-align: bottom;
  }
  .m-float-left {
    float: left;
  }
  .m-float-right {
    float: right;
  }
  .m-float-none {
    float: none;
  }
  .m-show, .m-display {
    display: block;
  }
  .m-show-block, .m-display-block {
    display: block;
  }
  .m-show-table, .m-display-table {
    display: table;
  }
  .m-show-inline, .m-display-inline {
    display: inline;
  }
  .m-show-inline-block, .m-display-inline-block {
    display: inline-block;
  }
  .m-show-flex, .m-display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .m-weight-1 {
    font-weight: 100;
  }
  .m-weight-2 {
    font-weight: 200;
  }
  .m-weight-3 {
    font-weight: 300;
  }
  .m-weight-4 {
    font-weight: 400;
  }
  .m-weight-5 {
    font-weight: 500;
  }
  .m-weight-6 {
    font-weight: 600;
  }
  .m-weight-7 {
    font-weight: 700;
  }
  .m-weight-8 {
    font-weight: 800;
  }
  .m-weight-9 {
    font-weight: 900;
  }
  .m-opacity-0 {
    opacity: 0;
  }
  .m-opacity-0\.1 {
    opacity: 0.1;
  }
  .m-opacity-0\.2 {
    opacity: 0.2;
  }
  .m-opacity-0\.3 {
    opacity: 0.3;
  }
  .m-opacity-0\.4 {
    opacity: 0.4;
  }
  .m-opacity-0\.5 {
    opacity: 0.5;
  }
  .m-opacity-0\.6 {
    opacity: 0.6;
  }
  .m-opacity-0\.7 {
    opacity: 0.7;
  }
  .m-opacity-0\.8 {
    opacity: 0.8;
  }
  .m-opacity-0\.9 {
    opacity: 0.9;
  }
  .m-opacity-1 {
    opacity: 1;
  }
  .m-col-auto {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 1px;
  }
  .m-for-col-auto > * {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 1px;
  }
  .m-col-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    min-width: 1px;
  }
  .m-for-col-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
  }
  .m-col-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    min-width: 1px;
  }
  .m-for-col-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
  }
  .m-col-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    min-width: 1px;
  }
  .m-for-col-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
  }
  .m-col-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    min-width: 1px;
  }
  .m-for-col-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
  }
  .m-col-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    min-width: 1px;
  }
  .m-for-col-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
  }
  .m-col-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    min-width: 1px;
  }
  .m-for-col-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
  .m-col-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    min-width: 1px;
  }
  .m-for-col-7 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
  }
  .m-col-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    min-width: 1px;
  }
  .m-for-col-8 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
  }
  .m-col-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    min-width: 1px;
  }
  .m-for-col-9 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
  }
  .m-col-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    min-width: 1px;
  }
  .m-for-col-10 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
  }
  .m-col-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    min-width: 1px;
  }
  .m-for-col-11 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
  }
  .m-col-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    min-width: 1px;
  }
  .m-for-col-12 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
  .m-width-1 {
    width: 1rem;
  }
  .m-width-2 {
    width: 2rem;
  }
  .m-width-3 {
    width: 3rem;
  }
  .m-width-4 {
    width: 4rem;
  }
  .m-width-5 {
    width: 5rem;
  }
  .m-width-6 {
    width: 6rem;
  }
  .m-width-7 {
    width: 7rem;
  }
  .m-width-8 {
    width: 8rem;
  }
  .m-width-9 {
    width: 9rem;
  }
  .m-width-10 {
    width: 10rem;
  }
  .m-fs-0\.1 {
    font-size: 0.1rem;
  }
  .m-fs-0\.2 {
    font-size: 0.2rem;
  }
  .m-fs-0\.3 {
    font-size: 0.3rem;
  }
  .m-fs-0\.4 {
    font-size: 0.4rem;
  }
  .m-fs-0\.5 {
    font-size: 0.5rem;
  }
  .m-fs-0\.6 {
    font-size: 0.6rem;
  }
  .m-fs-0\.7 {
    font-size: 0.7rem;
  }
  .m-fs-0\.8 {
    font-size: 0.8rem;
  }
  .m-fs-0\.9 {
    font-size: 0.9rem;
  }
  .m-fs-1 {
    font-size: 1rem;
  }
  .m-fs-1\.1 {
    font-size: 1.1rem;
  }
  .m-fs-1\.2 {
    font-size: 1.2rem;
  }
  .m-fs-1\.3 {
    font-size: 1.3rem;
  }
  .m-fs-1\.4 {
    font-size: 1.4rem;
  }
  .m-fs-1\.5 {
    font-size: 1.5rem;
  }
  .m-fs-1\.6 {
    font-size: 1.6rem;
  }
  .m-fs-1\.7 {
    font-size: 1.7rem;
  }
  .m-fs-1\.8 {
    font-size: 1.8rem;
  }
  .m-fs-1\.9 {
    font-size: 1.9rem;
  }
  .m-fs-2 {
    font-size: 2rem;
  }
  .m-fs-2\.1 {
    font-size: 2.1rem;
  }
  .m-fs-2\.2 {
    font-size: 2.2rem;
  }
  .m-fs-2\.3 {
    font-size: 2.3rem;
  }
  .m-fs-2\.4 {
    font-size: 2.4rem;
  }
  .m-fs-2\.5 {
    font-size: 2.5rem;
  }
  .m-fs-2\.6 {
    font-size: 2.6rem;
  }
  .m-fs-2\.7 {
    font-size: 2.7rem;
  }
  .m-fs-2\.8 {
    font-size: 2.8rem;
  }
  .m-fs-2\.9 {
    font-size: 2.9rem;
  }
  .m-fs-3 {
    font-size: 3rem;
  }
  .m-fs-3\.1 {
    font-size: 3.1rem;
  }
  .m-fs-3\.2 {
    font-size: 3.2rem;
  }
  .m-fs-3\.3 {
    font-size: 3.3rem;
  }
  .m-fs-3\.4 {
    font-size: 3.4rem;
  }
  .m-fs-3\.5 {
    font-size: 3.5rem;
  }
  .m-fs-3\.6 {
    font-size: 3.6rem;
  }
  .m-fs-3\.7 {
    font-size: 3.7rem;
  }
  .m-fs-3\.8 {
    font-size: 3.8rem;
  }
  .m-fs-3\.9 {
    font-size: 3.9rem;
  }
  .m-fs-4 {
    font-size: 4rem;
  }
  .m-fs-4\.1 {
    font-size: 4.1rem;
  }
  .m-fs-4\.2 {
    font-size: 4.2rem;
  }
  .m-fs-4\.3 {
    font-size: 4.3rem;
  }
  .m-fs-4\.4 {
    font-size: 4.4rem;
  }
  .m-fs-4\.5 {
    font-size: 4.5rem;
  }
  .m-fs-4\.6 {
    font-size: 4.6rem;
  }
  .m-fs-4\.7 {
    font-size: 4.7rem;
  }
  .m-fs-4\.8 {
    font-size: 4.8rem;
  }
  .m-fs-4\.9 {
    font-size: 4.9rem;
  }
  .m-fs-5 {
    font-size: 5rem;
  }
  .m-fs-5\.1 {
    font-size: 5.1rem;
  }
  .m-fs-5\.2 {
    font-size: 5.2rem;
  }
  .m-fs-5\.3 {
    font-size: 5.3rem;
  }
  .m-fs-5\.4 {
    font-size: 5.4rem;
  }
  .m-fs-5\.5 {
    font-size: 5.5rem;
  }
  .m-fs-5\.6 {
    font-size: 5.6rem;
  }
  .m-fs-5\.7 {
    font-size: 5.7rem;
  }
  .m-fs-5\.8 {
    font-size: 5.8rem;
  }
  .m-fs-5\.9 {
    font-size: 5.9rem;
  }
  .m-fs-6 {
    font-size: 6rem;
  }
  .m-fs-6\.1 {
    font-size: 6.1rem;
  }
  .m-fs-6\.2 {
    font-size: 6.2rem;
  }
  .m-fs-6\.3 {
    font-size: 6.3rem;
  }
  .m-fs-6\.4 {
    font-size: 6.4rem;
  }
  .m-fs-6\.5 {
    font-size: 6.5rem;
  }
  .m-fs-6\.6 {
    font-size: 6.6rem;
  }
  .m-fs-6\.7 {
    font-size: 6.7rem;
  }
  .m-fs-6\.8 {
    font-size: 6.8rem;
  }
  .m-fs-6\.9 {
    font-size: 6.9rem;
  }
  .m-fs-7 {
    font-size: 7rem;
  }
  .m-fs-7\.1 {
    font-size: 7.1rem;
  }
  .m-fs-7\.2 {
    font-size: 7.2rem;
  }
  .m-fs-7\.3 {
    font-size: 7.3rem;
  }
  .m-fs-7\.4 {
    font-size: 7.4rem;
  }
  .m-fs-7\.5 {
    font-size: 7.5rem;
  }
  .m-fs-7\.6 {
    font-size: 7.6rem;
  }
  .m-fs-7\.7 {
    font-size: 7.7rem;
  }
  .m-fs-7\.8 {
    font-size: 7.8rem;
  }
  .m-fs-7\.9 {
    font-size: 7.9rem;
  }
  .m-fs-8 {
    font-size: 8rem;
  }
  .m-fs-8\.1 {
    font-size: 8.1rem;
  }
  .m-fs-8\.2 {
    font-size: 8.2rem;
  }
  .m-fs-8\.3 {
    font-size: 8.3rem;
  }
  .m-fs-8\.4 {
    font-size: 8.4rem;
  }
  .m-fs-8\.5 {
    font-size: 8.5rem;
  }
  .m-fs-8\.6 {
    font-size: 8.6rem;
  }
  .m-fs-8\.7 {
    font-size: 8.7rem;
  }
  .m-fs-8\.8 {
    font-size: 8.8rem;
  }
  .m-fs-8\.9 {
    font-size: 8.9rem;
  }
  .m-fs-9 {
    font-size: 9rem;
  }
  .m-fs-9\.1 {
    font-size: 9.1rem;
  }
  .m-fs-9\.2 {
    font-size: 9.2rem;
  }
  .m-fs-9\.3 {
    font-size: 9.3rem;
  }
  .m-fs-9\.4 {
    font-size: 9.4rem;
  }
  .m-fs-9\.5 {
    font-size: 9.5rem;
  }
  .m-fs-9\.6 {
    font-size: 9.6rem;
  }
  .m-fs-9\.7 {
    font-size: 9.7rem;
  }
  .m-fs-9\.8 {
    font-size: 9.8rem;
  }
  .m-fs-9\.9 {
    font-size: 9.9rem;
  }
  .m-fs-10 {
    font-size: 10rem;
  }
  .m-fs-14 {
    font-size: 0.875rem;
  }
  .m-fs-18 {
    font-size: 1.125rem;
  }
  .m-fs-large {
    font-size: 3rem;
  }
  .m-line-1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .m-line-2 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .m-line-3 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  .m-line-4 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
  .m-line-5 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }
  .m-line-6 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
  .m-line-7 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
  }
  .m-line-8 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
  }
  .m-line-9 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;
  }
  .m-line-10 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
  }
  .m-lh-0\.2 {
    line-height: 0.2;
  }
  .m-lh-02 {
    line-height: 0.2;
  }
  .m-lh-0\.3 {
    line-height: 0.3;
  }
  .m-lh-03 {
    line-height: 0.3;
  }
  .m-lh-0\.4 {
    line-height: 0.4;
  }
  .m-lh-04 {
    line-height: 0.4;
  }
  .m-lh-0\.5 {
    line-height: 0.5;
  }
  .m-lh-05 {
    line-height: 0.5;
  }
  .m-lh-0\.6 {
    line-height: 0.6;
  }
  .m-lh-06 {
    line-height: 0.6;
  }
  .m-lh-0\.7 {
    line-height: 0.7;
  }
  .m-lh-07 {
    line-height: 0.7;
  }
  .m-lh-0\.8 {
    line-height: 0.8;
  }
  .m-lh-08 {
    line-height: 0.8;
  }
  .m-lh-0\.9 {
    line-height: 0.9;
  }
  .m-lh-09 {
    line-height: 0.9;
  }
  .m-lh-1 {
    line-height: 1;
  }
  .m-lh-1 {
    line-height: 1;
  }
  .m-lh-1\.1 {
    line-height: 1.1;
  }
  .m-lh-11 {
    line-height: 1.1;
  }
  .m-lh-1\.2 {
    line-height: 1.2;
  }
  .m-lh-12 {
    line-height: 1.2;
  }
  .m-lh-1\.3 {
    line-height: 1.3;
  }
  .m-lh-13 {
    line-height: 1.3;
  }
  .m-lh-1\.4 {
    line-height: 1.4;
  }
  .m-lh-14 {
    line-height: 1.4;
  }
  .m-lh-1\.5 {
    line-height: 1.5;
  }
  .m-lh-15 {
    line-height: 1.5;
  }
  .m-lh-1\.6 {
    line-height: 1.6;
  }
  .m-lh-16 {
    line-height: 1.6;
  }
  .m-lh-1\.7 {
    line-height: 1.7;
  }
  .m-lh-17 {
    line-height: 1.7;
  }
  .m-lh-1\.8 {
    line-height: 1.8;
  }
  .m-lh-18 {
    line-height: 1.8;
  }
  .m-lh-1\.9 {
    line-height: 1.9;
  }
  .m-lh-19 {
    line-height: 1.9;
  }
  .m-lh-2 {
    line-height: 2;
  }
  .m-lh-2 {
    line-height: 2;
  }
  .m-lh-2\.1 {
    line-height: 2.1;
  }
  .m-lh-21 {
    line-height: 2.1;
  }
  .m-lh-2\.2 {
    line-height: 2.2;
  }
  .m-lh-22 {
    line-height: 2.2;
  }
  .m-lh-2\.3 {
    line-height: 2.3;
  }
  .m-lh-23 {
    line-height: 2.3;
  }
  .m-lh-2\.4 {
    line-height: 2.4;
  }
  .m-lh-24 {
    line-height: 2.4;
  }
  .m-lh-2\.5 {
    line-height: 2.5;
  }
  .m-lh-25 {
    line-height: 2.5;
  }
  .m-lh-2\.6 {
    line-height: 2.6;
  }
  .m-lh-26 {
    line-height: 2.6;
  }
  .m-lh-2\.7 {
    line-height: 2.7;
  }
  .m-lh-27 {
    line-height: 2.7;
  }
  .m-lh-2\.8 {
    line-height: 2.8;
  }
  .m-lh-28 {
    line-height: 2.8;
  }
  .m-lh-2\.9 {
    line-height: 2.9;
  }
  .m-lh-29 {
    line-height: 2.9;
  }
  .m-lh-3 {
    line-height: 3;
  }
  .m-lh-3 {
    line-height: 3;
  }
  .m-lh-3\.1 {
    line-height: 3.1;
  }
  .m-lh-31 {
    line-height: 3.1;
  }
  .m-lh-3\.2 {
    line-height: 3.2;
  }
  .m-lh-32 {
    line-height: 3.2;
  }
  .m-lh-3\.3 {
    line-height: 3.3;
  }
  .m-lh-33 {
    line-height: 3.3;
  }
  .m-lh-3\.4 {
    line-height: 3.4;
  }
  .m-lh-34 {
    line-height: 3.4;
  }
  .m-lh-3\.5 {
    line-height: 3.5;
  }
  .m-lh-35 {
    line-height: 3.5;
  }
  .m-w-auto {
    width: auto;
  }
  .m-for-w-auto > * {
    width: auto;
  }
  .m-w-1 {
    width: 8.33333%;
  }
  .m-for-w-1 > * {
    width: 8.33333%;
  }
  .m-w-2 {
    width: 16.66667%;
  }
  .m-for-w-2 > * {
    width: 16.66667%;
  }
  .m-w-3 {
    width: 25%;
  }
  .m-for-w-3 > * {
    width: 25%;
  }
  .m-w-4 {
    width: 33.33333%;
  }
  .m-for-w-4 > * {
    width: 33.33333%;
  }
  .m-w-5 {
    width: 41.66667%;
  }
  .m-for-w-5 > * {
    width: 41.66667%;
  }
  .m-w-6 {
    width: 50%;
  }
  .m-for-w-6 > * {
    width: 50%;
  }
  .m-w-7 {
    width: 58.33333%;
  }
  .m-for-w-7 > * {
    width: 58.33333%;
  }
  .m-w-8 {
    width: 66.66667%;
  }
  .m-for-w-8 > * {
    width: 66.66667%;
  }
  .m-w-9 {
    width: 75%;
  }
  .m-for-w-9 > * {
    width: 75%;
  }
  .m-w-10 {
    width: 83.33333%;
  }
  .m-for-w-10 > * {
    width: 83.33333%;
  }
  .m-w-11 {
    width: 91.66667%;
  }
  .m-for-w-11 > * {
    width: 91.66667%;
  }
  .m-w-12 {
    width: 100%;
  }
  .m-for-w-12 > * {
    width: 100%;
  }
  .m-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .m-1 > * {
    width: 100%;
  }
  .m-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .m-2 > * {
    width: 50%;
  }
  .m-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .m-3 > * {
    width: 33.33333%;
  }
  .m-4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .m-4 > * {
    width: 25%;
  }
  .m-5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .m-5 > * {
    width: 20%;
  }
  .m-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .m-6 > * {
    width: 16.66667%;
  }
  .m-7 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .m-7 > * {
    width: 14.28571%;
  }
  .m-8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .m-8 > * {
    width: 12.5%;
  }
  .m-9 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .m-9 > * {
    width: 11.11111%;
  }
  .m-10 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .m-10 > * {
    width: 10%;
  }
  .m-overflow-hidden {
    overflow: hidden;
  }
  .m-overflow-x-hidden {
    overflow-x: hidden;
  }
  .m-overflow-y-hidden {
    overflow-y: hidden;
  }
  .m-overflow-x-auto {
    overflow-x: auto;
  }
  .m-overflow-y-auto {
    overflow-y: auto;
  }
  .m-overflow-visible {
    overflow: visible;
  }
  .m-no-events {
    pointer-events: none;
  }
  .m-no-drag {
    -webkit-user-drag: none;
  }
  .m-no-select {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .m-no-bold {
    font-weight: normal;
  }
  .m-no-border {
    border: none;
  }
  .m-no-float {
    float: none;
  }
  .m-no-first-border > *:first-child {
    border: 0;
  }
  .m-no-last-border > *:last-child {
    border: 0;
  }
  .m-no-bg {
    background-color: transparent !important;
    background-image: none !important;
  }
  .m-no-wrap {
    white-space: nowrap;
  }
  .m-no-flex-shrink {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  .m-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* 水平，左起 */
    /* 水平，左起 */
    /* 垂直，上起 */
    /* 垂直，下起 */
    /* flex 水平对齐方式 */
    /*分布对齐，如有空隙，包括始端与末端*/
    /* flex 垂直对齐方式 */
    /*第一行文字的基线对齐。*/
    /*（默认值）：如果项目未设置高度或设为auto，将占满整个容器的高度。*/
    /* flex align-content */
    /* 单项 对齐方式 */
  }
  .m-flex-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .m-flex-nowrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .m-flex-wrap-reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }
  .m-flex-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .m-flex-i {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .m-flex-1 {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .m-flex-direction-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .m-flex-direction-row-reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .m-flex-direction-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .m-flex-direction-column-reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .m-flex-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .m-flex-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .m-flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .m-flex-x-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .m-flex-x-around {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .m-flex-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .m-flex-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .m-flex-middle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .m-flex-baseline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
  .m-flex-stretch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .m-flex-align-start {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }
  .m-flex-align-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: end;
        align-content: flex-end;
  }
  .m-flex-align-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: flex-center;
        align-content: flex-center;
  }
  .m-flex-align-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: justify;
        align-content: space-between;
  }
  .m-flex-align-around {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: distribute;
        align-content: space-around;
  }
  .m-flex-align-stretch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
  }
  .m-flex > .item-top {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
  .m-flex > .item-middle {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .m-flex > .item-bottom {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }
  .m-flex > .item-baseline {
    -ms-flex-item-align: baseline;
        align-self: baseline;
  }
  .m-flex > .item-stretch {
    -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
  }
  .m-flex > .item-auto {
    -ms-flex-item-align: auto;
        -ms-grid-row-align: auto;
        align-self: auto;
  }
  .m-text-center {
    text-align: center;
  }
  .m-text-left {
    text-align: left;
  }
  .m-text-right {
    text-align: right;
  }
  .m-text-indent {
    text-indent: 2em;
  }
  .m-text-underline {
    text-decoration: underline;
  }
  .m-full {
    width: 100%;
    height: 100%;
  }
  .m-full-w {
    width: 100%;
  }
  .m-full-h {
    height: 100%;
  }
  .m-pd-s {
    padding: 0.5rem;
  }
  .m-pd-auto {
    padding: auto;
  }
  .m-pd-x-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .m-pd-y-auto {
    padding-top: auto;
    padding-top: auto;
  }
  .m-pd-left-auto {
    padding-left: auto;
  }
  .m-pd-right-auto {
    padding-right: auto;
  }
  .m-pd-top-auto {
    padding-top: auto;
  }
  .m-pd-bottom-auto {
    padding-bottom: auto;
  }
  .m-pd-0 {
    padding: 0rem;
  }
  .m-pd-left-0 {
    padding-left: 0rem;
  }
  .m-pd-right-0 {
    padding-right: 0rem;
  }
  .m-pd-top-0 {
    padding-top: 0rem;
  }
  .m-pd-bottom-0 {
    padding-bottom: 0rem;
  }
  .m-pd-x-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .m-pd-y-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .m-pd-0\.5 {
    padding: 0.5rem;
  }
  .m-pd-left-0\.5 {
    padding-left: 0.5rem;
  }
  .m-pd-right-0\.5 {
    padding-right: 0.5rem;
  }
  .m-pd-top-0\.5 {
    padding-top: 0.5rem;
  }
  .m-pd-bottom-0\.5 {
    padding-bottom: 0.5rem;
  }
  .m-pd-x-0\.5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .m-pd-y-0\.5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .m-pd-1 {
    padding: 1rem;
  }
  .m-pd-left-1 {
    padding-left: 1rem;
  }
  .m-pd-right-1 {
    padding-right: 1rem;
  }
  .m-pd-top-1 {
    padding-top: 1rem;
  }
  .m-pd-bottom-1 {
    padding-bottom: 1rem;
  }
  .m-pd-x-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .m-pd-y-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .m-pd-1\.5 {
    padding: 1.5rem;
  }
  .m-pd-left-1\.5 {
    padding-left: 1.5rem;
  }
  .m-pd-right-1\.5 {
    padding-right: 1.5rem;
  }
  .m-pd-top-1\.5 {
    padding-top: 1.5rem;
  }
  .m-pd-bottom-1\.5 {
    padding-bottom: 1.5rem;
  }
  .m-pd-x-1\.5 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .m-pd-y-1\.5 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .m-pd-2 {
    padding: 2rem;
  }
  .m-pd-left-2 {
    padding-left: 2rem;
  }
  .m-pd-right-2 {
    padding-right: 2rem;
  }
  .m-pd-top-2 {
    padding-top: 2rem;
  }
  .m-pd-bottom-2 {
    padding-bottom: 2rem;
  }
  .m-pd-x-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .m-pd-y-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .m-pd-2\.5 {
    padding: 2.5rem;
  }
  .m-pd-left-2\.5 {
    padding-left: 2.5rem;
  }
  .m-pd-right-2\.5 {
    padding-right: 2.5rem;
  }
  .m-pd-top-2\.5 {
    padding-top: 2.5rem;
  }
  .m-pd-bottom-2\.5 {
    padding-bottom: 2.5rem;
  }
  .m-pd-x-2\.5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .m-pd-y-2\.5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .m-pd-3 {
    padding: 3rem;
  }
  .m-pd-left-3 {
    padding-left: 3rem;
  }
  .m-pd-right-3 {
    padding-right: 3rem;
  }
  .m-pd-top-3 {
    padding-top: 3rem;
  }
  .m-pd-bottom-3 {
    padding-bottom: 3rem;
  }
  .m-pd-x-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .m-pd-y-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .m-pd-3\.5 {
    padding: 3.5rem;
  }
  .m-pd-left-3\.5 {
    padding-left: 3.5rem;
  }
  .m-pd-right-3\.5 {
    padding-right: 3.5rem;
  }
  .m-pd-top-3\.5 {
    padding-top: 3.5rem;
  }
  .m-pd-bottom-3\.5 {
    padding-bottom: 3.5rem;
  }
  .m-pd-x-3\.5 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .m-pd-y-3\.5 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .m-pd-4 {
    padding: 4rem;
  }
  .m-pd-left-4 {
    padding-left: 4rem;
  }
  .m-pd-right-4 {
    padding-right: 4rem;
  }
  .m-pd-top-4 {
    padding-top: 4rem;
  }
  .m-pd-bottom-4 {
    padding-bottom: 4rem;
  }
  .m-pd-x-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .m-pd-y-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .m-pd-4\.5 {
    padding: 4.5rem;
  }
  .m-pd-left-4\.5 {
    padding-left: 4.5rem;
  }
  .m-pd-right-4\.5 {
    padding-right: 4.5rem;
  }
  .m-pd-top-4\.5 {
    padding-top: 4.5rem;
  }
  .m-pd-bottom-4\.5 {
    padding-bottom: 4.5rem;
  }
  .m-pd-x-4\.5 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .m-pd-y-4\.5 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .m-pd-5 {
    padding: 5rem;
  }
  .m-pd-left-5 {
    padding-left: 5rem;
  }
  .m-pd-right-5 {
    padding-right: 5rem;
  }
  .m-pd-top-5 {
    padding-top: 5rem;
  }
  .m-pd-bottom-5 {
    padding-bottom: 5rem;
  }
  .m-pd-x-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .m-pd-y-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .m-pd-5\.5 {
    padding: 5.5rem;
  }
  .m-pd-left-5\.5 {
    padding-left: 5.5rem;
  }
  .m-pd-right-5\.5 {
    padding-right: 5.5rem;
  }
  .m-pd-top-5\.5 {
    padding-top: 5.5rem;
  }
  .m-pd-bottom-5\.5 {
    padding-bottom: 5.5rem;
  }
  .m-pd-x-5\.5 {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
  .m-pd-y-5\.5 {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .m-pd-6 {
    padding: 6rem;
  }
  .m-pd-left-6 {
    padding-left: 6rem;
  }
  .m-pd-right-6 {
    padding-right: 6rem;
  }
  .m-pd-top-6 {
    padding-top: 6rem;
  }
  .m-pd-bottom-6 {
    padding-bottom: 6rem;
  }
  .m-pd-x-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .m-pd-y-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .m-pd-6\.5 {
    padding: 6.5rem;
  }
  .m-pd-left-6\.5 {
    padding-left: 6.5rem;
  }
  .m-pd-right-6\.5 {
    padding-right: 6.5rem;
  }
  .m-pd-top-6\.5 {
    padding-top: 6.5rem;
  }
  .m-pd-bottom-6\.5 {
    padding-bottom: 6.5rem;
  }
  .m-pd-x-6\.5 {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  .m-pd-y-6\.5 {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .m-pd-7 {
    padding: 7rem;
  }
  .m-pd-left-7 {
    padding-left: 7rem;
  }
  .m-pd-right-7 {
    padding-right: 7rem;
  }
  .m-pd-top-7 {
    padding-top: 7rem;
  }
  .m-pd-bottom-7 {
    padding-bottom: 7rem;
  }
  .m-pd-x-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .m-pd-y-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .m-pd-7\.5 {
    padding: 7.5rem;
  }
  .m-pd-left-7\.5 {
    padding-left: 7.5rem;
  }
  .m-pd-right-7\.5 {
    padding-right: 7.5rem;
  }
  .m-pd-top-7\.5 {
    padding-top: 7.5rem;
  }
  .m-pd-bottom-7\.5 {
    padding-bottom: 7.5rem;
  }
  .m-pd-x-7\.5 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .m-pd-y-7\.5 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .m-pd-8 {
    padding: 8rem;
  }
  .m-pd-left-8 {
    padding-left: 8rem;
  }
  .m-pd-right-8 {
    padding-right: 8rem;
  }
  .m-pd-top-8 {
    padding-top: 8rem;
  }
  .m-pd-bottom-8 {
    padding-bottom: 8rem;
  }
  .m-pd-x-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .m-pd-y-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .m-pd-8\.5 {
    padding: 8.5rem;
  }
  .m-pd-left-8\.5 {
    padding-left: 8.5rem;
  }
  .m-pd-right-8\.5 {
    padding-right: 8.5rem;
  }
  .m-pd-top-8\.5 {
    padding-top: 8.5rem;
  }
  .m-pd-bottom-8\.5 {
    padding-bottom: 8.5rem;
  }
  .m-pd-x-8\.5 {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .m-pd-y-8\.5 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .m-pd-9 {
    padding: 9rem;
  }
  .m-pd-left-9 {
    padding-left: 9rem;
  }
  .m-pd-right-9 {
    padding-right: 9rem;
  }
  .m-pd-top-9 {
    padding-top: 9rem;
  }
  .m-pd-bottom-9 {
    padding-bottom: 9rem;
  }
  .m-pd-x-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .m-pd-y-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .m-pd-9\.5 {
    padding: 9.5rem;
  }
  .m-pd-left-9\.5 {
    padding-left: 9.5rem;
  }
  .m-pd-right-9\.5 {
    padding-right: 9.5rem;
  }
  .m-pd-top-9\.5 {
    padding-top: 9.5rem;
  }
  .m-pd-bottom-9\.5 {
    padding-bottom: 9.5rem;
  }
  .m-pd-x-9\.5 {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .m-pd-y-9\.5 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .m-pd-10 {
    padding: 10rem;
  }
  .m-pd-left-10 {
    padding-left: 10rem;
  }
  .m-pd-right-10 {
    padding-right: 10rem;
  }
  .m-pd-top-10 {
    padding-top: 10rem;
  }
  .m-pd-bottom-10 {
    padding-bottom: 10rem;
  }
  .m-pd-x-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .m-pd-y-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .m-mg-s {
    margin: 0.5rem;
  }
  .m-mg-auto {
    margin: auto;
  }
  .m-mg-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .m-mg-y-auto {
    margin-top: auto;
    margin-top: auto;
  }
  .m-mg-left-auto {
    margin-left: auto;
  }
  .m-mg-right-auto {
    margin-right: auto;
  }
  .m-mg-top-auto {
    margin-top: auto;
  }
  .m-mg-bottom-auto {
    margin-bottom: auto;
  }
  .m-mg-0 {
    margin: 0rem;
  }
  .m-mg-left-0 {
    margin-left: 0rem;
  }
  .m-mg-right-0 {
    margin-right: 0rem;
  }
  .m-mg-top-0 {
    margin-top: 0rem;
  }
  .m-mg-bottom-0 {
    margin-bottom: 0rem;
  }
  .m-mg-x-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .m-mg-y-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .m-mg-0\.5 {
    margin: 0.5rem;
  }
  .m-mg-left-0\.5 {
    margin-left: 0.5rem;
  }
  .m-mg-right-0\.5 {
    margin-right: 0.5rem;
  }
  .m-mg-top-0\.5 {
    margin-top: 0.5rem;
  }
  .m-mg-bottom-0\.5 {
    margin-bottom: 0.5rem;
  }
  .m-mg-x-0\.5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .m-mg-y-0\.5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .m-mg-1 {
    margin: 1rem;
  }
  .m-mg-left-1 {
    margin-left: 1rem;
  }
  .m-mg-right-1 {
    margin-right: 1rem;
  }
  .m-mg-top-1 {
    margin-top: 1rem;
  }
  .m-mg-bottom-1 {
    margin-bottom: 1rem;
  }
  .m-mg-x-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .m-mg-y-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .m-mg-1\.5 {
    margin: 1.5rem;
  }
  .m-mg-left-1\.5 {
    margin-left: 1.5rem;
  }
  .m-mg-right-1\.5 {
    margin-right: 1.5rem;
  }
  .m-mg-top-1\.5 {
    margin-top: 1.5rem;
  }
  .m-mg-bottom-1\.5 {
    margin-bottom: 1.5rem;
  }
  .m-mg-x-1\.5 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .m-mg-y-1\.5 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .m-mg-2 {
    margin: 2rem;
  }
  .m-mg-left-2 {
    margin-left: 2rem;
  }
  .m-mg-right-2 {
    margin-right: 2rem;
  }
  .m-mg-top-2 {
    margin-top: 2rem;
  }
  .m-mg-bottom-2 {
    margin-bottom: 2rem;
  }
  .m-mg-x-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .m-mg-y-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .m-mg-2\.5 {
    margin: 2.5rem;
  }
  .m-mg-left-2\.5 {
    margin-left: 2.5rem;
  }
  .m-mg-right-2\.5 {
    margin-right: 2.5rem;
  }
  .m-mg-top-2\.5 {
    margin-top: 2.5rem;
  }
  .m-mg-bottom-2\.5 {
    margin-bottom: 2.5rem;
  }
  .m-mg-x-2\.5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .m-mg-y-2\.5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .m-mg-3 {
    margin: 3rem;
  }
  .m-mg-left-3 {
    margin-left: 3rem;
  }
  .m-mg-right-3 {
    margin-right: 3rem;
  }
  .m-mg-top-3 {
    margin-top: 3rem;
  }
  .m-mg-bottom-3 {
    margin-bottom: 3rem;
  }
  .m-mg-x-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .m-mg-y-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .m-mg-3\.5 {
    margin: 3.5rem;
  }
  .m-mg-left-3\.5 {
    margin-left: 3.5rem;
  }
  .m-mg-right-3\.5 {
    margin-right: 3.5rem;
  }
  .m-mg-top-3\.5 {
    margin-top: 3.5rem;
  }
  .m-mg-bottom-3\.5 {
    margin-bottom: 3.5rem;
  }
  .m-mg-x-3\.5 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .m-mg-y-3\.5 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .m-mg-4 {
    margin: 4rem;
  }
  .m-mg-left-4 {
    margin-left: 4rem;
  }
  .m-mg-right-4 {
    margin-right: 4rem;
  }
  .m-mg-top-4 {
    margin-top: 4rem;
  }
  .m-mg-bottom-4 {
    margin-bottom: 4rem;
  }
  .m-mg-x-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .m-mg-y-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .m-mg-4\.5 {
    margin: 4.5rem;
  }
  .m-mg-left-4\.5 {
    margin-left: 4.5rem;
  }
  .m-mg-right-4\.5 {
    margin-right: 4.5rem;
  }
  .m-mg-top-4\.5 {
    margin-top: 4.5rem;
  }
  .m-mg-bottom-4\.5 {
    margin-bottom: 4.5rem;
  }
  .m-mg-x-4\.5 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .m-mg-y-4\.5 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .m-mg-5 {
    margin: 5rem;
  }
  .m-mg-left-5 {
    margin-left: 5rem;
  }
  .m-mg-right-5 {
    margin-right: 5rem;
  }
  .m-mg-top-5 {
    margin-top: 5rem;
  }
  .m-mg-bottom-5 {
    margin-bottom: 5rem;
  }
  .m-mg-x-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .m-mg-y-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .m-mg-5\.5 {
    margin: 5.5rem;
  }
  .m-mg-left-5\.5 {
    margin-left: 5.5rem;
  }
  .m-mg-right-5\.5 {
    margin-right: 5.5rem;
  }
  .m-mg-top-5\.5 {
    margin-top: 5.5rem;
  }
  .m-mg-bottom-5\.5 {
    margin-bottom: 5.5rem;
  }
  .m-mg-x-5\.5 {
    margin-left: 5.5rem;
    margin-right: 5.5rem;
  }
  .m-mg-y-5\.5 {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
  }
  .m-mg-6 {
    margin: 6rem;
  }
  .m-mg-left-6 {
    margin-left: 6rem;
  }
  .m-mg-right-6 {
    margin-right: 6rem;
  }
  .m-mg-top-6 {
    margin-top: 6rem;
  }
  .m-mg-bottom-6 {
    margin-bottom: 6rem;
  }
  .m-mg-x-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .m-mg-y-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .m-mg-6\.5 {
    margin: 6.5rem;
  }
  .m-mg-left-6\.5 {
    margin-left: 6.5rem;
  }
  .m-mg-right-6\.5 {
    margin-right: 6.5rem;
  }
  .m-mg-top-6\.5 {
    margin-top: 6.5rem;
  }
  .m-mg-bottom-6\.5 {
    margin-bottom: 6.5rem;
  }
  .m-mg-x-6\.5 {
    margin-left: 6.5rem;
    margin-right: 6.5rem;
  }
  .m-mg-y-6\.5 {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem;
  }
  .m-mg-7 {
    margin: 7rem;
  }
  .m-mg-left-7 {
    margin-left: 7rem;
  }
  .m-mg-right-7 {
    margin-right: 7rem;
  }
  .m-mg-top-7 {
    margin-top: 7rem;
  }
  .m-mg-bottom-7 {
    margin-bottom: 7rem;
  }
  .m-mg-x-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .m-mg-y-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .m-mg-7\.5 {
    margin: 7.5rem;
  }
  .m-mg-left-7\.5 {
    margin-left: 7.5rem;
  }
  .m-mg-right-7\.5 {
    margin-right: 7.5rem;
  }
  .m-mg-top-7\.5 {
    margin-top: 7.5rem;
  }
  .m-mg-bottom-7\.5 {
    margin-bottom: 7.5rem;
  }
  .m-mg-x-7\.5 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .m-mg-y-7\.5 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .m-mg-8 {
    margin: 8rem;
  }
  .m-mg-left-8 {
    margin-left: 8rem;
  }
  .m-mg-right-8 {
    margin-right: 8rem;
  }
  .m-mg-top-8 {
    margin-top: 8rem;
  }
  .m-mg-bottom-8 {
    margin-bottom: 8rem;
  }
  .m-mg-x-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .m-mg-y-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .m-mg-8\.5 {
    margin: 8.5rem;
  }
  .m-mg-left-8\.5 {
    margin-left: 8.5rem;
  }
  .m-mg-right-8\.5 {
    margin-right: 8.5rem;
  }
  .m-mg-top-8\.5 {
    margin-top: 8.5rem;
  }
  .m-mg-bottom-8\.5 {
    margin-bottom: 8.5rem;
  }
  .m-mg-x-8\.5 {
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .m-mg-y-8\.5 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }
  .m-mg-9 {
    margin: 9rem;
  }
  .m-mg-left-9 {
    margin-left: 9rem;
  }
  .m-mg-right-9 {
    margin-right: 9rem;
  }
  .m-mg-top-9 {
    margin-top: 9rem;
  }
  .m-mg-bottom-9 {
    margin-bottom: 9rem;
  }
  .m-mg-x-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .m-mg-y-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .m-mg-9\.5 {
    margin: 9.5rem;
  }
  .m-mg-left-9\.5 {
    margin-left: 9.5rem;
  }
  .m-mg-right-9\.5 {
    margin-right: 9.5rem;
  }
  .m-mg-top-9\.5 {
    margin-top: 9.5rem;
  }
  .m-mg-bottom-9\.5 {
    margin-bottom: 9.5rem;
  }
  .m-mg-x-9\.5 {
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .m-mg-y-9\.5 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem;
  }
  .m-mg-10 {
    margin: 10rem;
  }
  .m-mg-left-10 {
    margin-left: 10rem;
  }
  .m-mg-right-10 {
    margin-right: 10rem;
  }
  .m-mg-top-10 {
    margin-top: 10rem;
  }
  .m-mg-bottom-10 {
    margin-bottom: 10rem;
  }
  .m-mg-x-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .m-mg-y-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .m-for-no-flex-shrink > * {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  .m-for-full > * {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-align-items: stretch;
  }
  .m-for-full > * > .box {
    width: 100%;
  }
  .m-for-radius > * {
    border-radius: 0.25rem;
  }
  .m-for-cursor-pointer > * {
    cursor: pointer;
  }
  .m-for-spacing-s {
    margin-left: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .m-for-spacing-s > * {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .m-for-spacing-0 {
    margin-left: -0rem;
    margin-bottom: -0rem;
  }
  .m-for-spacing-0 > * {
    padding-left: 0rem;
    padding-bottom: 0rem;
  }
  .m-for-spacing-0\.5 {
    margin-left: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .m-for-spacing-0\.5 > * {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .m-for-spacing-1 {
    margin-left: -1rem;
    margin-bottom: -1rem;
  }
  .m-for-spacing-1 > * {
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
  .m-for-spacing-1\.5 {
    margin-left: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .m-for-spacing-1\.5 > * {
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .m-for-spacing-2 {
    margin-left: -2rem;
    margin-bottom: -2rem;
  }
  .m-for-spacing-2 > * {
    padding-left: 2rem;
    padding-bottom: 2rem;
  }
  .m-for-spacing-2\.5 {
    margin-left: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .m-for-spacing-2\.5 > * {
    padding-left: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .m-for-spacing-3 {
    margin-left: -3rem;
    margin-bottom: -3rem;
  }
  .m-for-spacing-3 > * {
    padding-left: 3rem;
    padding-bottom: 3rem;
  }
  .m-for-spacing-3\.5 {
    margin-left: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .m-for-spacing-3\.5 > * {
    padding-left: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .m-for-spacing-4 {
    margin-left: -4rem;
    margin-bottom: -4rem;
  }
  .m-for-spacing-4 > * {
    padding-left: 4rem;
    padding-bottom: 4rem;
  }
  .m-for-spacing-4\.5 {
    margin-left: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .m-for-spacing-4\.5 > * {
    padding-left: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .m-for-spacing-5 {
    margin-left: -5rem;
    margin-bottom: -5rem;
  }
  .m-for-spacing-5 > * {
    padding-left: 5rem;
    padding-bottom: 5rem;
  }
  .m-for-spacing-5\.5 {
    margin-left: -5.5rem;
    margin-bottom: -5.5rem;
  }
  .m-for-spacing-5\.5 > * {
    padding-left: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .m-for-spacing-6 {
    margin-left: -6rem;
    margin-bottom: -6rem;
  }
  .m-for-spacing-6 > * {
    padding-left: 6rem;
    padding-bottom: 6rem;
  }
  .m-for-spacing-6\.5 {
    margin-left: -6.5rem;
    margin-bottom: -6.5rem;
  }
  .m-for-spacing-6\.5 > * {
    padding-left: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .m-for-spacing-7 {
    margin-left: -7rem;
    margin-bottom: -7rem;
  }
  .m-for-spacing-7 > * {
    padding-left: 7rem;
    padding-bottom: 7rem;
  }
  .m-for-spacing-7\.5 {
    margin-left: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .m-for-spacing-7\.5 > * {
    padding-left: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .m-for-spacing-8 {
    margin-left: -8rem;
    margin-bottom: -8rem;
  }
  .m-for-spacing-8 > * {
    padding-left: 8rem;
    padding-bottom: 8rem;
  }
  .m-for-spacing-8\.5 {
    margin-left: -8.5rem;
    margin-bottom: -8.5rem;
  }
  .m-for-spacing-8\.5 > * {
    padding-left: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .m-for-spacing-9 {
    margin-left: -9rem;
    margin-bottom: -9rem;
  }
  .m-for-spacing-9 > * {
    padding-left: 9rem;
    padding-bottom: 9rem;
  }
  .m-for-spacing-9\.5 {
    margin-left: -9.5rem;
    margin-bottom: -9.5rem;
  }
  .m-for-spacing-9\.5 > * {
    padding-left: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .m-for-spacing-10 {
    margin-left: -10rem;
    margin-bottom: -10rem;
  }
  .m-for-spacing-10 > * {
    padding-left: 10rem;
    padding-bottom: 10rem;
  }
  .m-for-box-radius-0 > * > .box {
    border-radius: 0rem;
  }
  .m-for-box-radius-0\.1 > * > .box {
    border-radius: 0.1rem;
  }
  .m-for-box-radius-0\.2 > * > .box {
    border-radius: 0.2rem;
  }
  .m-for-box-radius-0\.3 > * > .box {
    border-radius: 0.3rem;
  }
  .m-for-box-radius-0\.4 > * > .box {
    border-radius: 0.4rem;
  }
  .m-for-box-radius-0\.5 > * > .box {
    border-radius: 0.5rem;
  }
  .m-for-box-radius-0\.6 > * > .box {
    border-radius: 0.6rem;
  }
  .m-for-box-radius-0\.7 > * > .box {
    border-radius: 0.7rem;
  }
  .m-for-box-radius-0\.8 > * > .box {
    border-radius: 0.8rem;
  }
  .m-for-box-radius-0\.9 > * > .box {
    border-radius: 0.9rem;
  }
  .m-for-box-radius-1 > * > .box {
    border-radius: 1rem;
  }
  .m-for-box-radius-1\.1 > * > .box {
    border-radius: 1.1rem;
  }
  .m-for-box-radius-1\.2 > * > .box {
    border-radius: 1.2rem;
  }
  .m-for-box-radius-1\.3 > * > .box {
    border-radius: 1.3rem;
  }
  .m-for-box-radius-1\.4 > * > .box {
    border-radius: 1.4rem;
  }
  .m-for-box-radius-1\.5 > * > .box {
    border-radius: 1.5rem;
  }
  .m-for-box-radius-1\.6 > * > .box {
    border-radius: 1.6rem;
  }
  .m-for-box-radius-1\.7 > * > .box {
    border-radius: 1.7rem;
  }
  .m-for-box-radius-1\.8 > * > .box {
    border-radius: 1.8rem;
  }
  .m-for-box-radius-1\.9 > * > .box {
    border-radius: 1.9rem;
  }
  .m-for-box-radius-2 > * > .box {
    border-radius: 2rem;
  }
  .m-for-box-radius-2\.1 > * > .box {
    border-radius: 2.1rem;
  }
  .m-for-box-radius-2\.2 > * > .box {
    border-radius: 2.2rem;
  }
  .m-for-box-radius-2\.3 > * > .box {
    border-radius: 2.3rem;
  }
  .m-for-box-radius-2\.4 > * > .box {
    border-radius: 2.4rem;
  }
  .m-for-box-radius-2\.5 > * > .box {
    border-radius: 2.5rem;
  }
  .m-for-box-radius-2\.6 > * > .box {
    border-radius: 2.6rem;
  }
  .m-for-box-radius-2\.7 > * > .box {
    border-radius: 2.7rem;
  }
  .m-for-box-radius-2\.8 > * > .box {
    border-radius: 2.8rem;
  }
  .m-for-box-radius-2\.9 > * > .box {
    border-radius: 2.9rem;
  }
  .m-for-box-radius-3 > * > .box {
    border-radius: 3rem;
  }
  .m-for-pd-0 > * {
    padding: 0rem;
  }
  .m-for-pd-left-0 > * {
    padding-left: 0rem;
  }
  .m-for-pd-right-0 > * {
    padding-right: 0rem;
  }
  .m-for-pd-top-0 > * {
    padding-top: 0rem;
  }
  .m-for-pd-bottom-0 > * {
    padding-bottom: 0rem;
  }
  .m-for-pd-x-0 > * {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .m-for-pd-y-0 > * {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .m-for-pd-0\.5 > * {
    padding: 0.5rem;
  }
  .m-for-pd-left-0\.5 > * {
    padding-left: 0.5rem;
  }
  .m-for-pd-right-0\.5 > * {
    padding-right: 0.5rem;
  }
  .m-for-pd-top-0\.5 > * {
    padding-top: 0.5rem;
  }
  .m-for-pd-bottom-0\.5 > * {
    padding-bottom: 0.5rem;
  }
  .m-for-pd-x-0\.5 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .m-for-pd-y-0\.5 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .m-for-pd-1 > * {
    padding: 1rem;
  }
  .m-for-pd-left-1 > * {
    padding-left: 1rem;
  }
  .m-for-pd-right-1 > * {
    padding-right: 1rem;
  }
  .m-for-pd-top-1 > * {
    padding-top: 1rem;
  }
  .m-for-pd-bottom-1 > * {
    padding-bottom: 1rem;
  }
  .m-for-pd-x-1 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .m-for-pd-y-1 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .m-for-pd-1\.5 > * {
    padding: 1.5rem;
  }
  .m-for-pd-left-1\.5 > * {
    padding-left: 1.5rem;
  }
  .m-for-pd-right-1\.5 > * {
    padding-right: 1.5rem;
  }
  .m-for-pd-top-1\.5 > * {
    padding-top: 1.5rem;
  }
  .m-for-pd-bottom-1\.5 > * {
    padding-bottom: 1.5rem;
  }
  .m-for-pd-x-1\.5 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .m-for-pd-y-1\.5 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .m-for-pd-2 > * {
    padding: 2rem;
  }
  .m-for-pd-left-2 > * {
    padding-left: 2rem;
  }
  .m-for-pd-right-2 > * {
    padding-right: 2rem;
  }
  .m-for-pd-top-2 > * {
    padding-top: 2rem;
  }
  .m-for-pd-bottom-2 > * {
    padding-bottom: 2rem;
  }
  .m-for-pd-x-2 > * {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .m-for-pd-y-2 > * {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .m-for-pd-2\.5 > * {
    padding: 2.5rem;
  }
  .m-for-pd-left-2\.5 > * {
    padding-left: 2.5rem;
  }
  .m-for-pd-right-2\.5 > * {
    padding-right: 2.5rem;
  }
  .m-for-pd-top-2\.5 > * {
    padding-top: 2.5rem;
  }
  .m-for-pd-bottom-2\.5 > * {
    padding-bottom: 2.5rem;
  }
  .m-for-pd-x-2\.5 > * {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .m-for-pd-y-2\.5 > * {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .m-for-pd-3 > * {
    padding: 3rem;
  }
  .m-for-pd-left-3 > * {
    padding-left: 3rem;
  }
  .m-for-pd-right-3 > * {
    padding-right: 3rem;
  }
  .m-for-pd-top-3 > * {
    padding-top: 3rem;
  }
  .m-for-pd-bottom-3 > * {
    padding-bottom: 3rem;
  }
  .m-for-pd-x-3 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .m-for-pd-y-3 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .m-for-pd-3\.5 > * {
    padding: 3.5rem;
  }
  .m-for-pd-left-3\.5 > * {
    padding-left: 3.5rem;
  }
  .m-for-pd-right-3\.5 > * {
    padding-right: 3.5rem;
  }
  .m-for-pd-top-3\.5 > * {
    padding-top: 3.5rem;
  }
  .m-for-pd-bottom-3\.5 > * {
    padding-bottom: 3.5rem;
  }
  .m-for-pd-x-3\.5 > * {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .m-for-pd-y-3\.5 > * {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .m-for-pd-4 > * {
    padding: 4rem;
  }
  .m-for-pd-left-4 > * {
    padding-left: 4rem;
  }
  .m-for-pd-right-4 > * {
    padding-right: 4rem;
  }
  .m-for-pd-top-4 > * {
    padding-top: 4rem;
  }
  .m-for-pd-bottom-4 > * {
    padding-bottom: 4rem;
  }
  .m-for-pd-x-4 > * {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .m-for-pd-y-4 > * {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .m-for-pd-4\.5 > * {
    padding: 4.5rem;
  }
  .m-for-pd-left-4\.5 > * {
    padding-left: 4.5rem;
  }
  .m-for-pd-right-4\.5 > * {
    padding-right: 4.5rem;
  }
  .m-for-pd-top-4\.5 > * {
    padding-top: 4.5rem;
  }
  .m-for-pd-bottom-4\.5 > * {
    padding-bottom: 4.5rem;
  }
  .m-for-pd-x-4\.5 > * {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .m-for-pd-y-4\.5 > * {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .m-for-pd-5 > * {
    padding: 5rem;
  }
  .m-for-pd-left-5 > * {
    padding-left: 5rem;
  }
  .m-for-pd-right-5 > * {
    padding-right: 5rem;
  }
  .m-for-pd-top-5 > * {
    padding-top: 5rem;
  }
  .m-for-pd-bottom-5 > * {
    padding-bottom: 5rem;
  }
  .m-for-pd-x-5 > * {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .m-for-pd-y-5 > * {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .m-for-pd-5\.5 > * {
    padding: 5.5rem;
  }
  .m-for-pd-left-5\.5 > * {
    padding-left: 5.5rem;
  }
  .m-for-pd-right-5\.5 > * {
    padding-right: 5.5rem;
  }
  .m-for-pd-top-5\.5 > * {
    padding-top: 5.5rem;
  }
  .m-for-pd-bottom-5\.5 > * {
    padding-bottom: 5.5rem;
  }
  .m-for-pd-x-5\.5 > * {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
  .m-for-pd-y-5\.5 > * {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .m-for-pd-6 > * {
    padding: 6rem;
  }
  .m-for-pd-left-6 > * {
    padding-left: 6rem;
  }
  .m-for-pd-right-6 > * {
    padding-right: 6rem;
  }
  .m-for-pd-top-6 > * {
    padding-top: 6rem;
  }
  .m-for-pd-bottom-6 > * {
    padding-bottom: 6rem;
  }
  .m-for-pd-x-6 > * {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .m-for-pd-y-6 > * {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .m-for-pd-6\.5 > * {
    padding: 6.5rem;
  }
  .m-for-pd-left-6\.5 > * {
    padding-left: 6.5rem;
  }
  .m-for-pd-right-6\.5 > * {
    padding-right: 6.5rem;
  }
  .m-for-pd-top-6\.5 > * {
    padding-top: 6.5rem;
  }
  .m-for-pd-bottom-6\.5 > * {
    padding-bottom: 6.5rem;
  }
  .m-for-pd-x-6\.5 > * {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  .m-for-pd-y-6\.5 > * {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .m-for-pd-7 > * {
    padding: 7rem;
  }
  .m-for-pd-left-7 > * {
    padding-left: 7rem;
  }
  .m-for-pd-right-7 > * {
    padding-right: 7rem;
  }
  .m-for-pd-top-7 > * {
    padding-top: 7rem;
  }
  .m-for-pd-bottom-7 > * {
    padding-bottom: 7rem;
  }
  .m-for-pd-x-7 > * {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .m-for-pd-y-7 > * {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .m-for-pd-7\.5 > * {
    padding: 7.5rem;
  }
  .m-for-pd-left-7\.5 > * {
    padding-left: 7.5rem;
  }
  .m-for-pd-right-7\.5 > * {
    padding-right: 7.5rem;
  }
  .m-for-pd-top-7\.5 > * {
    padding-top: 7.5rem;
  }
  .m-for-pd-bottom-7\.5 > * {
    padding-bottom: 7.5rem;
  }
  .m-for-pd-x-7\.5 > * {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .m-for-pd-y-7\.5 > * {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .m-for-pd-8 > * {
    padding: 8rem;
  }
  .m-for-pd-left-8 > * {
    padding-left: 8rem;
  }
  .m-for-pd-right-8 > * {
    padding-right: 8rem;
  }
  .m-for-pd-top-8 > * {
    padding-top: 8rem;
  }
  .m-for-pd-bottom-8 > * {
    padding-bottom: 8rem;
  }
  .m-for-pd-x-8 > * {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .m-for-pd-y-8 > * {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .m-for-pd-8\.5 > * {
    padding: 8.5rem;
  }
  .m-for-pd-left-8\.5 > * {
    padding-left: 8.5rem;
  }
  .m-for-pd-right-8\.5 > * {
    padding-right: 8.5rem;
  }
  .m-for-pd-top-8\.5 > * {
    padding-top: 8.5rem;
  }
  .m-for-pd-bottom-8\.5 > * {
    padding-bottom: 8.5rem;
  }
  .m-for-pd-x-8\.5 > * {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .m-for-pd-y-8\.5 > * {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .m-for-pd-9 > * {
    padding: 9rem;
  }
  .m-for-pd-left-9 > * {
    padding-left: 9rem;
  }
  .m-for-pd-right-9 > * {
    padding-right: 9rem;
  }
  .m-for-pd-top-9 > * {
    padding-top: 9rem;
  }
  .m-for-pd-bottom-9 > * {
    padding-bottom: 9rem;
  }
  .m-for-pd-x-9 > * {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .m-for-pd-y-9 > * {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .m-for-pd-9\.5 > * {
    padding: 9.5rem;
  }
  .m-for-pd-left-9\.5 > * {
    padding-left: 9.5rem;
  }
  .m-for-pd-right-9\.5 > * {
    padding-right: 9.5rem;
  }
  .m-for-pd-top-9\.5 > * {
    padding-top: 9.5rem;
  }
  .m-for-pd-bottom-9\.5 > * {
    padding-bottom: 9.5rem;
  }
  .m-for-pd-x-9\.5 > * {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .m-for-pd-y-9\.5 > * {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .m-for-pd-10 > * {
    padding: 10rem;
  }
  .m-for-pd-left-10 > * {
    padding-left: 10rem;
  }
  .m-for-pd-right-10 > * {
    padding-right: 10rem;
  }
  .m-for-pd-top-10 > * {
    padding-top: 10rem;
  }
  .m-for-pd-bottom-10 > * {
    padding-bottom: 10rem;
  }
  .m-for-pd-x-10 > * {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .m-for-pd-y-10 > * {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .m-for-mg-0 > * {
    margin: 0rem;
  }
  .m-for-mg-left-0 > * {
    margin-left: 0rem;
  }
  .m-for-mg-right-0 > * {
    margin-right: 0rem;
  }
  .m-for-mg-top-0 > * {
    margin-top: 0rem;
  }
  .m-for-mg-bottom-0 > * {
    margin-bottom: 0rem;
  }
  .m-for-mg-x-0 > * {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .m-for-mg-y-0 > * {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .m-for-mg-0\.5 > * {
    margin: 0.5rem;
  }
  .m-for-mg-left-0\.5 > * {
    margin-left: 0.5rem;
  }
  .m-for-mg-right-0\.5 > * {
    margin-right: 0.5rem;
  }
  .m-for-mg-top-0\.5 > * {
    margin-top: 0.5rem;
  }
  .m-for-mg-bottom-0\.5 > * {
    margin-bottom: 0.5rem;
  }
  .m-for-mg-x-0\.5 > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .m-for-mg-y-0\.5 > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .m-for-mg-1 > * {
    margin: 1rem;
  }
  .m-for-mg-left-1 > * {
    margin-left: 1rem;
  }
  .m-for-mg-right-1 > * {
    margin-right: 1rem;
  }
  .m-for-mg-top-1 > * {
    margin-top: 1rem;
  }
  .m-for-mg-bottom-1 > * {
    margin-bottom: 1rem;
  }
  .m-for-mg-x-1 > * {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .m-for-mg-y-1 > * {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .m-for-mg-1\.5 > * {
    margin: 1.5rem;
  }
  .m-for-mg-left-1\.5 > * {
    margin-left: 1.5rem;
  }
  .m-for-mg-right-1\.5 > * {
    margin-right: 1.5rem;
  }
  .m-for-mg-top-1\.5 > * {
    margin-top: 1.5rem;
  }
  .m-for-mg-bottom-1\.5 > * {
    margin-bottom: 1.5rem;
  }
  .m-for-mg-x-1\.5 > * {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .m-for-mg-y-1\.5 > * {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .m-for-mg-2 > * {
    margin: 2rem;
  }
  .m-for-mg-left-2 > * {
    margin-left: 2rem;
  }
  .m-for-mg-right-2 > * {
    margin-right: 2rem;
  }
  .m-for-mg-top-2 > * {
    margin-top: 2rem;
  }
  .m-for-mg-bottom-2 > * {
    margin-bottom: 2rem;
  }
  .m-for-mg-x-2 > * {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .m-for-mg-y-2 > * {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .m-for-mg-2\.5 > * {
    margin: 2.5rem;
  }
  .m-for-mg-left-2\.5 > * {
    margin-left: 2.5rem;
  }
  .m-for-mg-right-2\.5 > * {
    margin-right: 2.5rem;
  }
  .m-for-mg-top-2\.5 > * {
    margin-top: 2.5rem;
  }
  .m-for-mg-bottom-2\.5 > * {
    margin-bottom: 2.5rem;
  }
  .m-for-mg-x-2\.5 > * {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .m-for-mg-y-2\.5 > * {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .m-for-mg-3 > * {
    margin: 3rem;
  }
  .m-for-mg-left-3 > * {
    margin-left: 3rem;
  }
  .m-for-mg-right-3 > * {
    margin-right: 3rem;
  }
  .m-for-mg-top-3 > * {
    margin-top: 3rem;
  }
  .m-for-mg-bottom-3 > * {
    margin-bottom: 3rem;
  }
  .m-for-mg-x-3 > * {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .m-for-mg-y-3 > * {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .m-for-mg-3\.5 > * {
    margin: 3.5rem;
  }
  .m-for-mg-left-3\.5 > * {
    margin-left: 3.5rem;
  }
  .m-for-mg-right-3\.5 > * {
    margin-right: 3.5rem;
  }
  .m-for-mg-top-3\.5 > * {
    margin-top: 3.5rem;
  }
  .m-for-mg-bottom-3\.5 > * {
    margin-bottom: 3.5rem;
  }
  .m-for-mg-x-3\.5 > * {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .m-for-mg-y-3\.5 > * {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .m-for-mg-4 > * {
    margin: 4rem;
  }
  .m-for-mg-left-4 > * {
    margin-left: 4rem;
  }
  .m-for-mg-right-4 > * {
    margin-right: 4rem;
  }
  .m-for-mg-top-4 > * {
    margin-top: 4rem;
  }
  .m-for-mg-bottom-4 > * {
    margin-bottom: 4rem;
  }
  .m-for-mg-x-4 > * {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .m-for-mg-y-4 > * {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .m-for-mg-4\.5 > * {
    margin: 4.5rem;
  }
  .m-for-mg-left-4\.5 > * {
    margin-left: 4.5rem;
  }
  .m-for-mg-right-4\.5 > * {
    margin-right: 4.5rem;
  }
  .m-for-mg-top-4\.5 > * {
    margin-top: 4.5rem;
  }
  .m-for-mg-bottom-4\.5 > * {
    margin-bottom: 4.5rem;
  }
  .m-for-mg-x-4\.5 > * {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .m-for-mg-y-4\.5 > * {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .m-for-mg-5 > * {
    margin: 5rem;
  }
  .m-for-mg-left-5 > * {
    margin-left: 5rem;
  }
  .m-for-mg-right-5 > * {
    margin-right: 5rem;
  }
  .m-for-mg-top-5 > * {
    margin-top: 5rem;
  }
  .m-for-mg-bottom-5 > * {
    margin-bottom: 5rem;
  }
  .m-for-mg-x-5 > * {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .m-for-mg-y-5 > * {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .m-for-mg-5\.5 > * {
    margin: 5.5rem;
  }
  .m-for-mg-left-5\.5 > * {
    margin-left: 5.5rem;
  }
  .m-for-mg-right-5\.5 > * {
    margin-right: 5.5rem;
  }
  .m-for-mg-top-5\.5 > * {
    margin-top: 5.5rem;
  }
  .m-for-mg-bottom-5\.5 > * {
    margin-bottom: 5.5rem;
  }
  .m-for-mg-x-5\.5 > * {
    margin-left: 5.5rem;
    margin-right: 5.5rem;
  }
  .m-for-mg-y-5\.5 > * {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
  }
  .m-for-mg-6 > * {
    margin: 6rem;
  }
  .m-for-mg-left-6 > * {
    margin-left: 6rem;
  }
  .m-for-mg-right-6 > * {
    margin-right: 6rem;
  }
  .m-for-mg-top-6 > * {
    margin-top: 6rem;
  }
  .m-for-mg-bottom-6 > * {
    margin-bottom: 6rem;
  }
  .m-for-mg-x-6 > * {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .m-for-mg-y-6 > * {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .m-for-mg-6\.5 > * {
    margin: 6.5rem;
  }
  .m-for-mg-left-6\.5 > * {
    margin-left: 6.5rem;
  }
  .m-for-mg-right-6\.5 > * {
    margin-right: 6.5rem;
  }
  .m-for-mg-top-6\.5 > * {
    margin-top: 6.5rem;
  }
  .m-for-mg-bottom-6\.5 > * {
    margin-bottom: 6.5rem;
  }
  .m-for-mg-x-6\.5 > * {
    margin-left: 6.5rem;
    margin-right: 6.5rem;
  }
  .m-for-mg-y-6\.5 > * {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem;
  }
  .m-for-mg-7 > * {
    margin: 7rem;
  }
  .m-for-mg-left-7 > * {
    margin-left: 7rem;
  }
  .m-for-mg-right-7 > * {
    margin-right: 7rem;
  }
  .m-for-mg-top-7 > * {
    margin-top: 7rem;
  }
  .m-for-mg-bottom-7 > * {
    margin-bottom: 7rem;
  }
  .m-for-mg-x-7 > * {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .m-for-mg-y-7 > * {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .m-for-mg-7\.5 > * {
    margin: 7.5rem;
  }
  .m-for-mg-left-7\.5 > * {
    margin-left: 7.5rem;
  }
  .m-for-mg-right-7\.5 > * {
    margin-right: 7.5rem;
  }
  .m-for-mg-top-7\.5 > * {
    margin-top: 7.5rem;
  }
  .m-for-mg-bottom-7\.5 > * {
    margin-bottom: 7.5rem;
  }
  .m-for-mg-x-7\.5 > * {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .m-for-mg-y-7\.5 > * {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .m-for-mg-8 > * {
    margin: 8rem;
  }
  .m-for-mg-left-8 > * {
    margin-left: 8rem;
  }
  .m-for-mg-right-8 > * {
    margin-right: 8rem;
  }
  .m-for-mg-top-8 > * {
    margin-top: 8rem;
  }
  .m-for-mg-bottom-8 > * {
    margin-bottom: 8rem;
  }
  .m-for-mg-x-8 > * {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .m-for-mg-y-8 > * {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .m-for-mg-8\.5 > * {
    margin: 8.5rem;
  }
  .m-for-mg-left-8\.5 > * {
    margin-left: 8.5rem;
  }
  .m-for-mg-right-8\.5 > * {
    margin-right: 8.5rem;
  }
  .m-for-mg-top-8\.5 > * {
    margin-top: 8.5rem;
  }
  .m-for-mg-bottom-8\.5 > * {
    margin-bottom: 8.5rem;
  }
  .m-for-mg-x-8\.5 > * {
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .m-for-mg-y-8\.5 > * {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }
  .m-for-mg-9 > * {
    margin: 9rem;
  }
  .m-for-mg-left-9 > * {
    margin-left: 9rem;
  }
  .m-for-mg-right-9 > * {
    margin-right: 9rem;
  }
  .m-for-mg-top-9 > * {
    margin-top: 9rem;
  }
  .m-for-mg-bottom-9 > * {
    margin-bottom: 9rem;
  }
  .m-for-mg-x-9 > * {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .m-for-mg-y-9 > * {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .m-for-mg-9\.5 > * {
    margin: 9.5rem;
  }
  .m-for-mg-left-9\.5 > * {
    margin-left: 9.5rem;
  }
  .m-for-mg-right-9\.5 > * {
    margin-right: 9.5rem;
  }
  .m-for-mg-top-9\.5 > * {
    margin-top: 9.5rem;
  }
  .m-for-mg-bottom-9\.5 > * {
    margin-bottom: 9.5rem;
  }
  .m-for-mg-x-9\.5 > * {
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .m-for-mg-y-9\.5 > * {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem;
  }
  .m-for-mg-10 > * {
    margin: 10rem;
  }
  .m-for-mg-left-10 > * {
    margin-left: 10rem;
  }
  .m-for-mg-right-10 > * {
    margin-right: 10rem;
  }
  .m-for-mg-top-10 > * {
    margin-top: 10rem;
  }
  .m-for-mg-bottom-10 > * {
    margin-bottom: 10rem;
  }
  .m-for-mg-x-10 > * {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .m-for-mg-y-10 > * {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .m-for-box-light > * > .box {
    background-color: #f5f5f8;
  }
  .m-for-box-border > * > .box {
    border: 1px solid #E1E1E1;
  }
  .m-for-box-shadow {
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-box-shadow-bottom {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-box-shadow-top {
    -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-box-shadow-left {
    -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-box-shadow-right {
    -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-box-shadow-left-top {
    -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-box-shadow-right-top {
    -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-box-shadow-left-bottom {
    -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-box-shadow-right-bottom {
    -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-box-radius > * > .box {
    border-radius: 1rem;
  }
  .m-for-box-pd-0 > * > .box {
    padding: 0rem;
  }
  .m-for-box-pd-left-0 > * > .box {
    padding-left: 0rem;
  }
  .m-for-box-pd-right-0 > * > .box {
    padding-right: 0rem;
  }
  .m-for-box-pd-top-0 > * > .box {
    padding-top: 0rem;
  }
  .m-for-box-pd-bottom-0 > * > .box {
    padding-bottom: 0rem;
  }
  .m-for-box-pd-x-0 > * > .box {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .m-for-box-pd-y-0 > * > .box {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .m-for-box-pd-0\.5 > * > .box {
    padding: 0.5rem;
  }
  .m-for-box-pd-left-0\.5 > * > .box {
    padding-left: 0.5rem;
  }
  .m-for-box-pd-right-0\.5 > * > .box {
    padding-right: 0.5rem;
  }
  .m-for-box-pd-top-0\.5 > * > .box {
    padding-top: 0.5rem;
  }
  .m-for-box-pd-bottom-0\.5 > * > .box {
    padding-bottom: 0.5rem;
  }
  .m-for-box-pd-x-0\.5 > * > .box {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .m-for-box-pd-y-0\.5 > * > .box {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .m-for-box-pd-1 > * > .box {
    padding: 1rem;
  }
  .m-for-box-pd-left-1 > * > .box {
    padding-left: 1rem;
  }
  .m-for-box-pd-right-1 > * > .box {
    padding-right: 1rem;
  }
  .m-for-box-pd-top-1 > * > .box {
    padding-top: 1rem;
  }
  .m-for-box-pd-bottom-1 > * > .box {
    padding-bottom: 1rem;
  }
  .m-for-box-pd-x-1 > * > .box {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .m-for-box-pd-y-1 > * > .box {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .m-for-box-pd-1\.5 > * > .box {
    padding: 1.5rem;
  }
  .m-for-box-pd-left-1\.5 > * > .box {
    padding-left: 1.5rem;
  }
  .m-for-box-pd-right-1\.5 > * > .box {
    padding-right: 1.5rem;
  }
  .m-for-box-pd-top-1\.5 > * > .box {
    padding-top: 1.5rem;
  }
  .m-for-box-pd-bottom-1\.5 > * > .box {
    padding-bottom: 1.5rem;
  }
  .m-for-box-pd-x-1\.5 > * > .box {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .m-for-box-pd-y-1\.5 > * > .box {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .m-for-box-pd-2 > * > .box {
    padding: 2rem;
  }
  .m-for-box-pd-left-2 > * > .box {
    padding-left: 2rem;
  }
  .m-for-box-pd-right-2 > * > .box {
    padding-right: 2rem;
  }
  .m-for-box-pd-top-2 > * > .box {
    padding-top: 2rem;
  }
  .m-for-box-pd-bottom-2 > * > .box {
    padding-bottom: 2rem;
  }
  .m-for-box-pd-x-2 > * > .box {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .m-for-box-pd-y-2 > * > .box {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .m-for-box-pd-2\.5 > * > .box {
    padding: 2.5rem;
  }
  .m-for-box-pd-left-2\.5 > * > .box {
    padding-left: 2.5rem;
  }
  .m-for-box-pd-right-2\.5 > * > .box {
    padding-right: 2.5rem;
  }
  .m-for-box-pd-top-2\.5 > * > .box {
    padding-top: 2.5rem;
  }
  .m-for-box-pd-bottom-2\.5 > * > .box {
    padding-bottom: 2.5rem;
  }
  .m-for-box-pd-x-2\.5 > * > .box {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .m-for-box-pd-y-2\.5 > * > .box {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .m-for-box-pd-3 > * > .box {
    padding: 3rem;
  }
  .m-for-box-pd-left-3 > * > .box {
    padding-left: 3rem;
  }
  .m-for-box-pd-right-3 > * > .box {
    padding-right: 3rem;
  }
  .m-for-box-pd-top-3 > * > .box {
    padding-top: 3rem;
  }
  .m-for-box-pd-bottom-3 > * > .box {
    padding-bottom: 3rem;
  }
  .m-for-box-pd-x-3 > * > .box {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .m-for-box-pd-y-3 > * > .box {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .m-for-box-pd-3\.5 > * > .box {
    padding: 3.5rem;
  }
  .m-for-box-pd-left-3\.5 > * > .box {
    padding-left: 3.5rem;
  }
  .m-for-box-pd-right-3\.5 > * > .box {
    padding-right: 3.5rem;
  }
  .m-for-box-pd-top-3\.5 > * > .box {
    padding-top: 3.5rem;
  }
  .m-for-box-pd-bottom-3\.5 > * > .box {
    padding-bottom: 3.5rem;
  }
  .m-for-box-pd-x-3\.5 > * > .box {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .m-for-box-pd-y-3\.5 > * > .box {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .m-for-box-pd-4 > * > .box {
    padding: 4rem;
  }
  .m-for-box-pd-left-4 > * > .box {
    padding-left: 4rem;
  }
  .m-for-box-pd-right-4 > * > .box {
    padding-right: 4rem;
  }
  .m-for-box-pd-top-4 > * > .box {
    padding-top: 4rem;
  }
  .m-for-box-pd-bottom-4 > * > .box {
    padding-bottom: 4rem;
  }
  .m-for-box-pd-x-4 > * > .box {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .m-for-box-pd-y-4 > * > .box {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .m-for-box-pd-4\.5 > * > .box {
    padding: 4.5rem;
  }
  .m-for-box-pd-left-4\.5 > * > .box {
    padding-left: 4.5rem;
  }
  .m-for-box-pd-right-4\.5 > * > .box {
    padding-right: 4.5rem;
  }
  .m-for-box-pd-top-4\.5 > * > .box {
    padding-top: 4.5rem;
  }
  .m-for-box-pd-bottom-4\.5 > * > .box {
    padding-bottom: 4.5rem;
  }
  .m-for-box-pd-x-4\.5 > * > .box {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .m-for-box-pd-y-4\.5 > * > .box {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .m-for-box-pd-5 > * > .box {
    padding: 5rem;
  }
  .m-for-box-pd-left-5 > * > .box {
    padding-left: 5rem;
  }
  .m-for-box-pd-right-5 > * > .box {
    padding-right: 5rem;
  }
  .m-for-box-pd-top-5 > * > .box {
    padding-top: 5rem;
  }
  .m-for-box-pd-bottom-5 > * > .box {
    padding-bottom: 5rem;
  }
  .m-for-box-pd-x-5 > * > .box {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .m-for-box-pd-y-5 > * > .box {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .m-for-box-pd-5\.5 > * > .box {
    padding: 5.5rem;
  }
  .m-for-box-pd-left-5\.5 > * > .box {
    padding-left: 5.5rem;
  }
  .m-for-box-pd-right-5\.5 > * > .box {
    padding-right: 5.5rem;
  }
  .m-for-box-pd-top-5\.5 > * > .box {
    padding-top: 5.5rem;
  }
  .m-for-box-pd-bottom-5\.5 > * > .box {
    padding-bottom: 5.5rem;
  }
  .m-for-box-pd-x-5\.5 > * > .box {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
  .m-for-box-pd-y-5\.5 > * > .box {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .m-for-box-pd-6 > * > .box {
    padding: 6rem;
  }
  .m-for-box-pd-left-6 > * > .box {
    padding-left: 6rem;
  }
  .m-for-box-pd-right-6 > * > .box {
    padding-right: 6rem;
  }
  .m-for-box-pd-top-6 > * > .box {
    padding-top: 6rem;
  }
  .m-for-box-pd-bottom-6 > * > .box {
    padding-bottom: 6rem;
  }
  .m-for-box-pd-x-6 > * > .box {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .m-for-box-pd-y-6 > * > .box {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .m-for-box-pd-6\.5 > * > .box {
    padding: 6.5rem;
  }
  .m-for-box-pd-left-6\.5 > * > .box {
    padding-left: 6.5rem;
  }
  .m-for-box-pd-right-6\.5 > * > .box {
    padding-right: 6.5rem;
  }
  .m-for-box-pd-top-6\.5 > * > .box {
    padding-top: 6.5rem;
  }
  .m-for-box-pd-bottom-6\.5 > * > .box {
    padding-bottom: 6.5rem;
  }
  .m-for-box-pd-x-6\.5 > * > .box {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  .m-for-box-pd-y-6\.5 > * > .box {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .m-for-box-pd-7 > * > .box {
    padding: 7rem;
  }
  .m-for-box-pd-left-7 > * > .box {
    padding-left: 7rem;
  }
  .m-for-box-pd-right-7 > * > .box {
    padding-right: 7rem;
  }
  .m-for-box-pd-top-7 > * > .box {
    padding-top: 7rem;
  }
  .m-for-box-pd-bottom-7 > * > .box {
    padding-bottom: 7rem;
  }
  .m-for-box-pd-x-7 > * > .box {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .m-for-box-pd-y-7 > * > .box {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .m-for-box-pd-7\.5 > * > .box {
    padding: 7.5rem;
  }
  .m-for-box-pd-left-7\.5 > * > .box {
    padding-left: 7.5rem;
  }
  .m-for-box-pd-right-7\.5 > * > .box {
    padding-right: 7.5rem;
  }
  .m-for-box-pd-top-7\.5 > * > .box {
    padding-top: 7.5rem;
  }
  .m-for-box-pd-bottom-7\.5 > * > .box {
    padding-bottom: 7.5rem;
  }
  .m-for-box-pd-x-7\.5 > * > .box {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .m-for-box-pd-y-7\.5 > * > .box {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .m-for-box-pd-8 > * > .box {
    padding: 8rem;
  }
  .m-for-box-pd-left-8 > * > .box {
    padding-left: 8rem;
  }
  .m-for-box-pd-right-8 > * > .box {
    padding-right: 8rem;
  }
  .m-for-box-pd-top-8 > * > .box {
    padding-top: 8rem;
  }
  .m-for-box-pd-bottom-8 > * > .box {
    padding-bottom: 8rem;
  }
  .m-for-box-pd-x-8 > * > .box {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .m-for-box-pd-y-8 > * > .box {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .m-for-box-pd-8\.5 > * > .box {
    padding: 8.5rem;
  }
  .m-for-box-pd-left-8\.5 > * > .box {
    padding-left: 8.5rem;
  }
  .m-for-box-pd-right-8\.5 > * > .box {
    padding-right: 8.5rem;
  }
  .m-for-box-pd-top-8\.5 > * > .box {
    padding-top: 8.5rem;
  }
  .m-for-box-pd-bottom-8\.5 > * > .box {
    padding-bottom: 8.5rem;
  }
  .m-for-box-pd-x-8\.5 > * > .box {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .m-for-box-pd-y-8\.5 > * > .box {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .m-for-box-pd-9 > * > .box {
    padding: 9rem;
  }
  .m-for-box-pd-left-9 > * > .box {
    padding-left: 9rem;
  }
  .m-for-box-pd-right-9 > * > .box {
    padding-right: 9rem;
  }
  .m-for-box-pd-top-9 > * > .box {
    padding-top: 9rem;
  }
  .m-for-box-pd-bottom-9 > * > .box {
    padding-bottom: 9rem;
  }
  .m-for-box-pd-x-9 > * > .box {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .m-for-box-pd-y-9 > * > .box {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .m-for-box-pd-9\.5 > * > .box {
    padding: 9.5rem;
  }
  .m-for-box-pd-left-9\.5 > * > .box {
    padding-left: 9.5rem;
  }
  .m-for-box-pd-right-9\.5 > * > .box {
    padding-right: 9.5rem;
  }
  .m-for-box-pd-top-9\.5 > * > .box {
    padding-top: 9.5rem;
  }
  .m-for-box-pd-bottom-9\.5 > * > .box {
    padding-bottom: 9.5rem;
  }
  .m-for-box-pd-x-9\.5 > * > .box {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .m-for-box-pd-y-9\.5 > * > .box {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .m-for-box-pd-10 > * > .box {
    padding: 10rem;
  }
  .m-for-box-pd-left-10 > * > .box {
    padding-left: 10rem;
  }
  .m-for-box-pd-right-10 > * > .box {
    padding-right: 10rem;
  }
  .m-for-box-pd-top-10 > * > .box {
    padding-top: 10rem;
  }
  .m-for-box-pd-bottom-10 > * > .box {
    padding-bottom: 10rem;
  }
  .m-for-box-pd-x-10 > * > .box {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .m-for-box-pd-y-10 > * > .box {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .m-for-bgpic-radius > * > .bgpic {
    border-radius: 0.25rem;
    overflow: hidden;
  }
  .m-for-bgpic-border > * > .bgpic {
    border: 1px solid #E1E1E1;
  }
  .m-for-border > * {
    border: 1px solid #E1E1E1;
  }
  .m-for-hv-shadow {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-shadow:hover {
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-shadow-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-shadow-bottom:hover {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-shadow-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-shadow-top:hover {
    -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-shadow-left {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-shadow-left:hover {
    -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-shadow-right {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-shadow-right:hover {
    -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-shadow-left-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-shadow-left-top:hover {
    -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-shadow-right-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-shadow-right-top:hover {
    -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-shadow-left-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-shadow-left-bottom:hover {
    -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-shadow-right-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-shadow-right-bottom:hover {
    -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-box-shadow {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-box-shadow:hover {
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-box-shadow-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-box-shadow-bottom:hover {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-box-shadow-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-box-shadow-top:hover {
    -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-box-shadow-left {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-box-shadow-left:hover {
    -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-box-shadow-right {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-box-shadow-right:hover {
    -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-box-shadow-left-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-box-shadow-left-top:hover {
    -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-box-shadow-right-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-box-shadow-right-top:hover {
    -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-box-shadow-left-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-box-shadow-left-bottom:hover {
    -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-box-shadow-right-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .m-for-hv-box-shadow-right-bottom:hover {
    -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .m-for-hv-ani-pop, .m-for-hv-ani-push, .m-for-hv-ani-pulse, .m-for-hv-ani-float, .m-for-hv-ani-bob, .m-for-hv-ani-skew, .m-for-hv-ani-wobble {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .m-for-hv-ani-pop:hover {
    -webkit-animation-name: pop;
            animation-name: pop;
  }
  .m-for-hv-ani-push:hover {
    -webkit-animation-name: push;
            animation-name: push;
  }
  .m-for-hv-ani-pulse:hover {
    -webkit-animation-name: pulse;
            animation-name: pulse;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  .m-for-hv-ani-float:hover {
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
  }
  .m-for-hv-ani-bob:hover {
    -webkit-animation-name: bob;
            animation-name: bob;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  .m-for-hv-ani-skew:hover {
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
  }
  .m-for-hv-ani-wobble:hover {
    -webkit-animation-name: wobble-top;
            animation-name: wobble-top;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  .m-for-hv-box-ani-pop, .m-for-hv-box-ani-push, .m-for-hv-box-ani-pulse, .m-for-hv-box-ani-float, .m-for-hv-box-ani-bob, .m-for-hv-box-ani-skew, .m-for-hv-box-ani-wobble {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .m-for-hv-box-ani-pop:hover {
    -webkit-animation-name: pop;
            animation-name: pop;
  }
  .m-for-hv-box-ani-push:hover {
    -webkit-animation-name: push;
            animation-name: push;
  }
  .m-for-hv-box-ani-pulse:hover {
    -webkit-animation-name: pulse;
            animation-name: pulse;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  .m-for-hv-box-ani-float:hover {
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
  }
  .m-for-hv-box-ani-bob:hover {
    -webkit-animation-name: bob;
            animation-name: bob;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  .m-for-hv-box-ani-skew:hover {
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
  }
  .m-for-hv-box-ani-wobble:hover {
    -webkit-animation-name: wobble-top;
            animation-name: wobble-top;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
}

@media (max-width: 600px) {
  .s-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .s-absolute {
    position: absolute;
  }
  .s-fixed {
    position: fixed;
  }
  .s-relative {
    position: relative;
  }
  .s-bold {
    font-weight: bold;
  }
  .s-lighter {
    font-weight: lighter;
    font-family: "Microsoft Yahei" !important;
  }
  .s-hide {
    display: none;
  }
  .s-hidden {
    visibility: hidden;
  }
  .s-left {
    left: 0;
  }
  .s-right {
    right: 0;
  }
  .s-top {
    top: 0;
  }
  .s-bottom {
    bottom: 0;
  }
  .s-h-auto {
    height: auto;
  }
  .s-w-auto {
    width: auto;
  }
  .s-vmiddle {
    vertical-align: middle;
  }
  .s-vtop {
    vertical-align: top;
  }
  .s-vbottom {
    vertical-align: bottom;
  }
  .s-float-left {
    float: left;
  }
  .s-float-right {
    float: right;
  }
  .s-float-none {
    float: none;
  }
  .s-show, .s-display {
    display: block;
  }
  .s-show-block, .s-display-block {
    display: block;
  }
  .s-show-table, .s-display-table {
    display: table;
  }
  .s-show-inline, .s-display-inline {
    display: inline;
  }
  .s-show-inline-block, .s-display-inline-block {
    display: inline-block;
  }
  .s-show-flex, .s-display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .s-weight-1 {
    font-weight: 100;
  }
  .s-weight-2 {
    font-weight: 200;
  }
  .s-weight-3 {
    font-weight: 300;
  }
  .s-weight-4 {
    font-weight: 400;
  }
  .s-weight-5 {
    font-weight: 500;
  }
  .s-weight-6 {
    font-weight: 600;
  }
  .s-weight-7 {
    font-weight: 700;
  }
  .s-weight-8 {
    font-weight: 800;
  }
  .s-weight-9 {
    font-weight: 900;
  }
  .s-opacity-0 {
    opacity: 0;
  }
  .s-opacity-0\.1 {
    opacity: 0.1;
  }
  .s-opacity-0\.2 {
    opacity: 0.2;
  }
  .s-opacity-0\.3 {
    opacity: 0.3;
  }
  .s-opacity-0\.4 {
    opacity: 0.4;
  }
  .s-opacity-0\.5 {
    opacity: 0.5;
  }
  .s-opacity-0\.6 {
    opacity: 0.6;
  }
  .s-opacity-0\.7 {
    opacity: 0.7;
  }
  .s-opacity-0\.8 {
    opacity: 0.8;
  }
  .s-opacity-0\.9 {
    opacity: 0.9;
  }
  .s-opacity-1 {
    opacity: 1;
  }
  .s-col-auto {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 1px;
  }
  .s-for-col-auto > * {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 1px;
  }
  .s-col-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    min-width: 1px;
  }
  .s-for-col-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
  }
  .s-col-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    min-width: 1px;
  }
  .s-for-col-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
  }
  .s-col-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    min-width: 1px;
  }
  .s-for-col-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
  }
  .s-col-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    min-width: 1px;
  }
  .s-for-col-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
  }
  .s-col-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    min-width: 1px;
  }
  .s-for-col-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
  }
  .s-col-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    min-width: 1px;
  }
  .s-for-col-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
  .s-col-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    min-width: 1px;
  }
  .s-for-col-7 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
  }
  .s-col-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    min-width: 1px;
  }
  .s-for-col-8 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
  }
  .s-col-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    min-width: 1px;
  }
  .s-for-col-9 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
  }
  .s-col-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    min-width: 1px;
  }
  .s-for-col-10 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
  }
  .s-col-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    min-width: 1px;
  }
  .s-for-col-11 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
  }
  .s-col-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    min-width: 1px;
  }
  .s-for-col-12 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
  .s-width-1 {
    width: 1rem;
  }
  .s-width-2 {
    width: 2rem;
  }
  .s-width-3 {
    width: 3rem;
  }
  .s-width-4 {
    width: 4rem;
  }
  .s-width-5 {
    width: 5rem;
  }
  .s-width-6 {
    width: 6rem;
  }
  .s-width-7 {
    width: 7rem;
  }
  .s-width-8 {
    width: 8rem;
  }
  .s-width-9 {
    width: 9rem;
  }
  .s-width-10 {
    width: 10rem;
  }
  .s-fs-0\.1 {
    font-size: 0.1rem;
  }
  .s-fs-0\.2 {
    font-size: 0.2rem;
  }
  .s-fs-0\.3 {
    font-size: 0.3rem;
  }
  .s-fs-0\.4 {
    font-size: 0.4rem;
  }
  .s-fs-0\.5 {
    font-size: 0.5rem;
  }
  .s-fs-0\.6 {
    font-size: 0.6rem;
  }
  .s-fs-0\.7 {
    font-size: 0.7rem;
  }
  .s-fs-0\.8 {
    font-size: 0.8rem;
  }
  .s-fs-0\.9 {
    font-size: 0.9rem;
  }
  .s-fs-1 {
    font-size: 1rem;
  }
  .s-fs-1\.1 {
    font-size: 1.1rem;
  }
  .s-fs-1\.2 {
    font-size: 1.2rem;
  }
  .s-fs-1\.3 {
    font-size: 1.3rem;
  }
  .s-fs-1\.4 {
    font-size: 1.4rem;
  }
  .s-fs-1\.5 {
    font-size: 1.5rem;
  }
  .s-fs-1\.6 {
    font-size: 1.6rem;
  }
  .s-fs-1\.7 {
    font-size: 1.7rem;
  }
  .s-fs-1\.8 {
    font-size: 1.8rem;
  }
  .s-fs-1\.9 {
    font-size: 1.9rem;
  }
  .s-fs-2 {
    font-size: 2rem;
  }
  .s-fs-2\.1 {
    font-size: 2.1rem;
  }
  .s-fs-2\.2 {
    font-size: 2.2rem;
  }
  .s-fs-2\.3 {
    font-size: 2.3rem;
  }
  .s-fs-2\.4 {
    font-size: 2.4rem;
  }
  .s-fs-2\.5 {
    font-size: 2.5rem;
  }
  .s-fs-2\.6 {
    font-size: 2.6rem;
  }
  .s-fs-2\.7 {
    font-size: 2.7rem;
  }
  .s-fs-2\.8 {
    font-size: 2.8rem;
  }
  .s-fs-2\.9 {
    font-size: 2.9rem;
  }
  .s-fs-3 {
    font-size: 3rem;
  }
  .s-fs-3\.1 {
    font-size: 3.1rem;
  }
  .s-fs-3\.2 {
    font-size: 3.2rem;
  }
  .s-fs-3\.3 {
    font-size: 3.3rem;
  }
  .s-fs-3\.4 {
    font-size: 3.4rem;
  }
  .s-fs-3\.5 {
    font-size: 3.5rem;
  }
  .s-fs-3\.6 {
    font-size: 3.6rem;
  }
  .s-fs-3\.7 {
    font-size: 3.7rem;
  }
  .s-fs-3\.8 {
    font-size: 3.8rem;
  }
  .s-fs-3\.9 {
    font-size: 3.9rem;
  }
  .s-fs-4 {
    font-size: 4rem;
  }
  .s-fs-4\.1 {
    font-size: 4.1rem;
  }
  .s-fs-4\.2 {
    font-size: 4.2rem;
  }
  .s-fs-4\.3 {
    font-size: 4.3rem;
  }
  .s-fs-4\.4 {
    font-size: 4.4rem;
  }
  .s-fs-4\.5 {
    font-size: 4.5rem;
  }
  .s-fs-4\.6 {
    font-size: 4.6rem;
  }
  .s-fs-4\.7 {
    font-size: 4.7rem;
  }
  .s-fs-4\.8 {
    font-size: 4.8rem;
  }
  .s-fs-4\.9 {
    font-size: 4.9rem;
  }
  .s-fs-5 {
    font-size: 5rem;
  }
  .s-fs-5\.1 {
    font-size: 5.1rem;
  }
  .s-fs-5\.2 {
    font-size: 5.2rem;
  }
  .s-fs-5\.3 {
    font-size: 5.3rem;
  }
  .s-fs-5\.4 {
    font-size: 5.4rem;
  }
  .s-fs-5\.5 {
    font-size: 5.5rem;
  }
  .s-fs-5\.6 {
    font-size: 5.6rem;
  }
  .s-fs-5\.7 {
    font-size: 5.7rem;
  }
  .s-fs-5\.8 {
    font-size: 5.8rem;
  }
  .s-fs-5\.9 {
    font-size: 5.9rem;
  }
  .s-fs-6 {
    font-size: 6rem;
  }
  .s-fs-6\.1 {
    font-size: 6.1rem;
  }
  .s-fs-6\.2 {
    font-size: 6.2rem;
  }
  .s-fs-6\.3 {
    font-size: 6.3rem;
  }
  .s-fs-6\.4 {
    font-size: 6.4rem;
  }
  .s-fs-6\.5 {
    font-size: 6.5rem;
  }
  .s-fs-6\.6 {
    font-size: 6.6rem;
  }
  .s-fs-6\.7 {
    font-size: 6.7rem;
  }
  .s-fs-6\.8 {
    font-size: 6.8rem;
  }
  .s-fs-6\.9 {
    font-size: 6.9rem;
  }
  .s-fs-7 {
    font-size: 7rem;
  }
  .s-fs-7\.1 {
    font-size: 7.1rem;
  }
  .s-fs-7\.2 {
    font-size: 7.2rem;
  }
  .s-fs-7\.3 {
    font-size: 7.3rem;
  }
  .s-fs-7\.4 {
    font-size: 7.4rem;
  }
  .s-fs-7\.5 {
    font-size: 7.5rem;
  }
  .s-fs-7\.6 {
    font-size: 7.6rem;
  }
  .s-fs-7\.7 {
    font-size: 7.7rem;
  }
  .s-fs-7\.8 {
    font-size: 7.8rem;
  }
  .s-fs-7\.9 {
    font-size: 7.9rem;
  }
  .s-fs-8 {
    font-size: 8rem;
  }
  .s-fs-8\.1 {
    font-size: 8.1rem;
  }
  .s-fs-8\.2 {
    font-size: 8.2rem;
  }
  .s-fs-8\.3 {
    font-size: 8.3rem;
  }
  .s-fs-8\.4 {
    font-size: 8.4rem;
  }
  .s-fs-8\.5 {
    font-size: 8.5rem;
  }
  .s-fs-8\.6 {
    font-size: 8.6rem;
  }
  .s-fs-8\.7 {
    font-size: 8.7rem;
  }
  .s-fs-8\.8 {
    font-size: 8.8rem;
  }
  .s-fs-8\.9 {
    font-size: 8.9rem;
  }
  .s-fs-9 {
    font-size: 9rem;
  }
  .s-fs-9\.1 {
    font-size: 9.1rem;
  }
  .s-fs-9\.2 {
    font-size: 9.2rem;
  }
  .s-fs-9\.3 {
    font-size: 9.3rem;
  }
  .s-fs-9\.4 {
    font-size: 9.4rem;
  }
  .s-fs-9\.5 {
    font-size: 9.5rem;
  }
  .s-fs-9\.6 {
    font-size: 9.6rem;
  }
  .s-fs-9\.7 {
    font-size: 9.7rem;
  }
  .s-fs-9\.8 {
    font-size: 9.8rem;
  }
  .s-fs-9\.9 {
    font-size: 9.9rem;
  }
  .s-fs-10 {
    font-size: 10rem;
  }
  .s-fs-14 {
    font-size: 0.875rem;
  }
  .s-fs-18 {
    font-size: 1.125rem;
  }
  .s-fs-large {
    font-size: 3rem;
  }
  .s-line-1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .s-line-2 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .s-line-3 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  .s-line-4 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
  .s-line-5 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }
  .s-line-6 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
  .s-line-7 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
  }
  .s-line-8 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
  }
  .s-line-9 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;
  }
  .s-line-10 {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
  }
  .s-lh-0\.2 {
    line-height: 0.2;
  }
  .s-lh-02 {
    line-height: 0.2;
  }
  .s-lh-0\.3 {
    line-height: 0.3;
  }
  .s-lh-03 {
    line-height: 0.3;
  }
  .s-lh-0\.4 {
    line-height: 0.4;
  }
  .s-lh-04 {
    line-height: 0.4;
  }
  .s-lh-0\.5 {
    line-height: 0.5;
  }
  .s-lh-05 {
    line-height: 0.5;
  }
  .s-lh-0\.6 {
    line-height: 0.6;
  }
  .s-lh-06 {
    line-height: 0.6;
  }
  .s-lh-0\.7 {
    line-height: 0.7;
  }
  .s-lh-07 {
    line-height: 0.7;
  }
  .s-lh-0\.8 {
    line-height: 0.8;
  }
  .s-lh-08 {
    line-height: 0.8;
  }
  .s-lh-0\.9 {
    line-height: 0.9;
  }
  .s-lh-09 {
    line-height: 0.9;
  }
  .s-lh-1 {
    line-height: 1;
  }
  .s-lh-1 {
    line-height: 1;
  }
  .s-lh-1\.1 {
    line-height: 1.1;
  }
  .s-lh-11 {
    line-height: 1.1;
  }
  .s-lh-1\.2 {
    line-height: 1.2;
  }
  .s-lh-12 {
    line-height: 1.2;
  }
  .s-lh-1\.3 {
    line-height: 1.3;
  }
  .s-lh-13 {
    line-height: 1.3;
  }
  .s-lh-1\.4 {
    line-height: 1.4;
  }
  .s-lh-14 {
    line-height: 1.4;
  }
  .s-lh-1\.5 {
    line-height: 1.5;
  }
  .s-lh-15 {
    line-height: 1.5;
  }
  .s-lh-1\.6 {
    line-height: 1.6;
  }
  .s-lh-16 {
    line-height: 1.6;
  }
  .s-lh-1\.7 {
    line-height: 1.7;
  }
  .s-lh-17 {
    line-height: 1.7;
  }
  .s-lh-1\.8 {
    line-height: 1.8;
  }
  .s-lh-18 {
    line-height: 1.8;
  }
  .s-lh-1\.9 {
    line-height: 1.9;
  }
  .s-lh-19 {
    line-height: 1.9;
  }
  .s-lh-2 {
    line-height: 2;
  }
  .s-lh-2 {
    line-height: 2;
  }
  .s-lh-2\.1 {
    line-height: 2.1;
  }
  .s-lh-21 {
    line-height: 2.1;
  }
  .s-lh-2\.2 {
    line-height: 2.2;
  }
  .s-lh-22 {
    line-height: 2.2;
  }
  .s-lh-2\.3 {
    line-height: 2.3;
  }
  .s-lh-23 {
    line-height: 2.3;
  }
  .s-lh-2\.4 {
    line-height: 2.4;
  }
  .s-lh-24 {
    line-height: 2.4;
  }
  .s-lh-2\.5 {
    line-height: 2.5;
  }
  .s-lh-25 {
    line-height: 2.5;
  }
  .s-lh-2\.6 {
    line-height: 2.6;
  }
  .s-lh-26 {
    line-height: 2.6;
  }
  .s-lh-2\.7 {
    line-height: 2.7;
  }
  .s-lh-27 {
    line-height: 2.7;
  }
  .s-lh-2\.8 {
    line-height: 2.8;
  }
  .s-lh-28 {
    line-height: 2.8;
  }
  .s-lh-2\.9 {
    line-height: 2.9;
  }
  .s-lh-29 {
    line-height: 2.9;
  }
  .s-lh-3 {
    line-height: 3;
  }
  .s-lh-3 {
    line-height: 3;
  }
  .s-lh-3\.1 {
    line-height: 3.1;
  }
  .s-lh-31 {
    line-height: 3.1;
  }
  .s-lh-3\.2 {
    line-height: 3.2;
  }
  .s-lh-32 {
    line-height: 3.2;
  }
  .s-lh-3\.3 {
    line-height: 3.3;
  }
  .s-lh-33 {
    line-height: 3.3;
  }
  .s-lh-3\.4 {
    line-height: 3.4;
  }
  .s-lh-34 {
    line-height: 3.4;
  }
  .s-lh-3\.5 {
    line-height: 3.5;
  }
  .s-lh-35 {
    line-height: 3.5;
  }
  .s-w-auto {
    width: auto;
  }
  .s-for-w-auto > * {
    width: auto;
  }
  .s-w-1 {
    width: 8.33333%;
  }
  .s-for-w-1 > * {
    width: 8.33333%;
  }
  .s-w-2 {
    width: 16.66667%;
  }
  .s-for-w-2 > * {
    width: 16.66667%;
  }
  .s-w-3 {
    width: 25%;
  }
  .s-for-w-3 > * {
    width: 25%;
  }
  .s-w-4 {
    width: 33.33333%;
  }
  .s-for-w-4 > * {
    width: 33.33333%;
  }
  .s-w-5 {
    width: 41.66667%;
  }
  .s-for-w-5 > * {
    width: 41.66667%;
  }
  .s-w-6 {
    width: 50%;
  }
  .s-for-w-6 > * {
    width: 50%;
  }
  .s-w-7 {
    width: 58.33333%;
  }
  .s-for-w-7 > * {
    width: 58.33333%;
  }
  .s-w-8 {
    width: 66.66667%;
  }
  .s-for-w-8 > * {
    width: 66.66667%;
  }
  .s-w-9 {
    width: 75%;
  }
  .s-for-w-9 > * {
    width: 75%;
  }
  .s-w-10 {
    width: 83.33333%;
  }
  .s-for-w-10 > * {
    width: 83.33333%;
  }
  .s-w-11 {
    width: 91.66667%;
  }
  .s-for-w-11 > * {
    width: 91.66667%;
  }
  .s-w-12 {
    width: 100%;
  }
  .s-for-w-12 > * {
    width: 100%;
  }
  .s-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .s-1 > * {
    width: 100%;
  }
  .s-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .s-2 > * {
    width: 50%;
  }
  .s-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .s-3 > * {
    width: 33.33333%;
  }
  .s-4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .s-4 > * {
    width: 25%;
  }
  .s-5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .s-5 > * {
    width: 20%;
  }
  .s-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .s-6 > * {
    width: 16.66667%;
  }
  .s-7 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .s-7 > * {
    width: 14.28571%;
  }
  .s-8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .s-8 > * {
    width: 12.5%;
  }
  .s-9 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .s-9 > * {
    width: 11.11111%;
  }
  .s-10 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .s-10 > * {
    width: 10%;
  }
  .s-overflow-hidden {
    overflow: hidden;
  }
  .s-overflow-x-hidden {
    overflow-x: hidden;
  }
  .s-overflow-y-hidden {
    overflow-y: hidden;
  }
  .s-overflow-x-auto {
    overflow-x: auto;
  }
  .s-overflow-y-auto {
    overflow-y: auto;
  }
  .s-overflow-visible {
    overflow: visible;
  }
  .s-no-events {
    pointer-events: none;
  }
  .s-no-drag {
    -webkit-user-drag: none;
  }
  .s-no-select {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .s-no-bold {
    font-weight: normal;
  }
  .s-no-border {
    border: none;
  }
  .s-no-float {
    float: none;
  }
  .s-no-first-border > *:first-child {
    border: 0;
  }
  .s-no-last-border > *:last-child {
    border: 0;
  }
  .s-no-bg {
    background-color: transparent !important;
    background-image: none !important;
  }
  .s-no-wrap {
    white-space: nowrap;
  }
  .s-no-flex-shrink {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  .s-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* 水平，左起 */
    /* 水平，左起 */
    /* 垂直，上起 */
    /* 垂直，下起 */
    /* flex 水平对齐方式 */
    /*分布对齐，如有空隙，包括始端与末端*/
    /* flex 垂直对齐方式 */
    /*第一行文字的基线对齐。*/
    /*（默认值）：如果项目未设置高度或设为auto，将占满整个容器的高度。*/
    /* flex align-content */
    /* 单项 对齐方式 */
  }
  .s-flex-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .s-flex-nowrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .s-flex-wrap-reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }
  .s-flex-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .s-flex-i {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .s-flex-1 {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .s-flex-direction-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .s-flex-direction-row-reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .s-flex-direction-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .s-flex-direction-column-reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .s-flex-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .s-flex-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .s-flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .s-flex-x-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .s-flex-x-around {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .s-flex-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .s-flex-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .s-flex-middle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .s-flex-baseline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
  .s-flex-stretch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
  .s-flex-align-start {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }
  .s-flex-align-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: end;
        align-content: flex-end;
  }
  .s-flex-align-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: flex-center;
        align-content: flex-center;
  }
  .s-flex-align-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: justify;
        align-content: space-between;
  }
  .s-flex-align-around {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: distribute;
        align-content: space-around;
  }
  .s-flex-align-stretch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
  }
  .s-flex > .item-top {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
  .s-flex > .item-middle {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .s-flex > .item-bottom {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }
  .s-flex > .item-baseline {
    -ms-flex-item-align: baseline;
        align-self: baseline;
  }
  .s-flex > .item-stretch {
    -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
  }
  .s-flex > .item-auto {
    -ms-flex-item-align: auto;
        -ms-grid-row-align: auto;
        align-self: auto;
  }
  .s-text-center {
    text-align: center;
  }
  .s-text-left {
    text-align: left;
  }
  .s-text-right {
    text-align: right;
  }
  .s-text-indent {
    text-indent: 2em;
  }
  .s-text-underline {
    text-decoration: underline;
  }
  .s-full {
    width: 100%;
    height: 100%;
  }
  .s-full-w {
    width: 100%;
  }
  .s-full-h {
    height: 100%;
  }
  .s-pd-s {
    padding: 0.5rem;
  }
  .s-pd-auto {
    padding: auto;
  }
  .s-pd-x-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .s-pd-y-auto {
    padding-top: auto;
    padding-top: auto;
  }
  .s-pd-left-auto {
    padding-left: auto;
  }
  .s-pd-right-auto {
    padding-right: auto;
  }
  .s-pd-top-auto {
    padding-top: auto;
  }
  .s-pd-bottom-auto {
    padding-bottom: auto;
  }
  .s-pd-0 {
    padding: 0rem;
  }
  .s-pd-left-0 {
    padding-left: 0rem;
  }
  .s-pd-right-0 {
    padding-right: 0rem;
  }
  .s-pd-top-0 {
    padding-top: 0rem;
  }
  .s-pd-bottom-0 {
    padding-bottom: 0rem;
  }
  .s-pd-x-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .s-pd-y-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .s-pd-0\.5 {
    padding: 0.5rem;
  }
  .s-pd-left-0\.5 {
    padding-left: 0.5rem;
  }
  .s-pd-right-0\.5 {
    padding-right: 0.5rem;
  }
  .s-pd-top-0\.5 {
    padding-top: 0.5rem;
  }
  .s-pd-bottom-0\.5 {
    padding-bottom: 0.5rem;
  }
  .s-pd-x-0\.5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .s-pd-y-0\.5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .s-pd-1 {
    padding: 1rem;
  }
  .s-pd-left-1 {
    padding-left: 1rem;
  }
  .s-pd-right-1 {
    padding-right: 1rem;
  }
  .s-pd-top-1 {
    padding-top: 1rem;
  }
  .s-pd-bottom-1 {
    padding-bottom: 1rem;
  }
  .s-pd-x-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .s-pd-y-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .s-pd-1\.5 {
    padding: 1.5rem;
  }
  .s-pd-left-1\.5 {
    padding-left: 1.5rem;
  }
  .s-pd-right-1\.5 {
    padding-right: 1.5rem;
  }
  .s-pd-top-1\.5 {
    padding-top: 1.5rem;
  }
  .s-pd-bottom-1\.5 {
    padding-bottom: 1.5rem;
  }
  .s-pd-x-1\.5 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .s-pd-y-1\.5 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .s-pd-2 {
    padding: 2rem;
  }
  .s-pd-left-2 {
    padding-left: 2rem;
  }
  .s-pd-right-2 {
    padding-right: 2rem;
  }
  .s-pd-top-2 {
    padding-top: 2rem;
  }
  .s-pd-bottom-2 {
    padding-bottom: 2rem;
  }
  .s-pd-x-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .s-pd-y-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .s-pd-2\.5 {
    padding: 2.5rem;
  }
  .s-pd-left-2\.5 {
    padding-left: 2.5rem;
  }
  .s-pd-right-2\.5 {
    padding-right: 2.5rem;
  }
  .s-pd-top-2\.5 {
    padding-top: 2.5rem;
  }
  .s-pd-bottom-2\.5 {
    padding-bottom: 2.5rem;
  }
  .s-pd-x-2\.5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .s-pd-y-2\.5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .s-pd-3 {
    padding: 3rem;
  }
  .s-pd-left-3 {
    padding-left: 3rem;
  }
  .s-pd-right-3 {
    padding-right: 3rem;
  }
  .s-pd-top-3 {
    padding-top: 3rem;
  }
  .s-pd-bottom-3 {
    padding-bottom: 3rem;
  }
  .s-pd-x-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .s-pd-y-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .s-pd-3\.5 {
    padding: 3.5rem;
  }
  .s-pd-left-3\.5 {
    padding-left: 3.5rem;
  }
  .s-pd-right-3\.5 {
    padding-right: 3.5rem;
  }
  .s-pd-top-3\.5 {
    padding-top: 3.5rem;
  }
  .s-pd-bottom-3\.5 {
    padding-bottom: 3.5rem;
  }
  .s-pd-x-3\.5 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .s-pd-y-3\.5 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .s-pd-4 {
    padding: 4rem;
  }
  .s-pd-left-4 {
    padding-left: 4rem;
  }
  .s-pd-right-4 {
    padding-right: 4rem;
  }
  .s-pd-top-4 {
    padding-top: 4rem;
  }
  .s-pd-bottom-4 {
    padding-bottom: 4rem;
  }
  .s-pd-x-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .s-pd-y-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .s-pd-4\.5 {
    padding: 4.5rem;
  }
  .s-pd-left-4\.5 {
    padding-left: 4.5rem;
  }
  .s-pd-right-4\.5 {
    padding-right: 4.5rem;
  }
  .s-pd-top-4\.5 {
    padding-top: 4.5rem;
  }
  .s-pd-bottom-4\.5 {
    padding-bottom: 4.5rem;
  }
  .s-pd-x-4\.5 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .s-pd-y-4\.5 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .s-pd-5 {
    padding: 5rem;
  }
  .s-pd-left-5 {
    padding-left: 5rem;
  }
  .s-pd-right-5 {
    padding-right: 5rem;
  }
  .s-pd-top-5 {
    padding-top: 5rem;
  }
  .s-pd-bottom-5 {
    padding-bottom: 5rem;
  }
  .s-pd-x-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .s-pd-y-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .s-pd-5\.5 {
    padding: 5.5rem;
  }
  .s-pd-left-5\.5 {
    padding-left: 5.5rem;
  }
  .s-pd-right-5\.5 {
    padding-right: 5.5rem;
  }
  .s-pd-top-5\.5 {
    padding-top: 5.5rem;
  }
  .s-pd-bottom-5\.5 {
    padding-bottom: 5.5rem;
  }
  .s-pd-x-5\.5 {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
  .s-pd-y-5\.5 {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .s-pd-6 {
    padding: 6rem;
  }
  .s-pd-left-6 {
    padding-left: 6rem;
  }
  .s-pd-right-6 {
    padding-right: 6rem;
  }
  .s-pd-top-6 {
    padding-top: 6rem;
  }
  .s-pd-bottom-6 {
    padding-bottom: 6rem;
  }
  .s-pd-x-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .s-pd-y-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .s-pd-6\.5 {
    padding: 6.5rem;
  }
  .s-pd-left-6\.5 {
    padding-left: 6.5rem;
  }
  .s-pd-right-6\.5 {
    padding-right: 6.5rem;
  }
  .s-pd-top-6\.5 {
    padding-top: 6.5rem;
  }
  .s-pd-bottom-6\.5 {
    padding-bottom: 6.5rem;
  }
  .s-pd-x-6\.5 {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  .s-pd-y-6\.5 {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .s-pd-7 {
    padding: 7rem;
  }
  .s-pd-left-7 {
    padding-left: 7rem;
  }
  .s-pd-right-7 {
    padding-right: 7rem;
  }
  .s-pd-top-7 {
    padding-top: 7rem;
  }
  .s-pd-bottom-7 {
    padding-bottom: 7rem;
  }
  .s-pd-x-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .s-pd-y-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .s-pd-7\.5 {
    padding: 7.5rem;
  }
  .s-pd-left-7\.5 {
    padding-left: 7.5rem;
  }
  .s-pd-right-7\.5 {
    padding-right: 7.5rem;
  }
  .s-pd-top-7\.5 {
    padding-top: 7.5rem;
  }
  .s-pd-bottom-7\.5 {
    padding-bottom: 7.5rem;
  }
  .s-pd-x-7\.5 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .s-pd-y-7\.5 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .s-pd-8 {
    padding: 8rem;
  }
  .s-pd-left-8 {
    padding-left: 8rem;
  }
  .s-pd-right-8 {
    padding-right: 8rem;
  }
  .s-pd-top-8 {
    padding-top: 8rem;
  }
  .s-pd-bottom-8 {
    padding-bottom: 8rem;
  }
  .s-pd-x-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .s-pd-y-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .s-pd-8\.5 {
    padding: 8.5rem;
  }
  .s-pd-left-8\.5 {
    padding-left: 8.5rem;
  }
  .s-pd-right-8\.5 {
    padding-right: 8.5rem;
  }
  .s-pd-top-8\.5 {
    padding-top: 8.5rem;
  }
  .s-pd-bottom-8\.5 {
    padding-bottom: 8.5rem;
  }
  .s-pd-x-8\.5 {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .s-pd-y-8\.5 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .s-pd-9 {
    padding: 9rem;
  }
  .s-pd-left-9 {
    padding-left: 9rem;
  }
  .s-pd-right-9 {
    padding-right: 9rem;
  }
  .s-pd-top-9 {
    padding-top: 9rem;
  }
  .s-pd-bottom-9 {
    padding-bottom: 9rem;
  }
  .s-pd-x-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .s-pd-y-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .s-pd-9\.5 {
    padding: 9.5rem;
  }
  .s-pd-left-9\.5 {
    padding-left: 9.5rem;
  }
  .s-pd-right-9\.5 {
    padding-right: 9.5rem;
  }
  .s-pd-top-9\.5 {
    padding-top: 9.5rem;
  }
  .s-pd-bottom-9\.5 {
    padding-bottom: 9.5rem;
  }
  .s-pd-x-9\.5 {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .s-pd-y-9\.5 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .s-pd-10 {
    padding: 10rem;
  }
  .s-pd-left-10 {
    padding-left: 10rem;
  }
  .s-pd-right-10 {
    padding-right: 10rem;
  }
  .s-pd-top-10 {
    padding-top: 10rem;
  }
  .s-pd-bottom-10 {
    padding-bottom: 10rem;
  }
  .s-pd-x-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .s-pd-y-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .s-mg-s {
    margin: 0.5rem;
  }
  .s-mg-auto {
    margin: auto;
  }
  .s-mg-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .s-mg-y-auto {
    margin-top: auto;
    margin-top: auto;
  }
  .s-mg-left-auto {
    margin-left: auto;
  }
  .s-mg-right-auto {
    margin-right: auto;
  }
  .s-mg-top-auto {
    margin-top: auto;
  }
  .s-mg-bottom-auto {
    margin-bottom: auto;
  }
  .s-mg-0 {
    margin: 0rem;
  }
  .s-mg-left-0 {
    margin-left: 0rem;
  }
  .s-mg-right-0 {
    margin-right: 0rem;
  }
  .s-mg-top-0 {
    margin-top: 0rem;
  }
  .s-mg-bottom-0 {
    margin-bottom: 0rem;
  }
  .s-mg-x-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .s-mg-y-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .s-mg-0\.5 {
    margin: 0.5rem;
  }
  .s-mg-left-0\.5 {
    margin-left: 0.5rem;
  }
  .s-mg-right-0\.5 {
    margin-right: 0.5rem;
  }
  .s-mg-top-0\.5 {
    margin-top: 0.5rem;
  }
  .s-mg-bottom-0\.5 {
    margin-bottom: 0.5rem;
  }
  .s-mg-x-0\.5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .s-mg-y-0\.5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .s-mg-1 {
    margin: 1rem;
  }
  .s-mg-left-1 {
    margin-left: 1rem;
  }
  .s-mg-right-1 {
    margin-right: 1rem;
  }
  .s-mg-top-1 {
    margin-top: 1rem;
  }
  .s-mg-bottom-1 {
    margin-bottom: 1rem;
  }
  .s-mg-x-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .s-mg-y-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .s-mg-1\.5 {
    margin: 1.5rem;
  }
  .s-mg-left-1\.5 {
    margin-left: 1.5rem;
  }
  .s-mg-right-1\.5 {
    margin-right: 1.5rem;
  }
  .s-mg-top-1\.5 {
    margin-top: 1.5rem;
  }
  .s-mg-bottom-1\.5 {
    margin-bottom: 1.5rem;
  }
  .s-mg-x-1\.5 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .s-mg-y-1\.5 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .s-mg-2 {
    margin: 2rem;
  }
  .s-mg-left-2 {
    margin-left: 2rem;
  }
  .s-mg-right-2 {
    margin-right: 2rem;
  }
  .s-mg-top-2 {
    margin-top: 2rem;
  }
  .s-mg-bottom-2 {
    margin-bottom: 2rem;
  }
  .s-mg-x-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .s-mg-y-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .s-mg-2\.5 {
    margin: 2.5rem;
  }
  .s-mg-left-2\.5 {
    margin-left: 2.5rem;
  }
  .s-mg-right-2\.5 {
    margin-right: 2.5rem;
  }
  .s-mg-top-2\.5 {
    margin-top: 2.5rem;
  }
  .s-mg-bottom-2\.5 {
    margin-bottom: 2.5rem;
  }
  .s-mg-x-2\.5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .s-mg-y-2\.5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .s-mg-3 {
    margin: 3rem;
  }
  .s-mg-left-3 {
    margin-left: 3rem;
  }
  .s-mg-right-3 {
    margin-right: 3rem;
  }
  .s-mg-top-3 {
    margin-top: 3rem;
  }
  .s-mg-bottom-3 {
    margin-bottom: 3rem;
  }
  .s-mg-x-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .s-mg-y-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .s-mg-3\.5 {
    margin: 3.5rem;
  }
  .s-mg-left-3\.5 {
    margin-left: 3.5rem;
  }
  .s-mg-right-3\.5 {
    margin-right: 3.5rem;
  }
  .s-mg-top-3\.5 {
    margin-top: 3.5rem;
  }
  .s-mg-bottom-3\.5 {
    margin-bottom: 3.5rem;
  }
  .s-mg-x-3\.5 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .s-mg-y-3\.5 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .s-mg-4 {
    margin: 4rem;
  }
  .s-mg-left-4 {
    margin-left: 4rem;
  }
  .s-mg-right-4 {
    margin-right: 4rem;
  }
  .s-mg-top-4 {
    margin-top: 4rem;
  }
  .s-mg-bottom-4 {
    margin-bottom: 4rem;
  }
  .s-mg-x-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .s-mg-y-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .s-mg-4\.5 {
    margin: 4.5rem;
  }
  .s-mg-left-4\.5 {
    margin-left: 4.5rem;
  }
  .s-mg-right-4\.5 {
    margin-right: 4.5rem;
  }
  .s-mg-top-4\.5 {
    margin-top: 4.5rem;
  }
  .s-mg-bottom-4\.5 {
    margin-bottom: 4.5rem;
  }
  .s-mg-x-4\.5 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .s-mg-y-4\.5 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .s-mg-5 {
    margin: 5rem;
  }
  .s-mg-left-5 {
    margin-left: 5rem;
  }
  .s-mg-right-5 {
    margin-right: 5rem;
  }
  .s-mg-top-5 {
    margin-top: 5rem;
  }
  .s-mg-bottom-5 {
    margin-bottom: 5rem;
  }
  .s-mg-x-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .s-mg-y-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .s-mg-5\.5 {
    margin: 5.5rem;
  }
  .s-mg-left-5\.5 {
    margin-left: 5.5rem;
  }
  .s-mg-right-5\.5 {
    margin-right: 5.5rem;
  }
  .s-mg-top-5\.5 {
    margin-top: 5.5rem;
  }
  .s-mg-bottom-5\.5 {
    margin-bottom: 5.5rem;
  }
  .s-mg-x-5\.5 {
    margin-left: 5.5rem;
    margin-right: 5.5rem;
  }
  .s-mg-y-5\.5 {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
  }
  .s-mg-6 {
    margin: 6rem;
  }
  .s-mg-left-6 {
    margin-left: 6rem;
  }
  .s-mg-right-6 {
    margin-right: 6rem;
  }
  .s-mg-top-6 {
    margin-top: 6rem;
  }
  .s-mg-bottom-6 {
    margin-bottom: 6rem;
  }
  .s-mg-x-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .s-mg-y-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .s-mg-6\.5 {
    margin: 6.5rem;
  }
  .s-mg-left-6\.5 {
    margin-left: 6.5rem;
  }
  .s-mg-right-6\.5 {
    margin-right: 6.5rem;
  }
  .s-mg-top-6\.5 {
    margin-top: 6.5rem;
  }
  .s-mg-bottom-6\.5 {
    margin-bottom: 6.5rem;
  }
  .s-mg-x-6\.5 {
    margin-left: 6.5rem;
    margin-right: 6.5rem;
  }
  .s-mg-y-6\.5 {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem;
  }
  .s-mg-7 {
    margin: 7rem;
  }
  .s-mg-left-7 {
    margin-left: 7rem;
  }
  .s-mg-right-7 {
    margin-right: 7rem;
  }
  .s-mg-top-7 {
    margin-top: 7rem;
  }
  .s-mg-bottom-7 {
    margin-bottom: 7rem;
  }
  .s-mg-x-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .s-mg-y-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .s-mg-7\.5 {
    margin: 7.5rem;
  }
  .s-mg-left-7\.5 {
    margin-left: 7.5rem;
  }
  .s-mg-right-7\.5 {
    margin-right: 7.5rem;
  }
  .s-mg-top-7\.5 {
    margin-top: 7.5rem;
  }
  .s-mg-bottom-7\.5 {
    margin-bottom: 7.5rem;
  }
  .s-mg-x-7\.5 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .s-mg-y-7\.5 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .s-mg-8 {
    margin: 8rem;
  }
  .s-mg-left-8 {
    margin-left: 8rem;
  }
  .s-mg-right-8 {
    margin-right: 8rem;
  }
  .s-mg-top-8 {
    margin-top: 8rem;
  }
  .s-mg-bottom-8 {
    margin-bottom: 8rem;
  }
  .s-mg-x-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .s-mg-y-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .s-mg-8\.5 {
    margin: 8.5rem;
  }
  .s-mg-left-8\.5 {
    margin-left: 8.5rem;
  }
  .s-mg-right-8\.5 {
    margin-right: 8.5rem;
  }
  .s-mg-top-8\.5 {
    margin-top: 8.5rem;
  }
  .s-mg-bottom-8\.5 {
    margin-bottom: 8.5rem;
  }
  .s-mg-x-8\.5 {
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .s-mg-y-8\.5 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }
  .s-mg-9 {
    margin: 9rem;
  }
  .s-mg-left-9 {
    margin-left: 9rem;
  }
  .s-mg-right-9 {
    margin-right: 9rem;
  }
  .s-mg-top-9 {
    margin-top: 9rem;
  }
  .s-mg-bottom-9 {
    margin-bottom: 9rem;
  }
  .s-mg-x-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .s-mg-y-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .s-mg-9\.5 {
    margin: 9.5rem;
  }
  .s-mg-left-9\.5 {
    margin-left: 9.5rem;
  }
  .s-mg-right-9\.5 {
    margin-right: 9.5rem;
  }
  .s-mg-top-9\.5 {
    margin-top: 9.5rem;
  }
  .s-mg-bottom-9\.5 {
    margin-bottom: 9.5rem;
  }
  .s-mg-x-9\.5 {
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .s-mg-y-9\.5 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem;
  }
  .s-mg-10 {
    margin: 10rem;
  }
  .s-mg-left-10 {
    margin-left: 10rem;
  }
  .s-mg-right-10 {
    margin-right: 10rem;
  }
  .s-mg-top-10 {
    margin-top: 10rem;
  }
  .s-mg-bottom-10 {
    margin-bottom: 10rem;
  }
  .s-mg-x-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .s-mg-y-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .s-for-no-flex-shrink > * {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  .s-for-full > * {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-align-items: stretch;
  }
  .s-for-full > * > .box {
    width: 100%;
  }
  .s-for-radius > * {
    border-radius: 0.25rem;
  }
  .s-for-cursor-pointer > * {
    cursor: pointer;
  }
  .s-for-spacing-s {
    margin-left: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .s-for-spacing-s > * {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .s-for-spacing-0 {
    margin-left: -0rem;
    margin-bottom: -0rem;
  }
  .s-for-spacing-0 > * {
    padding-left: 0rem;
    padding-bottom: 0rem;
  }
  .s-for-spacing-0\.5 {
    margin-left: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .s-for-spacing-0\.5 > * {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .s-for-spacing-1 {
    margin-left: -1rem;
    margin-bottom: -1rem;
  }
  .s-for-spacing-1 > * {
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
  .s-for-spacing-1\.5 {
    margin-left: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .s-for-spacing-1\.5 > * {
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .s-for-spacing-2 {
    margin-left: -2rem;
    margin-bottom: -2rem;
  }
  .s-for-spacing-2 > * {
    padding-left: 2rem;
    padding-bottom: 2rem;
  }
  .s-for-spacing-2\.5 {
    margin-left: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .s-for-spacing-2\.5 > * {
    padding-left: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .s-for-spacing-3 {
    margin-left: -3rem;
    margin-bottom: -3rem;
  }
  .s-for-spacing-3 > * {
    padding-left: 3rem;
    padding-bottom: 3rem;
  }
  .s-for-spacing-3\.5 {
    margin-left: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .s-for-spacing-3\.5 > * {
    padding-left: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .s-for-spacing-4 {
    margin-left: -4rem;
    margin-bottom: -4rem;
  }
  .s-for-spacing-4 > * {
    padding-left: 4rem;
    padding-bottom: 4rem;
  }
  .s-for-spacing-4\.5 {
    margin-left: -4.5rem;
    margin-bottom: -4.5rem;
  }
  .s-for-spacing-4\.5 > * {
    padding-left: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .s-for-spacing-5 {
    margin-left: -5rem;
    margin-bottom: -5rem;
  }
  .s-for-spacing-5 > * {
    padding-left: 5rem;
    padding-bottom: 5rem;
  }
  .s-for-spacing-5\.5 {
    margin-left: -5.5rem;
    margin-bottom: -5.5rem;
  }
  .s-for-spacing-5\.5 > * {
    padding-left: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .s-for-spacing-6 {
    margin-left: -6rem;
    margin-bottom: -6rem;
  }
  .s-for-spacing-6 > * {
    padding-left: 6rem;
    padding-bottom: 6rem;
  }
  .s-for-spacing-6\.5 {
    margin-left: -6.5rem;
    margin-bottom: -6.5rem;
  }
  .s-for-spacing-6\.5 > * {
    padding-left: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .s-for-spacing-7 {
    margin-left: -7rem;
    margin-bottom: -7rem;
  }
  .s-for-spacing-7 > * {
    padding-left: 7rem;
    padding-bottom: 7rem;
  }
  .s-for-spacing-7\.5 {
    margin-left: -7.5rem;
    margin-bottom: -7.5rem;
  }
  .s-for-spacing-7\.5 > * {
    padding-left: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .s-for-spacing-8 {
    margin-left: -8rem;
    margin-bottom: -8rem;
  }
  .s-for-spacing-8 > * {
    padding-left: 8rem;
    padding-bottom: 8rem;
  }
  .s-for-spacing-8\.5 {
    margin-left: -8.5rem;
    margin-bottom: -8.5rem;
  }
  .s-for-spacing-8\.5 > * {
    padding-left: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .s-for-spacing-9 {
    margin-left: -9rem;
    margin-bottom: -9rem;
  }
  .s-for-spacing-9 > * {
    padding-left: 9rem;
    padding-bottom: 9rem;
  }
  .s-for-spacing-9\.5 {
    margin-left: -9.5rem;
    margin-bottom: -9.5rem;
  }
  .s-for-spacing-9\.5 > * {
    padding-left: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .s-for-spacing-10 {
    margin-left: -10rem;
    margin-bottom: -10rem;
  }
  .s-for-spacing-10 > * {
    padding-left: 10rem;
    padding-bottom: 10rem;
  }
  .s-for-box-radius-0 > * > .box {
    border-radius: 0rem;
  }
  .s-for-box-radius-0\.1 > * > .box {
    border-radius: 0.1rem;
  }
  .s-for-box-radius-0\.2 > * > .box {
    border-radius: 0.2rem;
  }
  .s-for-box-radius-0\.3 > * > .box {
    border-radius: 0.3rem;
  }
  .s-for-box-radius-0\.4 > * > .box {
    border-radius: 0.4rem;
  }
  .s-for-box-radius-0\.5 > * > .box {
    border-radius: 0.5rem;
  }
  .s-for-box-radius-0\.6 > * > .box {
    border-radius: 0.6rem;
  }
  .s-for-box-radius-0\.7 > * > .box {
    border-radius: 0.7rem;
  }
  .s-for-box-radius-0\.8 > * > .box {
    border-radius: 0.8rem;
  }
  .s-for-box-radius-0\.9 > * > .box {
    border-radius: 0.9rem;
  }
  .s-for-box-radius-1 > * > .box {
    border-radius: 1rem;
  }
  .s-for-box-radius-1\.1 > * > .box {
    border-radius: 1.1rem;
  }
  .s-for-box-radius-1\.2 > * > .box {
    border-radius: 1.2rem;
  }
  .s-for-box-radius-1\.3 > * > .box {
    border-radius: 1.3rem;
  }
  .s-for-box-radius-1\.4 > * > .box {
    border-radius: 1.4rem;
  }
  .s-for-box-radius-1\.5 > * > .box {
    border-radius: 1.5rem;
  }
  .s-for-box-radius-1\.6 > * > .box {
    border-radius: 1.6rem;
  }
  .s-for-box-radius-1\.7 > * > .box {
    border-radius: 1.7rem;
  }
  .s-for-box-radius-1\.8 > * > .box {
    border-radius: 1.8rem;
  }
  .s-for-box-radius-1\.9 > * > .box {
    border-radius: 1.9rem;
  }
  .s-for-box-radius-2 > * > .box {
    border-radius: 2rem;
  }
  .s-for-box-radius-2\.1 > * > .box {
    border-radius: 2.1rem;
  }
  .s-for-box-radius-2\.2 > * > .box {
    border-radius: 2.2rem;
  }
  .s-for-box-radius-2\.3 > * > .box {
    border-radius: 2.3rem;
  }
  .s-for-box-radius-2\.4 > * > .box {
    border-radius: 2.4rem;
  }
  .s-for-box-radius-2\.5 > * > .box {
    border-radius: 2.5rem;
  }
  .s-for-box-radius-2\.6 > * > .box {
    border-radius: 2.6rem;
  }
  .s-for-box-radius-2\.7 > * > .box {
    border-radius: 2.7rem;
  }
  .s-for-box-radius-2\.8 > * > .box {
    border-radius: 2.8rem;
  }
  .s-for-box-radius-2\.9 > * > .box {
    border-radius: 2.9rem;
  }
  .s-for-box-radius-3 > * > .box {
    border-radius: 3rem;
  }
  .s-for-pd-0 > * {
    padding: 0rem;
  }
  .s-for-pd-left-0 > * {
    padding-left: 0rem;
  }
  .s-for-pd-right-0 > * {
    padding-right: 0rem;
  }
  .s-for-pd-top-0 > * {
    padding-top: 0rem;
  }
  .s-for-pd-bottom-0 > * {
    padding-bottom: 0rem;
  }
  .s-for-pd-x-0 > * {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .s-for-pd-y-0 > * {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .s-for-pd-0\.5 > * {
    padding: 0.5rem;
  }
  .s-for-pd-left-0\.5 > * {
    padding-left: 0.5rem;
  }
  .s-for-pd-right-0\.5 > * {
    padding-right: 0.5rem;
  }
  .s-for-pd-top-0\.5 > * {
    padding-top: 0.5rem;
  }
  .s-for-pd-bottom-0\.5 > * {
    padding-bottom: 0.5rem;
  }
  .s-for-pd-x-0\.5 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .s-for-pd-y-0\.5 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .s-for-pd-1 > * {
    padding: 1rem;
  }
  .s-for-pd-left-1 > * {
    padding-left: 1rem;
  }
  .s-for-pd-right-1 > * {
    padding-right: 1rem;
  }
  .s-for-pd-top-1 > * {
    padding-top: 1rem;
  }
  .s-for-pd-bottom-1 > * {
    padding-bottom: 1rem;
  }
  .s-for-pd-x-1 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .s-for-pd-y-1 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .s-for-pd-1\.5 > * {
    padding: 1.5rem;
  }
  .s-for-pd-left-1\.5 > * {
    padding-left: 1.5rem;
  }
  .s-for-pd-right-1\.5 > * {
    padding-right: 1.5rem;
  }
  .s-for-pd-top-1\.5 > * {
    padding-top: 1.5rem;
  }
  .s-for-pd-bottom-1\.5 > * {
    padding-bottom: 1.5rem;
  }
  .s-for-pd-x-1\.5 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .s-for-pd-y-1\.5 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .s-for-pd-2 > * {
    padding: 2rem;
  }
  .s-for-pd-left-2 > * {
    padding-left: 2rem;
  }
  .s-for-pd-right-2 > * {
    padding-right: 2rem;
  }
  .s-for-pd-top-2 > * {
    padding-top: 2rem;
  }
  .s-for-pd-bottom-2 > * {
    padding-bottom: 2rem;
  }
  .s-for-pd-x-2 > * {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .s-for-pd-y-2 > * {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .s-for-pd-2\.5 > * {
    padding: 2.5rem;
  }
  .s-for-pd-left-2\.5 > * {
    padding-left: 2.5rem;
  }
  .s-for-pd-right-2\.5 > * {
    padding-right: 2.5rem;
  }
  .s-for-pd-top-2\.5 > * {
    padding-top: 2.5rem;
  }
  .s-for-pd-bottom-2\.5 > * {
    padding-bottom: 2.5rem;
  }
  .s-for-pd-x-2\.5 > * {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .s-for-pd-y-2\.5 > * {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .s-for-pd-3 > * {
    padding: 3rem;
  }
  .s-for-pd-left-3 > * {
    padding-left: 3rem;
  }
  .s-for-pd-right-3 > * {
    padding-right: 3rem;
  }
  .s-for-pd-top-3 > * {
    padding-top: 3rem;
  }
  .s-for-pd-bottom-3 > * {
    padding-bottom: 3rem;
  }
  .s-for-pd-x-3 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .s-for-pd-y-3 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .s-for-pd-3\.5 > * {
    padding: 3.5rem;
  }
  .s-for-pd-left-3\.5 > * {
    padding-left: 3.5rem;
  }
  .s-for-pd-right-3\.5 > * {
    padding-right: 3.5rem;
  }
  .s-for-pd-top-3\.5 > * {
    padding-top: 3.5rem;
  }
  .s-for-pd-bottom-3\.5 > * {
    padding-bottom: 3.5rem;
  }
  .s-for-pd-x-3\.5 > * {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .s-for-pd-y-3\.5 > * {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .s-for-pd-4 > * {
    padding: 4rem;
  }
  .s-for-pd-left-4 > * {
    padding-left: 4rem;
  }
  .s-for-pd-right-4 > * {
    padding-right: 4rem;
  }
  .s-for-pd-top-4 > * {
    padding-top: 4rem;
  }
  .s-for-pd-bottom-4 > * {
    padding-bottom: 4rem;
  }
  .s-for-pd-x-4 > * {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .s-for-pd-y-4 > * {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .s-for-pd-4\.5 > * {
    padding: 4.5rem;
  }
  .s-for-pd-left-4\.5 > * {
    padding-left: 4.5rem;
  }
  .s-for-pd-right-4\.5 > * {
    padding-right: 4.5rem;
  }
  .s-for-pd-top-4\.5 > * {
    padding-top: 4.5rem;
  }
  .s-for-pd-bottom-4\.5 > * {
    padding-bottom: 4.5rem;
  }
  .s-for-pd-x-4\.5 > * {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .s-for-pd-y-4\.5 > * {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .s-for-pd-5 > * {
    padding: 5rem;
  }
  .s-for-pd-left-5 > * {
    padding-left: 5rem;
  }
  .s-for-pd-right-5 > * {
    padding-right: 5rem;
  }
  .s-for-pd-top-5 > * {
    padding-top: 5rem;
  }
  .s-for-pd-bottom-5 > * {
    padding-bottom: 5rem;
  }
  .s-for-pd-x-5 > * {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .s-for-pd-y-5 > * {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .s-for-pd-5\.5 > * {
    padding: 5.5rem;
  }
  .s-for-pd-left-5\.5 > * {
    padding-left: 5.5rem;
  }
  .s-for-pd-right-5\.5 > * {
    padding-right: 5.5rem;
  }
  .s-for-pd-top-5\.5 > * {
    padding-top: 5.5rem;
  }
  .s-for-pd-bottom-5\.5 > * {
    padding-bottom: 5.5rem;
  }
  .s-for-pd-x-5\.5 > * {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
  .s-for-pd-y-5\.5 > * {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .s-for-pd-6 > * {
    padding: 6rem;
  }
  .s-for-pd-left-6 > * {
    padding-left: 6rem;
  }
  .s-for-pd-right-6 > * {
    padding-right: 6rem;
  }
  .s-for-pd-top-6 > * {
    padding-top: 6rem;
  }
  .s-for-pd-bottom-6 > * {
    padding-bottom: 6rem;
  }
  .s-for-pd-x-6 > * {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .s-for-pd-y-6 > * {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .s-for-pd-6\.5 > * {
    padding: 6.5rem;
  }
  .s-for-pd-left-6\.5 > * {
    padding-left: 6.5rem;
  }
  .s-for-pd-right-6\.5 > * {
    padding-right: 6.5rem;
  }
  .s-for-pd-top-6\.5 > * {
    padding-top: 6.5rem;
  }
  .s-for-pd-bottom-6\.5 > * {
    padding-bottom: 6.5rem;
  }
  .s-for-pd-x-6\.5 > * {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  .s-for-pd-y-6\.5 > * {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .s-for-pd-7 > * {
    padding: 7rem;
  }
  .s-for-pd-left-7 > * {
    padding-left: 7rem;
  }
  .s-for-pd-right-7 > * {
    padding-right: 7rem;
  }
  .s-for-pd-top-7 > * {
    padding-top: 7rem;
  }
  .s-for-pd-bottom-7 > * {
    padding-bottom: 7rem;
  }
  .s-for-pd-x-7 > * {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .s-for-pd-y-7 > * {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .s-for-pd-7\.5 > * {
    padding: 7.5rem;
  }
  .s-for-pd-left-7\.5 > * {
    padding-left: 7.5rem;
  }
  .s-for-pd-right-7\.5 > * {
    padding-right: 7.5rem;
  }
  .s-for-pd-top-7\.5 > * {
    padding-top: 7.5rem;
  }
  .s-for-pd-bottom-7\.5 > * {
    padding-bottom: 7.5rem;
  }
  .s-for-pd-x-7\.5 > * {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .s-for-pd-y-7\.5 > * {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .s-for-pd-8 > * {
    padding: 8rem;
  }
  .s-for-pd-left-8 > * {
    padding-left: 8rem;
  }
  .s-for-pd-right-8 > * {
    padding-right: 8rem;
  }
  .s-for-pd-top-8 > * {
    padding-top: 8rem;
  }
  .s-for-pd-bottom-8 > * {
    padding-bottom: 8rem;
  }
  .s-for-pd-x-8 > * {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .s-for-pd-y-8 > * {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .s-for-pd-8\.5 > * {
    padding: 8.5rem;
  }
  .s-for-pd-left-8\.5 > * {
    padding-left: 8.5rem;
  }
  .s-for-pd-right-8\.5 > * {
    padding-right: 8.5rem;
  }
  .s-for-pd-top-8\.5 > * {
    padding-top: 8.5rem;
  }
  .s-for-pd-bottom-8\.5 > * {
    padding-bottom: 8.5rem;
  }
  .s-for-pd-x-8\.5 > * {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .s-for-pd-y-8\.5 > * {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .s-for-pd-9 > * {
    padding: 9rem;
  }
  .s-for-pd-left-9 > * {
    padding-left: 9rem;
  }
  .s-for-pd-right-9 > * {
    padding-right: 9rem;
  }
  .s-for-pd-top-9 > * {
    padding-top: 9rem;
  }
  .s-for-pd-bottom-9 > * {
    padding-bottom: 9rem;
  }
  .s-for-pd-x-9 > * {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .s-for-pd-y-9 > * {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .s-for-pd-9\.5 > * {
    padding: 9.5rem;
  }
  .s-for-pd-left-9\.5 > * {
    padding-left: 9.5rem;
  }
  .s-for-pd-right-9\.5 > * {
    padding-right: 9.5rem;
  }
  .s-for-pd-top-9\.5 > * {
    padding-top: 9.5rem;
  }
  .s-for-pd-bottom-9\.5 > * {
    padding-bottom: 9.5rem;
  }
  .s-for-pd-x-9\.5 > * {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .s-for-pd-y-9\.5 > * {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .s-for-pd-10 > * {
    padding: 10rem;
  }
  .s-for-pd-left-10 > * {
    padding-left: 10rem;
  }
  .s-for-pd-right-10 > * {
    padding-right: 10rem;
  }
  .s-for-pd-top-10 > * {
    padding-top: 10rem;
  }
  .s-for-pd-bottom-10 > * {
    padding-bottom: 10rem;
  }
  .s-for-pd-x-10 > * {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .s-for-pd-y-10 > * {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .s-for-mg-0 > * {
    margin: 0rem;
  }
  .s-for-mg-left-0 > * {
    margin-left: 0rem;
  }
  .s-for-mg-right-0 > * {
    margin-right: 0rem;
  }
  .s-for-mg-top-0 > * {
    margin-top: 0rem;
  }
  .s-for-mg-bottom-0 > * {
    margin-bottom: 0rem;
  }
  .s-for-mg-x-0 > * {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .s-for-mg-y-0 > * {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .s-for-mg-0\.5 > * {
    margin: 0.5rem;
  }
  .s-for-mg-left-0\.5 > * {
    margin-left: 0.5rem;
  }
  .s-for-mg-right-0\.5 > * {
    margin-right: 0.5rem;
  }
  .s-for-mg-top-0\.5 > * {
    margin-top: 0.5rem;
  }
  .s-for-mg-bottom-0\.5 > * {
    margin-bottom: 0.5rem;
  }
  .s-for-mg-x-0\.5 > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .s-for-mg-y-0\.5 > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .s-for-mg-1 > * {
    margin: 1rem;
  }
  .s-for-mg-left-1 > * {
    margin-left: 1rem;
  }
  .s-for-mg-right-1 > * {
    margin-right: 1rem;
  }
  .s-for-mg-top-1 > * {
    margin-top: 1rem;
  }
  .s-for-mg-bottom-1 > * {
    margin-bottom: 1rem;
  }
  .s-for-mg-x-1 > * {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .s-for-mg-y-1 > * {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .s-for-mg-1\.5 > * {
    margin: 1.5rem;
  }
  .s-for-mg-left-1\.5 > * {
    margin-left: 1.5rem;
  }
  .s-for-mg-right-1\.5 > * {
    margin-right: 1.5rem;
  }
  .s-for-mg-top-1\.5 > * {
    margin-top: 1.5rem;
  }
  .s-for-mg-bottom-1\.5 > * {
    margin-bottom: 1.5rem;
  }
  .s-for-mg-x-1\.5 > * {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .s-for-mg-y-1\.5 > * {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .s-for-mg-2 > * {
    margin: 2rem;
  }
  .s-for-mg-left-2 > * {
    margin-left: 2rem;
  }
  .s-for-mg-right-2 > * {
    margin-right: 2rem;
  }
  .s-for-mg-top-2 > * {
    margin-top: 2rem;
  }
  .s-for-mg-bottom-2 > * {
    margin-bottom: 2rem;
  }
  .s-for-mg-x-2 > * {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .s-for-mg-y-2 > * {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .s-for-mg-2\.5 > * {
    margin: 2.5rem;
  }
  .s-for-mg-left-2\.5 > * {
    margin-left: 2.5rem;
  }
  .s-for-mg-right-2\.5 > * {
    margin-right: 2.5rem;
  }
  .s-for-mg-top-2\.5 > * {
    margin-top: 2.5rem;
  }
  .s-for-mg-bottom-2\.5 > * {
    margin-bottom: 2.5rem;
  }
  .s-for-mg-x-2\.5 > * {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .s-for-mg-y-2\.5 > * {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .s-for-mg-3 > * {
    margin: 3rem;
  }
  .s-for-mg-left-3 > * {
    margin-left: 3rem;
  }
  .s-for-mg-right-3 > * {
    margin-right: 3rem;
  }
  .s-for-mg-top-3 > * {
    margin-top: 3rem;
  }
  .s-for-mg-bottom-3 > * {
    margin-bottom: 3rem;
  }
  .s-for-mg-x-3 > * {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .s-for-mg-y-3 > * {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .s-for-mg-3\.5 > * {
    margin: 3.5rem;
  }
  .s-for-mg-left-3\.5 > * {
    margin-left: 3.5rem;
  }
  .s-for-mg-right-3\.5 > * {
    margin-right: 3.5rem;
  }
  .s-for-mg-top-3\.5 > * {
    margin-top: 3.5rem;
  }
  .s-for-mg-bottom-3\.5 > * {
    margin-bottom: 3.5rem;
  }
  .s-for-mg-x-3\.5 > * {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .s-for-mg-y-3\.5 > * {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .s-for-mg-4 > * {
    margin: 4rem;
  }
  .s-for-mg-left-4 > * {
    margin-left: 4rem;
  }
  .s-for-mg-right-4 > * {
    margin-right: 4rem;
  }
  .s-for-mg-top-4 > * {
    margin-top: 4rem;
  }
  .s-for-mg-bottom-4 > * {
    margin-bottom: 4rem;
  }
  .s-for-mg-x-4 > * {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .s-for-mg-y-4 > * {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .s-for-mg-4\.5 > * {
    margin: 4.5rem;
  }
  .s-for-mg-left-4\.5 > * {
    margin-left: 4.5rem;
  }
  .s-for-mg-right-4\.5 > * {
    margin-right: 4.5rem;
  }
  .s-for-mg-top-4\.5 > * {
    margin-top: 4.5rem;
  }
  .s-for-mg-bottom-4\.5 > * {
    margin-bottom: 4.5rem;
  }
  .s-for-mg-x-4\.5 > * {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .s-for-mg-y-4\.5 > * {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .s-for-mg-5 > * {
    margin: 5rem;
  }
  .s-for-mg-left-5 > * {
    margin-left: 5rem;
  }
  .s-for-mg-right-5 > * {
    margin-right: 5rem;
  }
  .s-for-mg-top-5 > * {
    margin-top: 5rem;
  }
  .s-for-mg-bottom-5 > * {
    margin-bottom: 5rem;
  }
  .s-for-mg-x-5 > * {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .s-for-mg-y-5 > * {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .s-for-mg-5\.5 > * {
    margin: 5.5rem;
  }
  .s-for-mg-left-5\.5 > * {
    margin-left: 5.5rem;
  }
  .s-for-mg-right-5\.5 > * {
    margin-right: 5.5rem;
  }
  .s-for-mg-top-5\.5 > * {
    margin-top: 5.5rem;
  }
  .s-for-mg-bottom-5\.5 > * {
    margin-bottom: 5.5rem;
  }
  .s-for-mg-x-5\.5 > * {
    margin-left: 5.5rem;
    margin-right: 5.5rem;
  }
  .s-for-mg-y-5\.5 > * {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
  }
  .s-for-mg-6 > * {
    margin: 6rem;
  }
  .s-for-mg-left-6 > * {
    margin-left: 6rem;
  }
  .s-for-mg-right-6 > * {
    margin-right: 6rem;
  }
  .s-for-mg-top-6 > * {
    margin-top: 6rem;
  }
  .s-for-mg-bottom-6 > * {
    margin-bottom: 6rem;
  }
  .s-for-mg-x-6 > * {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .s-for-mg-y-6 > * {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .s-for-mg-6\.5 > * {
    margin: 6.5rem;
  }
  .s-for-mg-left-6\.5 > * {
    margin-left: 6.5rem;
  }
  .s-for-mg-right-6\.5 > * {
    margin-right: 6.5rem;
  }
  .s-for-mg-top-6\.5 > * {
    margin-top: 6.5rem;
  }
  .s-for-mg-bottom-6\.5 > * {
    margin-bottom: 6.5rem;
  }
  .s-for-mg-x-6\.5 > * {
    margin-left: 6.5rem;
    margin-right: 6.5rem;
  }
  .s-for-mg-y-6\.5 > * {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem;
  }
  .s-for-mg-7 > * {
    margin: 7rem;
  }
  .s-for-mg-left-7 > * {
    margin-left: 7rem;
  }
  .s-for-mg-right-7 > * {
    margin-right: 7rem;
  }
  .s-for-mg-top-7 > * {
    margin-top: 7rem;
  }
  .s-for-mg-bottom-7 > * {
    margin-bottom: 7rem;
  }
  .s-for-mg-x-7 > * {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .s-for-mg-y-7 > * {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .s-for-mg-7\.5 > * {
    margin: 7.5rem;
  }
  .s-for-mg-left-7\.5 > * {
    margin-left: 7.5rem;
  }
  .s-for-mg-right-7\.5 > * {
    margin-right: 7.5rem;
  }
  .s-for-mg-top-7\.5 > * {
    margin-top: 7.5rem;
  }
  .s-for-mg-bottom-7\.5 > * {
    margin-bottom: 7.5rem;
  }
  .s-for-mg-x-7\.5 > * {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .s-for-mg-y-7\.5 > * {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .s-for-mg-8 > * {
    margin: 8rem;
  }
  .s-for-mg-left-8 > * {
    margin-left: 8rem;
  }
  .s-for-mg-right-8 > * {
    margin-right: 8rem;
  }
  .s-for-mg-top-8 > * {
    margin-top: 8rem;
  }
  .s-for-mg-bottom-8 > * {
    margin-bottom: 8rem;
  }
  .s-for-mg-x-8 > * {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .s-for-mg-y-8 > * {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .s-for-mg-8\.5 > * {
    margin: 8.5rem;
  }
  .s-for-mg-left-8\.5 > * {
    margin-left: 8.5rem;
  }
  .s-for-mg-right-8\.5 > * {
    margin-right: 8.5rem;
  }
  .s-for-mg-top-8\.5 > * {
    margin-top: 8.5rem;
  }
  .s-for-mg-bottom-8\.5 > * {
    margin-bottom: 8.5rem;
  }
  .s-for-mg-x-8\.5 > * {
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .s-for-mg-y-8\.5 > * {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }
  .s-for-mg-9 > * {
    margin: 9rem;
  }
  .s-for-mg-left-9 > * {
    margin-left: 9rem;
  }
  .s-for-mg-right-9 > * {
    margin-right: 9rem;
  }
  .s-for-mg-top-9 > * {
    margin-top: 9rem;
  }
  .s-for-mg-bottom-9 > * {
    margin-bottom: 9rem;
  }
  .s-for-mg-x-9 > * {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .s-for-mg-y-9 > * {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .s-for-mg-9\.5 > * {
    margin: 9.5rem;
  }
  .s-for-mg-left-9\.5 > * {
    margin-left: 9.5rem;
  }
  .s-for-mg-right-9\.5 > * {
    margin-right: 9.5rem;
  }
  .s-for-mg-top-9\.5 > * {
    margin-top: 9.5rem;
  }
  .s-for-mg-bottom-9\.5 > * {
    margin-bottom: 9.5rem;
  }
  .s-for-mg-x-9\.5 > * {
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .s-for-mg-y-9\.5 > * {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem;
  }
  .s-for-mg-10 > * {
    margin: 10rem;
  }
  .s-for-mg-left-10 > * {
    margin-left: 10rem;
  }
  .s-for-mg-right-10 > * {
    margin-right: 10rem;
  }
  .s-for-mg-top-10 > * {
    margin-top: 10rem;
  }
  .s-for-mg-bottom-10 > * {
    margin-bottom: 10rem;
  }
  .s-for-mg-x-10 > * {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .s-for-mg-y-10 > * {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .s-for-box-light > * > .box {
    background-color: #f5f5f8;
  }
  .s-for-box-border > * > .box {
    border: 1px solid #E1E1E1;
  }
  .s-for-box-shadow {
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-box-shadow-bottom {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-box-shadow-top {
    -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-box-shadow-left {
    -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-box-shadow-right {
    -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-box-shadow-left-top {
    -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-box-shadow-right-top {
    -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-box-shadow-left-bottom {
    -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-box-shadow-right-bottom {
    -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-box-radius > * > .box {
    border-radius: 1rem;
  }
  .s-for-box-pd-0 > * > .box {
    padding: 0rem;
  }
  .s-for-box-pd-left-0 > * > .box {
    padding-left: 0rem;
  }
  .s-for-box-pd-right-0 > * > .box {
    padding-right: 0rem;
  }
  .s-for-box-pd-top-0 > * > .box {
    padding-top: 0rem;
  }
  .s-for-box-pd-bottom-0 > * > .box {
    padding-bottom: 0rem;
  }
  .s-for-box-pd-x-0 > * > .box {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .s-for-box-pd-y-0 > * > .box {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .s-for-box-pd-0\.5 > * > .box {
    padding: 0.5rem;
  }
  .s-for-box-pd-left-0\.5 > * > .box {
    padding-left: 0.5rem;
  }
  .s-for-box-pd-right-0\.5 > * > .box {
    padding-right: 0.5rem;
  }
  .s-for-box-pd-top-0\.5 > * > .box {
    padding-top: 0.5rem;
  }
  .s-for-box-pd-bottom-0\.5 > * > .box {
    padding-bottom: 0.5rem;
  }
  .s-for-box-pd-x-0\.5 > * > .box {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .s-for-box-pd-y-0\.5 > * > .box {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .s-for-box-pd-1 > * > .box {
    padding: 1rem;
  }
  .s-for-box-pd-left-1 > * > .box {
    padding-left: 1rem;
  }
  .s-for-box-pd-right-1 > * > .box {
    padding-right: 1rem;
  }
  .s-for-box-pd-top-1 > * > .box {
    padding-top: 1rem;
  }
  .s-for-box-pd-bottom-1 > * > .box {
    padding-bottom: 1rem;
  }
  .s-for-box-pd-x-1 > * > .box {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .s-for-box-pd-y-1 > * > .box {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .s-for-box-pd-1\.5 > * > .box {
    padding: 1.5rem;
  }
  .s-for-box-pd-left-1\.5 > * > .box {
    padding-left: 1.5rem;
  }
  .s-for-box-pd-right-1\.5 > * > .box {
    padding-right: 1.5rem;
  }
  .s-for-box-pd-top-1\.5 > * > .box {
    padding-top: 1.5rem;
  }
  .s-for-box-pd-bottom-1\.5 > * > .box {
    padding-bottom: 1.5rem;
  }
  .s-for-box-pd-x-1\.5 > * > .box {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .s-for-box-pd-y-1\.5 > * > .box {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .s-for-box-pd-2 > * > .box {
    padding: 2rem;
  }
  .s-for-box-pd-left-2 > * > .box {
    padding-left: 2rem;
  }
  .s-for-box-pd-right-2 > * > .box {
    padding-right: 2rem;
  }
  .s-for-box-pd-top-2 > * > .box {
    padding-top: 2rem;
  }
  .s-for-box-pd-bottom-2 > * > .box {
    padding-bottom: 2rem;
  }
  .s-for-box-pd-x-2 > * > .box {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .s-for-box-pd-y-2 > * > .box {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .s-for-box-pd-2\.5 > * > .box {
    padding: 2.5rem;
  }
  .s-for-box-pd-left-2\.5 > * > .box {
    padding-left: 2.5rem;
  }
  .s-for-box-pd-right-2\.5 > * > .box {
    padding-right: 2.5rem;
  }
  .s-for-box-pd-top-2\.5 > * > .box {
    padding-top: 2.5rem;
  }
  .s-for-box-pd-bottom-2\.5 > * > .box {
    padding-bottom: 2.5rem;
  }
  .s-for-box-pd-x-2\.5 > * > .box {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .s-for-box-pd-y-2\.5 > * > .box {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .s-for-box-pd-3 > * > .box {
    padding: 3rem;
  }
  .s-for-box-pd-left-3 > * > .box {
    padding-left: 3rem;
  }
  .s-for-box-pd-right-3 > * > .box {
    padding-right: 3rem;
  }
  .s-for-box-pd-top-3 > * > .box {
    padding-top: 3rem;
  }
  .s-for-box-pd-bottom-3 > * > .box {
    padding-bottom: 3rem;
  }
  .s-for-box-pd-x-3 > * > .box {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .s-for-box-pd-y-3 > * > .box {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .s-for-box-pd-3\.5 > * > .box {
    padding: 3.5rem;
  }
  .s-for-box-pd-left-3\.5 > * > .box {
    padding-left: 3.5rem;
  }
  .s-for-box-pd-right-3\.5 > * > .box {
    padding-right: 3.5rem;
  }
  .s-for-box-pd-top-3\.5 > * > .box {
    padding-top: 3.5rem;
  }
  .s-for-box-pd-bottom-3\.5 > * > .box {
    padding-bottom: 3.5rem;
  }
  .s-for-box-pd-x-3\.5 > * > .box {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .s-for-box-pd-y-3\.5 > * > .box {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .s-for-box-pd-4 > * > .box {
    padding: 4rem;
  }
  .s-for-box-pd-left-4 > * > .box {
    padding-left: 4rem;
  }
  .s-for-box-pd-right-4 > * > .box {
    padding-right: 4rem;
  }
  .s-for-box-pd-top-4 > * > .box {
    padding-top: 4rem;
  }
  .s-for-box-pd-bottom-4 > * > .box {
    padding-bottom: 4rem;
  }
  .s-for-box-pd-x-4 > * > .box {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .s-for-box-pd-y-4 > * > .box {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .s-for-box-pd-4\.5 > * > .box {
    padding: 4.5rem;
  }
  .s-for-box-pd-left-4\.5 > * > .box {
    padding-left: 4.5rem;
  }
  .s-for-box-pd-right-4\.5 > * > .box {
    padding-right: 4.5rem;
  }
  .s-for-box-pd-top-4\.5 > * > .box {
    padding-top: 4.5rem;
  }
  .s-for-box-pd-bottom-4\.5 > * > .box {
    padding-bottom: 4.5rem;
  }
  .s-for-box-pd-x-4\.5 > * > .box {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .s-for-box-pd-y-4\.5 > * > .box {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .s-for-box-pd-5 > * > .box {
    padding: 5rem;
  }
  .s-for-box-pd-left-5 > * > .box {
    padding-left: 5rem;
  }
  .s-for-box-pd-right-5 > * > .box {
    padding-right: 5rem;
  }
  .s-for-box-pd-top-5 > * > .box {
    padding-top: 5rem;
  }
  .s-for-box-pd-bottom-5 > * > .box {
    padding-bottom: 5rem;
  }
  .s-for-box-pd-x-5 > * > .box {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .s-for-box-pd-y-5 > * > .box {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .s-for-box-pd-5\.5 > * > .box {
    padding: 5.5rem;
  }
  .s-for-box-pd-left-5\.5 > * > .box {
    padding-left: 5.5rem;
  }
  .s-for-box-pd-right-5\.5 > * > .box {
    padding-right: 5.5rem;
  }
  .s-for-box-pd-top-5\.5 > * > .box {
    padding-top: 5.5rem;
  }
  .s-for-box-pd-bottom-5\.5 > * > .box {
    padding-bottom: 5.5rem;
  }
  .s-for-box-pd-x-5\.5 > * > .box {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
  .s-for-box-pd-y-5\.5 > * > .box {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
  .s-for-box-pd-6 > * > .box {
    padding: 6rem;
  }
  .s-for-box-pd-left-6 > * > .box {
    padding-left: 6rem;
  }
  .s-for-box-pd-right-6 > * > .box {
    padding-right: 6rem;
  }
  .s-for-box-pd-top-6 > * > .box {
    padding-top: 6rem;
  }
  .s-for-box-pd-bottom-6 > * > .box {
    padding-bottom: 6rem;
  }
  .s-for-box-pd-x-6 > * > .box {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .s-for-box-pd-y-6 > * > .box {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .s-for-box-pd-6\.5 > * > .box {
    padding: 6.5rem;
  }
  .s-for-box-pd-left-6\.5 > * > .box {
    padding-left: 6.5rem;
  }
  .s-for-box-pd-right-6\.5 > * > .box {
    padding-right: 6.5rem;
  }
  .s-for-box-pd-top-6\.5 > * > .box {
    padding-top: 6.5rem;
  }
  .s-for-box-pd-bottom-6\.5 > * > .box {
    padding-bottom: 6.5rem;
  }
  .s-for-box-pd-x-6\.5 > * > .box {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  .s-for-box-pd-y-6\.5 > * > .box {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .s-for-box-pd-7 > * > .box {
    padding: 7rem;
  }
  .s-for-box-pd-left-7 > * > .box {
    padding-left: 7rem;
  }
  .s-for-box-pd-right-7 > * > .box {
    padding-right: 7rem;
  }
  .s-for-box-pd-top-7 > * > .box {
    padding-top: 7rem;
  }
  .s-for-box-pd-bottom-7 > * > .box {
    padding-bottom: 7rem;
  }
  .s-for-box-pd-x-7 > * > .box {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .s-for-box-pd-y-7 > * > .box {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .s-for-box-pd-7\.5 > * > .box {
    padding: 7.5rem;
  }
  .s-for-box-pd-left-7\.5 > * > .box {
    padding-left: 7.5rem;
  }
  .s-for-box-pd-right-7\.5 > * > .box {
    padding-right: 7.5rem;
  }
  .s-for-box-pd-top-7\.5 > * > .box {
    padding-top: 7.5rem;
  }
  .s-for-box-pd-bottom-7\.5 > * > .box {
    padding-bottom: 7.5rem;
  }
  .s-for-box-pd-x-7\.5 > * > .box {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .s-for-box-pd-y-7\.5 > * > .box {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .s-for-box-pd-8 > * > .box {
    padding: 8rem;
  }
  .s-for-box-pd-left-8 > * > .box {
    padding-left: 8rem;
  }
  .s-for-box-pd-right-8 > * > .box {
    padding-right: 8rem;
  }
  .s-for-box-pd-top-8 > * > .box {
    padding-top: 8rem;
  }
  .s-for-box-pd-bottom-8 > * > .box {
    padding-bottom: 8rem;
  }
  .s-for-box-pd-x-8 > * > .box {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .s-for-box-pd-y-8 > * > .box {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .s-for-box-pd-8\.5 > * > .box {
    padding: 8.5rem;
  }
  .s-for-box-pd-left-8\.5 > * > .box {
    padding-left: 8.5rem;
  }
  .s-for-box-pd-right-8\.5 > * > .box {
    padding-right: 8.5rem;
  }
  .s-for-box-pd-top-8\.5 > * > .box {
    padding-top: 8.5rem;
  }
  .s-for-box-pd-bottom-8\.5 > * > .box {
    padding-bottom: 8.5rem;
  }
  .s-for-box-pd-x-8\.5 > * > .box {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .s-for-box-pd-y-8\.5 > * > .box {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .s-for-box-pd-9 > * > .box {
    padding: 9rem;
  }
  .s-for-box-pd-left-9 > * > .box {
    padding-left: 9rem;
  }
  .s-for-box-pd-right-9 > * > .box {
    padding-right: 9rem;
  }
  .s-for-box-pd-top-9 > * > .box {
    padding-top: 9rem;
  }
  .s-for-box-pd-bottom-9 > * > .box {
    padding-bottom: 9rem;
  }
  .s-for-box-pd-x-9 > * > .box {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .s-for-box-pd-y-9 > * > .box {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .s-for-box-pd-9\.5 > * > .box {
    padding: 9.5rem;
  }
  .s-for-box-pd-left-9\.5 > * > .box {
    padding-left: 9.5rem;
  }
  .s-for-box-pd-right-9\.5 > * > .box {
    padding-right: 9.5rem;
  }
  .s-for-box-pd-top-9\.5 > * > .box {
    padding-top: 9.5rem;
  }
  .s-for-box-pd-bottom-9\.5 > * > .box {
    padding-bottom: 9.5rem;
  }
  .s-for-box-pd-x-9\.5 > * > .box {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .s-for-box-pd-y-9\.5 > * > .box {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .s-for-box-pd-10 > * > .box {
    padding: 10rem;
  }
  .s-for-box-pd-left-10 > * > .box {
    padding-left: 10rem;
  }
  .s-for-box-pd-right-10 > * > .box {
    padding-right: 10rem;
  }
  .s-for-box-pd-top-10 > * > .box {
    padding-top: 10rem;
  }
  .s-for-box-pd-bottom-10 > * > .box {
    padding-bottom: 10rem;
  }
  .s-for-box-pd-x-10 > * > .box {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .s-for-box-pd-y-10 > * > .box {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .s-for-bgpic-radius > * > .bgpic {
    border-radius: 0.25rem;
    overflow: hidden;
  }
  .s-for-bgpic-border > * > .bgpic {
    border: 1px solid #E1E1E1;
  }
  .s-for-border > * {
    border: 1px solid #E1E1E1;
  }
  .s-for-hv-shadow {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-shadow:hover {
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-shadow-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-shadow-bottom:hover {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-shadow-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-shadow-top:hover {
    -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-shadow-left {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-shadow-left:hover {
    -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-shadow-right {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-shadow-right:hover {
    -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-shadow-left-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-shadow-left-top:hover {
    -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-shadow-right-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-shadow-right-top:hover {
    -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-shadow-left-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-shadow-left-bottom:hover {
    -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-shadow-right-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-shadow-right-bottom:hover {
    -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-box-shadow {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-box-shadow:hover {
    -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-box-shadow-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-box-shadow-bottom:hover {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-box-shadow-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-box-shadow-top:hover {
    -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-box-shadow-left {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-box-shadow-left:hover {
    -webkit-box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-box-shadow-right {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-box-shadow-right:hover {
    -webkit-box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-box-shadow-left-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-box-shadow-left-top:hover {
    -webkit-box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-box-shadow-right-top {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-box-shadow-right-top:hover {
    -webkit-box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-box-shadow-left-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-box-shadow-left-bottom:hover {
    -webkit-box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-box-shadow-right-bottom {
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .s-for-hv-box-shadow-right-bottom:hover {
    -webkit-box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.05);
  }
  .s-for-hv-ani-pop, .s-for-hv-ani-push, .s-for-hv-ani-pulse, .s-for-hv-ani-float, .s-for-hv-ani-bob, .s-for-hv-ani-skew, .s-for-hv-ani-wobble {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .s-for-hv-ani-pop:hover {
    -webkit-animation-name: pop;
            animation-name: pop;
  }
  .s-for-hv-ani-push:hover {
    -webkit-animation-name: push;
            animation-name: push;
  }
  .s-for-hv-ani-pulse:hover {
    -webkit-animation-name: pulse;
            animation-name: pulse;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  .s-for-hv-ani-float:hover {
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
  }
  .s-for-hv-ani-bob:hover {
    -webkit-animation-name: bob;
            animation-name: bob;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  .s-for-hv-ani-skew:hover {
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
  }
  .s-for-hv-ani-wobble:hover {
    -webkit-animation-name: wobble-top;
            animation-name: wobble-top;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  .s-for-hv-box-ani-pop, .s-for-hv-box-ani-push, .s-for-hv-box-ani-pulse, .s-for-hv-box-ani-float, .s-for-hv-box-ani-bob, .s-for-hv-box-ani-skew, .s-for-hv-box-ani-wobble {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .s-for-hv-box-ani-pop:hover {
    -webkit-animation-name: pop;
            animation-name: pop;
  }
  .s-for-hv-box-ani-push:hover {
    -webkit-animation-name: push;
            animation-name: push;
  }
  .s-for-hv-box-ani-pulse:hover {
    -webkit-animation-name: pulse;
            animation-name: pulse;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  .s-for-hv-box-ani-float:hover {
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
  }
  .s-for-hv-box-ani-bob:hover {
    -webkit-animation-name: bob;
            animation-name: bob;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  .s-for-hv-box-ani-skew:hover {
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
  }
  .s-for-hv-box-ani-wobble:hover {
    -webkit-animation-name: wobble-top;
            animation-name: wobble-top;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
}
